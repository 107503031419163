import { useEffect, useRef, useState } from 'react';

import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { setSelectedLeagueId } from '~store/slices/prematchMenuSlice';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

export const useLeagueEventsWithMarkets = () => {
  const { leagueId: activeLeague } = useQueryParams();
  const { leagueEvents, selectedLeagueData, dateToFilter } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const { isPrimaryDatsLoaded, sendWsMessage } = useEventsLoadingSocket();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const prevDateTo = useRef('');

  useEffect(() => {
    if (!isPrimaryDatsLoaded) return;
    if (prevDateTo.current !== dateToFilter && activeLeague) {
      setIsLoading(true);
      prevDateTo.current = dateToFilter || '';
      dispatch(setSelectedLeagueId(activeLeague));
      sendWsMessage(ACTION_TYPE.GET_PREMATCH_EVENTS_BY_LEAGUE, {
        LeagueId: +activeLeague,
        DateTo: dateToFilter,
      });
    }
  }, [activeLeague, dateToFilter, isPrimaryDatsLoaded]);

  return {
    leagueEventsData: leagueEvents,
    prematchEventsByLeagueData: selectedLeagueData,
    isLoading,
    onLoadingChange: setIsLoading,
  };
};
