import React, { useState } from 'react';

import { SettingsDropdownPopover } from '~components/atoms/SettingsDropdown/SettingsDropdownPopover';
import {
  StyledDropdownTrigger,
  StyledSettingsMobileButton,
} from '~components/atoms/SettingsDropdown/styled.components';
import { StyledUserProfileBarContainer } from '~components/molecules/UserProfile/components/UserProfileBar';
import { useMedia } from '~hooks';
import useIsInIframe from '~hooks/useIsInIframe';
import { SettingsIcon } from '~icons';

export const SettingsDropdown = () => {
  const isInIframe = useIsInIframe();
  const { isMobileOrTablet } = useMedia();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledUserProfileBarContainer
      isSquare
      isOpened={isOpen}
      flexRow
      justifyCenter
      alignCenter
      css={{
        borderColor: `${isInIframe ? '$blueDark' : 'transparent'} !important`,
        background: 'transparent',
        '> div': {
          color: `${isInIframe && isOpen ? '$yellow' : '$white'}`,
        },
      }}
    >
      <SettingsDropdownPopover
        onOpenChange={() => {
          setIsOpen(!isOpen);
        }}
      >
        <StyledDropdownTrigger as="div">
          {!isMobileOrTablet ? (
            <SettingsIcon />
          ) : (
            <StyledSettingsMobileButton>
              <SettingsIcon />
            </StyledSettingsMobileButton>
          )}
        </StyledDropdownTrigger>
      </SettingsDropdownPopover>
    </StyledUserProfileBarContainer>
  );
};
