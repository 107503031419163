import React, { Fragment } from 'react';
import { useTable, UseTableOptions } from 'react-table';

import { BetslipHistoryDetails } from '~api/betslip/types';
import { TableHeader } from '~components/molecules/BetslipHistorySection/components/TableHeader';
import {
  StyledCellComponent,
  StyledExpandedTable,
  StyledInnerTableTBody,
  StyledTableRow,
} from '~components/molecules/BetslipHistorySection/styled.components';

import { useBetslipHistoryInnerTable } from './useBetslipHistoryInnerTable';

export const columnWidths: Record<number, string> = {
  0: '182px',
  1: '194px',
  2: '160px',
  3: '45px',
  4: '79px',
};

export const BetslipHistoryInnerTable = ({
  betHistoryDetails,
}: {
  betHistoryDetails: BetslipHistoryDetails[];
}) => {
  const { columns } = useBetslipHistoryInnerTable();

  const {
    getTableProps: getInnerTableProps,
    getTableBodyProps: getInnerTableBodyProps,
    headerGroups,
    rows: innerRows,
    prepareRow: prepareInnerRow,
  } = useTable({
    columns,
    data: betHistoryDetails || [],
  } as UseTableOptions<BetslipHistoryDetails>);

  return (
    <StyledExpandedTable {...getInnerTableProps()} className="expandedTable">
      <TableHeader
        headerGroups={headerGroups}
        columnWidths={columnWidths}
        customStyles={{
          color: '$grayMedium',
          backgroundColor: '$grayDark',
          textAlign: 'left',
          '&:first-child': {
            paddingLeft: '$3',
          },
        }}
      />
      <StyledInnerTableTBody {...getInnerTableBodyProps()}>
        {innerRows.map((row, rowIndex) => {
          prepareInnerRow(row);

          return (
            <Fragment key={rowIndex}>
              <StyledTableRow {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => (
                  <td {...cell.getCellProps()} key={`cell-${cellIndex}`}>
                    <StyledCellComponent>
                      {cell.render('Cell')}
                    </StyledCellComponent>
                  </td>
                ))}
              </StyledTableRow>
            </Fragment>
          );
        })}
      </StyledInnerTableTBody>
    </StyledExpandedTable>
  );
};
