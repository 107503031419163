import { useEffect, useState } from 'react';

import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoriteInplayEvents } from '~store/slices/liveGroupsSlice';
import { SportEventMainData } from '~types/events';

export const useInplayFavoriteEvents = (eventId?: string) => {
  const dispatch = useAppDispatch();
  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();
  const [isEventFavorite, setIsEventFavorite] = useState(false);
  const { eventsMap } = useAppSelector((state) => state.events);
  const { favoriteInplayEvents = [] } = useAppSelector(
    (state) => state.liveGroupsState,
  );

  const handleFavoriteInplayEventClick = async () => {
    if (!eventId) return;
    try {
      const eventData = eventsMap[eventId];

      if (isEventFavorite) {
        await deleteFavoriteEventMutation({
          eventId,
        });

        dispatch(
          setFavoriteInplayEvents(
            favoriteInplayEvents.filter(
              (favoriteEvent) => favoriteEvent.id !== eventData?.id,
            ),
          ),
        );
      } else {
        await addFavoriteEventMutation({
          eventId,
        });

        if (eventData) {
          dispatch(
            setFavoriteInplayEvents([
              ...favoriteInplayEvents,
              eventData as SportEventMainData,
            ]),
          );
        }
      }
    } catch (e) {
      console.error('Failed to handle favorite live event: ', e);
    }
  };

  useEffect(() => {
    const isEventFavorite = !!favoriteInplayEvents.find(
      (event) => event.id === eventId,
    );

    setIsEventFavorite(isEventFavorite);
  }, [favoriteInplayEvents]);

  return { handleFavoriteInplayEventClick, isEventFavorite };
};
