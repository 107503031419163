import { Dispatch } from 'redux';

import { BalanceChange } from '~api/betslip/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { BetslipPartialWinboost } from '~components/molecules/Betslip/ BetslipPartialWinboost';
import { BetslipWinboostError } from '~components/molecules/Betslip/BetslipWinboostError';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { CURRENCY_CODES, MESSAGE_TYPES } from '~constants/common';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { BetslipLoginToBet } from './BetslipLoginToBet';

export enum BETSLIP_HISTORY_TYPE {
  SPORTS = 'sports',
  JACKPOT = 'jackpot',
}

export enum BETSLIP_TOGGLE_TYPES {
  BETSLIP = 'betslip',
  OPEN_BETS = 'openBets',
}

export enum SPORT_BETSLIP_TYPE_CODES {
  SINGLE = 1,
  MULTIPLE = 2,
  SYSTEM = 4,
}

export enum SPORT_BETSLIP_TYPE_OPTIONS {
  MULTIPLE = 'multiple',
  SINGLE = 'single',
  SYSTEM = 'system',
}

export enum BETSLIP_VALUES_MAP_OPTIONS {
  AGGREGATED = 'aggregated',
  ALL = 'all',
}

export const SPORT_BETSLIP_TYPES_MAP = {
  1: SPORT_BETSLIP_TYPE_OPTIONS.SINGLE,
  2: SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE,
  4: SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM,
};

export type BetslipHistoryTypeOption = {
  value: BETSLIP_HISTORY_TYPE;
  label: string;
};

export const betslipAdjustmentOptions = [1000, 2000, 5000, 10000];

export enum BETSLIP_ERRORS {
  NOT_COMBINABLE = 'notCombinable',
  REMOVED_EVENTS = 'removedEvents',
  LOW_BALANCE = 'lowBalance',
  LOW_BONUS_BALANCE = 'lowBonusBalance',
  LOGIN_TO_BET = 'loginToBet',
  MAX_STAKE = 'maxStake',
  MIN_STAKE = 'minStake',
  NOT_MEET_REQUIREMENTS = 'notMeetRequirements',
  NOT_MET_WINBOOST = 'notMetWinboost',
  PARTIAL_WINBOOST_MET = 'partialWinboost',
  MIN_SELECTIONS = 'minSelections',
  MAX_SELECTIONS = 'maxSelections',
}

export enum ODD_ASK_TYPES {
  ALWAYS = '1',
  HIGHER = '2',
  ALL = '3',
}

type LocalizedFunction = (key: string, args?: object) => string;

interface GetBetslipErrorsProps {
  localized: LocalizedFunction;
  localizedError: LocalizedFunction;
  dispatch: Dispatch;
  websiteCurrency: CURRENCY_CODES;
  isMobileOrTablet: boolean;
  maxStakeLimit?: number;
  minStakeLimit?: number;
  sportMaxSelectionCount: number;
  sportMinSelectionCount: number;
}

export const getBetslipErrors = ({
  localized,
  localizedError,
  dispatch,
  websiteCurrency,
  isMobileOrTablet,
  maxStakeLimit,
  minStakeLimit,
  sportMaxSelectionCount,
  sportMinSelectionCount,
}: GetBetslipErrorsProps) => ({
  [BETSLIP_ERRORS.NOT_COMBINABLE]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.NOT_COMBINABLE}`),
    type: MESSAGE_TYPES.ERROR,
  },
  [BETSLIP_ERRORS.REMOVED_EVENTS]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.REMOVED_EVENTS}`),
    type: MESSAGE_TYPES.ERROR,
  },
  [BETSLIP_ERRORS.LOW_BALANCE]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.LOW_BALANCE}`),
    type: MESSAGE_TYPES.WARNING,
    action: {
      text: localized('buttons.deposit'),
      action: () => {
        if (!isMobileOrTablet) {
          dispatch(openDialog(DIALOGS.USER_PROFILE));
          dispatch(
            setTabsState({
              mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
              contentTab: USER_PAYMENT_TABS.DEPOSIT,
            }),
          );
        } else {
          dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
          dispatch(
            setMobileUserProfileDialogState(
              USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
            ),
          );
          dispatch(
            setMobileUserProfileDialogActiveContent(USER_PAYMENT_TABS.DEPOSIT),
          );
        }
      },
    },
  },
  [BETSLIP_ERRORS.LOW_BONUS_BALANCE]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.LOW_BONUS_BALANCE}`),
    type: MESSAGE_TYPES.WARNING,
  },
  [BETSLIP_ERRORS.NOT_MEET_REQUIREMENTS]: {
    text: localizedError(
      `betslip.errors.${BETSLIP_ERRORS.NOT_MEET_REQUIREMENTS}`,
    ),
    type: MESSAGE_TYPES.WARNING,
  },
  [BETSLIP_ERRORS.MAX_STAKE]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.MAX_STAKE}`, {
      max: commaSeparatedNumber(maxStakeLimit, true),
      currency: CURRENCY_CODES[websiteCurrency] || '',
    }),
    type: MESSAGE_TYPES.WARNING,
  },
  [BETSLIP_ERRORS.MIN_STAKE]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.MIN_STAKE}`, {
      min: commaSeparatedNumber(minStakeLimit, true),
      currency: CURRENCY_CODES[websiteCurrency] || '',
    }),
    type: MESSAGE_TYPES.WARNING,
  },
  [BETSLIP_ERRORS.NOT_MET_WINBOOST]: {
    type: MESSAGE_TYPES.WARNING,
    customRender: <BetslipWinboostError />,
  },
  [BETSLIP_ERRORS.PARTIAL_WINBOOST_MET]: {
    type: MESSAGE_TYPES.WARNING,
    customRender: <BetslipPartialWinboost />,
  },

  [BETSLIP_ERRORS.LOGIN_TO_BET]: {
    type: MESSAGE_TYPES.WARNING,
    customRender: <BetslipLoginToBet />,
  },
  [BETSLIP_ERRORS.MIN_SELECTIONS]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.MIN_SELECTIONS}`, {
      min: sportMinSelectionCount,
    }),
    type: MESSAGE_TYPES.WARNING,
  },
  [BETSLIP_ERRORS.MAX_SELECTIONS]: {
    text: localizedError(`betslip.errors.${BETSLIP_ERRORS.MAX_SELECTIONS}`, {
      max: sportMaxSelectionCount,
    }),
    type: MESSAGE_TYPES.WARNING,
  },
});

export const getBetslipTypes = (localized: LocalizedFunction) => {
  return [
    {
      label: localized('betslip.stakeType.single'),
      value: SPORT_BETSLIP_TYPE_OPTIONS.SINGLE,
    },
    {
      label: localized('betslip.stakeType.multiple'),
      value: SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE,
    },
    {
      label: localized('betslip.stakeType.system'),
      value: SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM,
    },
  ];
};

export const getOddTypeOptions = (localized: LocalizedFunction) => {
  return [
    {
      label: localized('betslip.oddsAsk.always'),
      value: ODD_ASK_TYPES.ALWAYS,
    },
    {
      label: localized('betslip.oddsAsk.higher'),
      value: ODD_ASK_TYPES.HIGHER,
    },
    {
      label: localized('betslip.oddsAsk.all'),
      value: ODD_ASK_TYPES.ALL,
    },
  ];
};

export const EVENT_ACTIVE_STATUSES = [1, 2, 9];

export const MARKET_ACTIVE_STATUS = 1;

export const MIN_SYSTEM_BET_EVENTS = 3;

export enum STAKE_TAX_PAYER {
  BOOKMAKER = 1,
  PLAYER = 2,
}

export const DEFAULT_BALANCE_CHANGE: BalanceChange = {
  realBetAmount: 0,
  selectionId: '',
  totalPossibleWin: 0,
  bonusChanges: [],
  winBoostDescriptions: [],
  matchedBonusRules: 0,
  winBoostAmount: 0,
  winBoostMultiplier: 0,
  originalWinAmount: 0,
  betMatchesLimits: false,
  maxStakeLimit: 0,
  minStakeLimit: 0,
  possibleIncomeTax: 0,
  incomeTaxPercent: 0,
  stake: 0,
  stakeTax: 0,
  stakeTaxPercent: 0,
  stakeBeforeTax: 0,
  realStakeAfterTax: 0,
  stakeTaxPayer: STAKE_TAX_PAYER.BOOKMAKER, // Replace with the appropriate enum value
};
