import React, { useMemo } from 'react';
import { registerSW } from 'virtual:pwa-register';

import { AbsoluteDialogs } from '~components/atoms/AbsoluteDialogs';
import { DesktopLayout } from '~components/atoms/layouts/DesktopLayout';
import { MobileLayout } from '~components/atoms/layouts/MobileLayout';
import { ConnectionStatus } from '~components/molecules/ConnectionStatus';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { useOnAppInit } from '~useOnAppInit';

const intervalMS = 1000 * 60 * 10;

let isUpdating = false;

registerSW({
  onRegisteredSW(_, registration) {
    const updateServiceWorker = () => {
      if (isUpdating) return;
      isUpdating = true;

      registration.update().finally(() => {
        isUpdating = false;
      });
    };

    setInterval(updateServiceWorker, intervalMS);
  },
});

const App = () => {
  const { isAppInitDataReady } = useOnAppInit();
  const { isMobileOrTablet } = useMedia();
  const isMobile = useMemo(() => navigator.userAgent.indexOf('Mobi') > -1, []);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  const renderLayout = useMemo(
    () => (
      <>
        {isMobileOrTablet || isMobile ? <MobileLayout /> : <DesktopLayout />}
        <AbsoluteDialogs />
        <ConnectionStatus />
      </>
    ),
    [isMobileOrTablet, isMobile, isUserLoggedIn],
  );

  if (!isAppInitDataReady) return null;

  return renderLayout;
};

export default App;
