import React from 'react';

import { Box } from '~components/atoms/Box';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';

import { useUserProfileDialogMobile } from '../../hooks/useUserProfileDialogMobile';

export enum USER_PROFILE_DIALOG_MOBILE_STATE {
  SIDEBAR = 'sidebar',
  CONTENT = 'content',
}

export const UserProfileDialogMobile = () => {
  const {
    profile,
    isContentState,
    componentsMap,
    mobileUserProfileDialogState,
  } = useUserProfileDialogMobile();

  if (!profile) {
    return null;
  }

  return (
    <>
      <DialogMobileContent isContentState={isContentState}>
        <Box css={{ p: '$4', pt: isContentState ? 0 : '$4', mt: '5px' }}>
          {componentsMap[mobileUserProfileDialogState]}
        </Box>
      </DialogMobileContent>
    </>
  );
};
