import React, { useEffect } from 'react';

import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';
import { Dialog, DialogContent } from '~components/atoms/Modal';
import { CashOut, CashOutSuccess } from '~components/molecules/CashOut';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import { setMobileUserProfileDialogActiveContent } from '~store/slices/mobileSlice';
import { setCashOutBet, setCashOutProcessed } from '~store/slices/openBetSlice';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

interface BetHistoryMobileCashoutProps {
  isBetHistory?: boolean;
}

export const BetHistoryMobileCashout = ({
  isBetHistory = false,
}: BetHistoryMobileCashoutProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { cashOutBet, cashOutProcessed } = useAppSelector(
    (state) => state.openBets,
  );
  const { mobileUserProfileDialogActiveContent } = useAppSelector(
    (state) => state.mobileState,
  );
  const { trail } = useAppSelector((state) => state.breadcrumb);

  const handleContinue = () => {
    dispatch(
      setMobileUserProfileDialogActiveContent(
        mobileUserProfileDialogActiveContent,
      ),
    );
    dispatch(setCashOutBet(null));
    dispatch(setCashOutProcessed(false));
  };

  const toggleDialog = (value: boolean) => {
    if (!value) {
      handleContinue();
    }
  };

  useEffect(() => {
    if (!cashOutBet) return;

    const newTrail = [
      {
        label: localized('buttons.back'),
        onClickCallback: () => {
          handleContinue();
        },
        forceCallback: true,
      },
      {
        label: localized('betslip.cashOut'),
      },
    ];

    if (!areTwoObjectsEqual(trail, newTrail)) {
      dispatch(setBreadcrumbTrail(newTrail));
    }
  }, [cashOutBet, trail]);

  return (
    <>
      <Dialog open={Boolean(cashOutBet)} toggleDialog={toggleDialog}>
        <DialogContent hasOverlay top>
          <DialogMobileContent>
            <Breadcrumb />
            {cashOutProcessed ? (
              <CashOutSuccess onContinue={handleContinue} />
            ) : (
              <CashOut
                noCloseIcon
                onCancel={handleContinue}
                isBetHistory={isBetHistory}
              />
            )}
          </DialogMobileContent>
        </DialogContent>
      </Dialog>
    </>
  );
};
