import React, { useEffect, useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { NoBets } from '~components/molecules/BetslipHistorySection/NoBets';
import { useBetslipHistory } from '~components/molecules/BetslipHistorySection/Sports/useBetslipHistory';
import { useTranslation } from '~hooks/useTranslation';

import { BetslipHistoryMobileContent } from '../BetslipHistoryMobileContent';

export const BetslipHistoryMobile = () => {
  const { localized } = useTranslation();
  const { loadBetslipHistory, isLoaded, total, loadMore, betHistory } =
    useBetslipHistory();

  useEffect(() => {
    loadBetslipHistory({});
  }, []);

  return useMemo(() => {
    if (!isLoaded) {
      return (
        <Box flexCol alignCenter gap={2} css={{ paddingTop: '32px' }}>
          <Loader css={{ color: '$grayMedium', width: '$6', height: '$6' }} />
          <Text color="grayMedium" level="12-20">
            {localized('betslipHistory.loading')}
          </Text>
        </Box>
      );
    }

    if (!betHistory.length) {
      return <NoBets />;
    }

    return (
      <BetslipHistoryMobileContent
        betHistory={betHistory}
        loadMore={loadMore}
        total={total}
      />
    );
  }, [isLoaded, betHistory]);
};
