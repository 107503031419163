import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledActiveBonusWrapper = styled(Box, {
  p: '$2 $4 $3',
  border: '1px solid $gray',
  borderRadius: '$8',
  backgroundColor: '$blueDark',
  '@xs_sm': {
    p: '$2 $3 $3',
  },
});

export const StyledActiveBonusTotal = styled(Box, {
  width: '100%',
  gap: '$2',
  p: '$1 $3',
  boxShadow: '$progressBarShadow',
  borderRadius: '$6',
  backgroundColor: '$grayDark',
});

export const StyledActiveBonusWon = styled(Box, {
  width: '100%',
  p: '$1 $3',
  borderRadius: '$6',
  backgroundColor: '$grayDark',
});

export const StyledExpiresTimeNumber = styled(Box, {
  backgroundColor: '$grayDark',
  borderRadius: '$6',
  width: 'fit-content',
  minWidth: '28px',
  p: '2px 5px',
});

export const StyledExpiresLabel = styled(Box, {
  position: 'absolute',
  top: '32px',
  left: '50%',
  transform: 'translateX(-50%)',
});
