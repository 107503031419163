import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';

export const Container = styled(Box, {
  borderRadius: '$8',
  border: '1px solid $gray',
  p: 0,
});

export const SubContainer = styled(Box, {
  width: '50%',
});

export const Title = styled(Text, {
  defaultVariants: {
    color: 'whiteDark',
    fontWeight: 'medium',
    level: 'sm-4',
  },
  textTransform: 'uppercase',
  p: '$2 $3',
});

export const HorizontalSeparator = styled(Separator, {
  defaultVariants: {
    verticalSpace: 0,
    shrinkOut: 0,
  },
  backgroundColor: '$grayOpacity8',
});

export const MobileSectionContainer = styled(Box, {
  backgroundColor: '$grayDark',
  borderRadius: '$8',
});

export const MobileSectionTitleContainer = styled(Box, {
  p: '$2 $6',
  gap: '$1',
});
