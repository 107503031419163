import React from 'react';

import { Box } from '~components/atoms/Box';
import { CountryName } from '~constants/flag';
import { GlobeIcon } from '~icons';
import { getAlpha2Code } from '~utils/getFlagByCountryName';

interface FlagProps {
  countryName: CountryName;
  code?: string | null | undefined;
  size?: 'sm' | 'md' | 'lg';
}

export const Flag = ({ countryName, size = 'md' }: FlagProps) => {
  const countryCode = getAlpha2Code(countryName);

  if (!countryCode || countryName.toLowerCase().includes('international')) {
    return (
      <Box
        flexRow
        alignCenter
        css={{
          color: '$grayMedium',
          width: '$5',
          height: '$5',
          '& > svg': {
            width: '$5',
            height: '$5',
          },
        }}
      >
        <GlobeIcon />
      </Box>
    );
  }

  return (
    <div
      className={`fflag fflag-${countryCode} ff-${size} ff-round`}
      style={{
        backgroundImage: 'url(/flagSprite60.png)',
      }}
      title={countryName}
    />
  );
};
