import { createElement } from 'react';

import { SportIconKey, sportIconMap } from '~constants/sport';
import { SVGElementProps } from '~types/general';

export const getSportIcon = (sport: SportIconKey, props?: SVGElementProps) => {
  const sportIcon = sportIconMap[sport];

  return sportIcon ? createElement(sportIcon, { ...props }) : null;
};
