import { CountryName } from '~constants/flag';

export const LANGUAGES: { value: string; label: string; flag: CountryName }[] =
  [
    { value: 'en', label: 'English', flag: 'England' },
    { value: 'fr', label: 'French', flag: 'France' },
    { value: 'es', label: 'Spanish', flag: 'Spain' },
    { value: 'pt', label: 'Portuguese', flag: 'Portugal' },
    { value: 'hi', label: 'Hindi', flag: 'India' },
    { value: 'fa', label: 'Farsi', flag: 'Iran' },
  ];

export const languagesMap: Record<string, number> = {
  en: 1,
  fr: 2,
  es: 3,
  pt: 4,
  hi: 5,
  fa: 6,
};
