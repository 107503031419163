import { useEffect } from 'react';

import {
  useDeleteBank,
  useDeleteCard,
  useSetDefaultCardFlutterwave,
  useSetDefaultCardPaystack,
  useSetDefaultWithdrawalBank,
} from '~api/transaction/transactionMutations';
import {
  useLazyGetAuthorizedDepositFlutterwave,
  useLazyGetAuthorizedDepositPaystack,
  useLazyGetWithdrawalSavedBanks,
} from '~api/transaction/transactionQueries';
import { useAppDispatch, useAppSelector } from '~store';
import {
  removeDefaultFlutterwaveCard,
  removeDefaultPaystackCard,
  removeDefaultWithdrawBank,
  setFlutterwaveDepositCards,
  setPaystackDepositCards,
  setWithdrawBankAccounts,
  updateDefaultFlutterwaveCard,
  updateDefaultPaystackCard,
  updateDefaultWithdrawBank,
} from '~store/slices/paymentAccountsSlice';

enum PAYMENT_ACCOUNTS {
  WITHDRAW_BANKS = 'withdrawBanks',
  PAYSTACK_DEPOSIT_CARDS = 'paystackDepositCards',
  FLUTTERWAVE_DEPOSIT_CARDS = 'flutterwaveDepositCards',
}

export const usePaymentAccounts = () => {
  const dispatch = useAppDispatch();
  const { paystackDepositCards, flutterwaveDepositCards, withdrawBanks } =
    useAppSelector((state) => state.paymentAccounts);

  const { lazyGetWithdrawalSavedBanksQuery, lazyGetWithdrawalSavedBanksData } =
    useLazyGetWithdrawalSavedBanks();

  const {
    lazyGetAuthorizedDepositPaystackQuery,
    lazyGetAuthorizedDepositPaystackData,
  } = useLazyGetAuthorizedDepositPaystack();
  const {
    lazyGetAuthorizedDepositFlutterwaveQuery,
    lazyGetAuthorizedDepositFlutterwaveData,
  } = useLazyGetAuthorizedDepositFlutterwave();
  const { setDefaultWithdrawalBankMutation } = useSetDefaultWithdrawalBank();
  const { setDefaultCardFlutterwaveMutation } = useSetDefaultCardFlutterwave();
  const { setDefaultCardPaystackMutation } = useSetDefaultCardPaystack();
  const { deleteBankMutation } = useDeleteBank();
  const { deleteCardMutation } = useDeleteCard();

  const handleDefaultAccountChange = async (
    accountId: string,
    key: PAYMENT_ACCOUNTS,
  ) => {
    try {
      switch (key) {
        case PAYMENT_ACCOUNTS.WITHDRAW_BANKS:
          await setDefaultWithdrawalBankMutation(accountId);
          dispatch(updateDefaultWithdrawBank(accountId));
          break;
        case PAYMENT_ACCOUNTS.PAYSTACK_DEPOSIT_CARDS:
          await setDefaultCardPaystackMutation(accountId);
          dispatch(updateDefaultPaystackCard(accountId));
          break;
        case PAYMENT_ACCOUNTS.FLUTTERWAVE_DEPOSIT_CARDS:
          await setDefaultCardFlutterwaveMutation(accountId);
          dispatch(updateDefaultFlutterwaveCard(accountId));
          break;
        default:
          break;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const handleRemoveDefaultAccount = async (
    accountId: string,
    key: PAYMENT_ACCOUNTS,
  ) => {
    try {
      switch (key) {
        case PAYMENT_ACCOUNTS.WITHDRAW_BANKS:
          await deleteBankMutation(accountId);
          dispatch(removeDefaultWithdrawBank(accountId));
          break;
        case PAYMENT_ACCOUNTS.FLUTTERWAVE_DEPOSIT_CARDS:
          await deleteCardMutation(accountId);
          dispatch(removeDefaultFlutterwaveCard(accountId));
          break;
        case PAYMENT_ACCOUNTS.PAYSTACK_DEPOSIT_CARDS:
          await deleteCardMutation(accountId);
          dispatch(removeDefaultPaystackCard(accountId));
          break;
        default:
          break;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    const loadPayments = async () => {
      await lazyGetWithdrawalSavedBanksQuery();
      await lazyGetAuthorizedDepositFlutterwaveQuery();
      await lazyGetAuthorizedDepositPaystackQuery();
    };

    loadPayments();
  }, []);

  useEffect(() => {
    if (lazyGetAuthorizedDepositFlutterwaveData) {
      dispatch(
        setFlutterwaveDepositCards(lazyGetAuthorizedDepositFlutterwaveData),
      );
    }
  }, [lazyGetAuthorizedDepositFlutterwaveData]);

  useEffect(() => {
    if (lazyGetAuthorizedDepositPaystackData) {
      dispatch(setPaystackDepositCards(lazyGetAuthorizedDepositPaystackData));
    }
  }, [lazyGetAuthorizedDepositPaystackData]);

  useEffect(() => {
    if (lazyGetWithdrawalSavedBanksData) {
      dispatch(setWithdrawBankAccounts(lazyGetWithdrawalSavedBanksData));
    }
  }, [lazyGetWithdrawalSavedBanksData]);

  return {
    withdrawBanks,
    paystackDepositCards,
    flutterwaveDepositCards,
    PAYMENT_ACCOUNTS,
    handleDefaultAccountChange,
    handleRemoveDefaultAccount,
  };
};
