import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useLazyGetUserLimits } from '~api/auth/authQueries';
import {
  useLazyGetBannerByGroupName,
  useLazyGetMenuContent,
} from '~api/content/contentQueries';
import { BannerGroup } from '~api/content/types';
import { useLazyGetFavoriteMarkets } from '~api/market/marketQueries';
import { useLazySettings } from '~api/partner/partnerQueries';
import {
  PaymentSettings,
  SportsBettingConfig,
  WebsiteSettingsResponse,
} from '~api/partner/types';
import { UserProfileData } from '~api/user/types';
import { useLazyGetUserProfile } from '~api/user/userQueries';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import {
  bannerGroupNameMobile,
  bannerGroupNameWeb,
} from '~components/atoms/BannerList';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { CHANNEL_TYPES } from '~constants/common';
import { THEMES } from '~constants/ui';
import {
  useCasinoDataLoad,
  useLaunchEventsSocket,
  useListenEventsLoadingSocket,
  useQueryParams,
  useShare,
} from '~hooks';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useIframeAuthorization } from '~hooks/useIframeAuthorization';
import { useListenTokens } from '~hooks/useListenTokens';
import { useMedia } from '~hooks/useMedia';
import { useSignalRSockets } from '~hooks/useSignalRSockets';
import { useTransactionsUpdate } from '~hooks/useTransactionsUpdate';
import { useUpdateWebsiteSettings } from '~hooks/useUpdateSettings';
import {
  startTokenRefreshScheduler,
  stopTokenRefreshScheduler,
} from '~services/auth/tokenRefreshScheduler';
import { useAppDispatch, useAppSelector } from '~store';
import { setStakeType } from '~store/slices/betslipSlice';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setFavoriteMarkets } from '~store/slices/mainMarketsSlice';
import {
  selectPaymnets,
  setAvailablePaymentSettings,
  setPaymentSettings,
} from '~store/slices/paymentsSlice';
import { getBetslip } from '~store/slices/selectors/betslip';
import {
  setWebsiteSettings,
  updateWebsiteSettings,
} from '~store/slices/settingsSlice';
import {
  login,
  logout,
  selectIsUserLoggedIn,
  selectIsUserProfileLoaded,
  setIsUserProfileLoaded,
  setProfile,
} from '~store/slices/userSlice';
import { setBannerData, setMenuContents } from '~store/slices/userUISlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { COOKIES_NAMES, getCookie } from '~utils/cookies';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { getTheme } from '~utils/getTheme';
import isSportWebsite from '~utils/isSportWebsite';

import { semaBetTheme } from '../stitches.config';

import BetonlyFaviconIcon from './assets/betonly-logo.webp';
import SemabetFaviconIcon from './assets/semabet-logo.webp';

type AppInitDataType = {
  isAuthStatusConfirmed: boolean;
};

export const useOnAppInit = () => {
  const { verifyEmailToken, name } = useQueryParams();
  const dispatch = useAppDispatch();

  const language = useAppSelector(selectLanguage);
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const isUserProfileLoaded = useAppSelector(selectIsUserProfileLoaded);
  const { activePaymentTab } = useAppSelector(selectPaymnets);
  const { stakeType } = useAppSelector(getBetslip);

  const { lazyGetUserProfileQuery } = useLazyGetUserProfile();
  const { lazyGetFavoriteMarketsQuery } = useLazyGetFavoriteMarkets();
  const { lazyGetBannerByGroupNameQuery } = useLazyGetBannerByGroupName();
  const { lazySettingsQuery } = useLazySettings();
  const { lazyGetMenuContentQuery } = useLazyGetMenuContent();
  const { lazyGetUserLimitsQuery } = useLazyGetUserLimits();
  const { isMobileOrTablet, isMobile, isTablet } = useMedia();
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const timer2Ref = useRef<NodeJS.Timeout>();
  const prevLanguageRef = useRef(language);
  const { isPrimaryDatsLoaded, isWsConnected, sendWsMessage } =
    useEventsLoadingSocket();

  const isSport = isSportWebsite();

  const [appInitData, setAppInitData] = useState<AppInitDataType>({
    isAuthStatusConfirmed: false,
    // add new parameters that should be confirmed before the content render
  });

  const updateAppInitData = (updatedValue: Partial<AppInitDataType>) => {
    setAppInitData((prevState) => ({
      ...prevState,
      ...updatedValue,
    }));
  };

  const isAppInitDataReady = useMemo(
    () => Object.values(appInitData).every((v) => v),
    [appInitData],
  );

  useCasinoDataLoad();
  useIframeAuthorization();
  useShare();
  useLaunchEventsSocket();
  useListenEventsLoadingSocket();
  useTransactionsUpdate();
  useListenTokens();
  useUpdateWebsiteSettings();
  useSignalRSockets();

  useEffect(() => {
    const path = window.location.pathname;

    if (path.startsWith('//')) {
      window.location.href = '/notFound';
    }
  }, []);

  useEffect(() => {
    if (language === prevLanguageRef.current) return;
    window.location.reload();
  }, [language]);

  useLayoutEffect(() => {
    const accessToken = getCookie(COOKIES_NAMES.ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      dispatch(login());
    }

    const theme = getTheme();

    if (theme === THEMES.SEMA_BET) {
      document.documentElement.classList.add(semaBetTheme.className);
    }

    updateAppInitData({ isAuthStatusConfirmed: true });
  }, []);

  useEffect(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  useEffect(() => {
    if (isWsConnected) {
      sendWsMessage(ACTION_TYPE.GET_COUNTS);
      if (timer2Ref.current) clearTimeout(timer2Ref.current);
      timer2Ref.current = setTimeout(() => {
        sendWsMessage(ACTION_TYPE.GET_MAIN_MARKETS);
      }, 300);
    }

    return () => {
      if (timer2Ref.current) clearTimeout(timer2Ref.current);
    };
  }, [isWsConnected]);

  useEffect(() => {
    if (isPrimaryDatsLoaded) {
      sendWsMessage(ACTION_TYPE.GET_TOP_TOURNAMENTS);
      sendWsMessage(ACTION_TYPE.GET_TOP_GAMES);
    }
  }, [isPrimaryDatsLoaded]);

  useEffect(() => {
    const ongoingGroup: string[] = isMobileOrTablet
      ? bannerGroupNameMobile
      : bannerGroupNameWeb;

    const loadBannerGroups = async () => {
      try {
        const channelType = isMobile
          ? CHANNEL_TYPES.MOBILE
          : isTablet
            ? CHANNEL_TYPES.TABLET
            : CHANNEL_TYPES.WEB;

        const { data: groups } = await lazyGetBannerByGroupNameQuery({
          names: ongoingGroup,
          channelType: channelType,
        });
        const groupsMapping: Record<string, BannerGroup> = {};

        if (groups) {
          ongoingGroup.map((groupName) => {
            groupsMapping[groupName] =
              groups.find(({ name }) => name === groupName) ||
              ({} as BannerGroup);
          });
          dispatch(setBannerData(groupsMapping));
        }
      } catch (error) {
        console.error('Failed getting banners', error);
      }
    };

    if (!isSport) return;

    loadBannerGroups();
  }, [isMobile, isTablet, isMobileOrTablet, language]);

  useEffect(() => {
    const loadProfile = async () => {
      try {
        dispatch(setIsUserProfileLoaded(false));
        const { data } = await lazyGetUserProfileQuery();

        if (data) {
          dispatch(setProfile(data as UserProfileData));
          dispatch(setIsUserProfileLoaded(true));
        } else {
          dispatch(logout());
          window.parent.postMessage({ type: 'LOGOUT' }, '*');
        }
      } catch (error) {
        console.error('Error while loading profile', error);
        dispatch(logout());
        window.parent.postMessage({ type: 'LOGOUT' }, '*');
      }
    };

    if (isUserLoggedIn) {
      loadProfile();
      startTokenRefreshScheduler();
    } else {
      stopTokenRefreshScheduler();
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    function getFaviconUrl() {
      const hostname = window.location.hostname;

      if (
        hostname.includes('semabet.ug') ||
        hostname.includes('semabet.co.zm') ||
        hostname.includes('semabet.co.mz') ||
        hostname.includes('semabet.co.tz')
      ) {
        return SemabetFaviconIcon;
      }

      if (hostname.includes('betonly')) {
        return BetonlyFaviconIcon;
      }

      return null;
    }

    if (document && document.querySelector("link[rel='icon']")) {
      const element = document.querySelector("link[rel='icon']");

      if (element) {
        const favicon = getFaviconUrl();

        if (favicon) {
          element.setAttribute('href', favicon);
        }
      }
    }
  }, []);

  useEffect(() => {
    const loadSettings = async () => {
      const websiteSettings = await lazySettingsQuery().unwrap();
      const { paymentProviderSettings } = websiteSettings;

      dispatch(setWebsiteSettings(websiteSettings));

      if (
        !websiteSettings.allowSystemBets &&
        stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM
      ) {
        dispatch(setStakeType(SPORT_BETSLIP_TYPE_OPTIONS.SINGLE));
      }

      if (paymentProviderSettings.length) {
        dispatch(setAvailablePaymentSettings(paymentProviderSettings));

        if (activePaymentTab) {
          const providerValue = paymentProviderSettings.find(
            (provider) => provider.paymentProvider === activePaymentTab,
          );

          if (providerValue) {
            dispatch(setPaymentSettings(providerValue)); // Setting default provider payment settings
          }
        }
      }

      setIsSettingsLoaded(true);
    };

    const loadMenuContentData = async () => {
      const data = await lazyGetMenuContentQuery(
        '?names=footer&names=header',
      ).unwrap();

      dispatch(setMenuContents(data));
    };

    loadMenuContentData();
    loadSettings();
  }, [language]);

  useEffect(() => {
    if (!isSport) return;
    if (isUserLoggedIn && isSettingsLoaded) {
      const loadUserLimits = async () => {
        try {
          const {
            minStake = 0,
            maxStake = 0,
            minStakePreMatch = 0,
            maxStakePreMatch = 0,
            minStakeInPlay = 0,
            maxStakeInPlay = 0,
            maxWinAmount = 0,
            duplicateBetsCountLimit = 0, // set default value properly
            sportMinSelectionCount = 0, // set default value properly
            sportMaxSelectionCount = 0, // set default value properly
            depositLimitMin = 0, // set default value properly
            depositLimitMax = 0, // set default value properly
            withdrawalLimitMin = 0, // set default value properly
            withdrawalLimitMax = 0, // set default value properly
          } = await lazyGetUserLimitsQuery().unwrap();

          const newBetsConfig: Partial<SportsBettingConfig> = {};
          const newPaymentSettings: Partial<PaymentSettings> = {};
          const otherSettings: Partial<WebsiteSettingsResponse> = {};

          if (minStake) {
            newBetsConfig.sportMinStake = minStake;
          }

          if (maxStake) {
            newBetsConfig.sportMaxStake = maxStake;
          }

          if (minStakePreMatch) {
            newBetsConfig.minStakePreMatch = minStakePreMatch;
          }

          if (maxStakePreMatch) {
            newBetsConfig.maxStakePreMatch = maxStakePreMatch;
          }

          if (minStakeInPlay) {
            newBetsConfig.minStakeInPlay = minStakeInPlay;
          }

          if (maxStakeInPlay) {
            newBetsConfig.maxStakeInPlay = maxStakeInPlay;
          }

          if (sportMinSelectionCount) {
            newBetsConfig.sportMinSelectionCount = sportMinSelectionCount;
          }

          if (sportMaxSelectionCount) {
            newBetsConfig.sportMaxSelectionCount = sportMaxSelectionCount;
          }

          if (maxWinAmount) {
            otherSettings.maxWinAmount = maxWinAmount;
          }

          if (duplicateBetsCountLimit) {
            otherSettings.duplicateBetsCountLimit = duplicateBetsCountLimit;
          }

          if (depositLimitMax) {
            newPaymentSettings.depositLimitMax = depositLimitMax;
          }

          if (depositLimitMin) {
            newPaymentSettings.depositLimitMin = depositLimitMin;
          }

          if (withdrawalLimitMax) {
            newPaymentSettings.withdrawalLimitMax = withdrawalLimitMax;
          }

          if (withdrawalLimitMin) {
            newPaymentSettings.withdrawalLimitMin = withdrawalLimitMin;
          }

          dispatch(
            updateWebsiteSettings({
              otherSettings,
              newBetsConfig,
              newPaymentSettings,
            }),
          );
        } catch (e) {
          console.log(e);
        }
      };

      loadUserLimits();
    }
  }, [isUserProfileLoaded, isSettingsLoaded]);

  useEffect(() => {
    const loadFavoriteMarkets = async () => {
      const favoriteMarketsData = await lazyGetFavoriteMarketsQuery().unwrap();

      dispatch(setFavoriteMarkets(favoriteMarketsData));
    };

    if (isUserProfileLoaded && isUserLoggedIn) {
      loadFavoriteMarkets();
    }
  }, [isUserProfileLoaded]);

  return { isAppInitDataReady };
};
