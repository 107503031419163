import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import { SportEventItem } from '~api/sportEvent/types';
import { MARKET_STATUS } from '~constants/common';
import {
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useAppSelector } from '~store';
import { Market } from '~types/events';

interface EventsUpdatesListenerProps {
  event: SportEventItem;
  onUpdate: (event: SportEventItem) => void;
}

export const useEventUpdatesListener = ({
  event,
  onUpdate,
}: EventsUpdatesListenerProps) => {
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const [socket, setSocket] = useState<HubConnection | null>(null);

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  const handleBetStop = (data: any) => {
    const { id } = data;

    if (id !== event.id) return;
    const resultEvent = { ...event };

    resultEvent.markets = resultEvent.markets.map((market) => {
      market.status = MARKET_STATUS.SUSPENDED;

      return market;
    });

    onUpdate(resultEvent);
  };

  const handleExtraDataUpdate = (response: any) => {
    const { extraData, id } = response;
    const eventRef = event;

    if (id !== event.id) return;

    onUpdate({
      ...eventRef,
      extraData,
    });
  };

  const handleMarketUpdate = (response: any) => {
    const { active, ...eventData } = response;
    const { eventId } = eventData;
    const eventRef = event;

    if (eventId !== eventRef.id) return;

    const eventCopy = { ...eventRef };

    eventCopy.marketsCount = active;

    const marketIndex = eventCopy.markets.findIndex(
      (market) => market.id === eventData.id,
    );

    const marketsCopy = [...eventCopy.markets];

    if (marketIndex !== -1) {
      marketsCopy[marketIndex] = eventData;
    } else {
      marketsCopy.push(response);
    }

    eventCopy.markets = marketsCopy;

    onUpdate(eventCopy);
  };

  const handleMarketsUpdate = (response: any) => {
    const { markets, eventId, active } = response;
    const eventRef = event;

    if (eventId !== eventRef.id) return;

    const eventCopy = { ...eventRef };

    eventCopy.marketsCount = active;

    const marketsCopy = [...eventCopy.markets];

    markets.forEach((market: Market) => {
      const marketIndex = marketsCopy.findIndex(
        (marketItem) => marketItem.id === market.id,
      );

      if (marketIndex !== -1) {
        marketsCopy[marketIndex] = market;
      } else {
        marketsCopy.push(market);
      }
    });

    eventCopy.markets = marketsCopy;

    onUpdate(eventCopy);
  };

  useEffect(() => {
    const eventId = event?.id;

    if (!eventId) return;
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.BET_STOP_MESSAGE, handleBetStop);
    socket?.on(
      SIGNAL_R_SOCKET_MESSAGES.EXTRA_DATA_MESSAGE,
      handleExtraDataUpdate,
    );
    socket?.on(
      SIGNAL_R_SOCKET_MESSAGES.MARKET_UPDATE_MESSAGE,
      handleMarketUpdate,
    );
    socket?.on(
      SIGNAL_R_SOCKET_MESSAGES.MARKETS_UPDATE_MESSAGE,
      handleMarketsUpdate,
    );

    return () => {
      socket?.off(SIGNAL_R_SOCKET_MESSAGES.BET_STOP_MESSAGE, handleBetStop);
      socket?.off(
        SIGNAL_R_SOCKET_MESSAGES.EXTRA_DATA_MESSAGE,
        handleExtraDataUpdate,
      );
      socket?.off(
        SIGNAL_R_SOCKET_MESSAGES.MARKET_UPDATE_MESSAGE,
        handleMarketUpdate,
      );
      socket?.off(
        SIGNAL_R_SOCKET_MESSAGES.MARKETS_UPDATE_MESSAGE,
        handleMarketsUpdate,
      );
    };
  }, [socket, event]);
};
