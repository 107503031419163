import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';

export const StyledBetslipHistoryTabsWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '$2 $4',
  borderTop: '1px solid $grayDark',
  borderBottom: '1px solid $grayDark',
  margin: '-16px', // Ignore padding of the parent Dialog component in order to match the design
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  backgroundColor: '$dialogBgColor',
});

export const StyledActiveParamsNumber = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: '-8px',
  bottom: '-5px',
  backgroundColor: '$green',
  width: '$5',
  height: '$4',
  borderRadius: '$2',
});

export const StyledFilterButton = styled(Button, {
  width: 32,
  height: 32,
  overflow: 'visible',
});
