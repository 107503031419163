import React, { memo } from 'react';

import { MainMarket } from '~api/market/types';
import { Text } from '~components/atoms/Typography';
import {
  HeaderBottomStyled,
  HeaderTopStyled,
} from '~components/molecules/MainHighlights/styled.components';

import { MainMarketNamesList } from './MainMarketNamesList';
import { MainMarketSelectionNamesList } from './MainMarketSelectionNamesList';
interface MatchHighlightsMarketsHeadersProps {
  mainMarkets: MainMarket[];
  label?: string;
}

export const MatchHighlightsMarketsHeaders = memo(
  ({ mainMarkets, label }: MatchHighlightsMarketsHeadersProps) => {
    return (
      <>
        <HeaderTopStyled>
          <Text level="16-20">{label}</Text>
          {!!mainMarkets?.length && (
            <MainMarketNamesList mainMarkets={mainMarkets} />
          )}
        </HeaderTopStyled>
        {!!mainMarkets?.length && (
          <HeaderBottomStyled>
            <MainMarketSelectionNamesList mainMarkets={mainMarkets} />
          </HeaderBottomStyled>
        )}
      </>
    );
  },
);
