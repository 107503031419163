import React, { ComponentPropsWithRef, forwardRef } from 'react';

import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { SPORTS } from '~constants/sport';
import { SVGElement } from '~types/general';
import { getSportIcon } from '~utils/getSportIcon';

import {
  StyledSportSliderItem,
  styledSportSliderTagCSS,
} from './styled.components';

interface SportSliderItemProps extends ComponentPropsWithRef<'div'> {
  sport: string;
  count?: string | number;
  icon?: SVGElement;
  isDesktop?: boolean;
  withCounts?: boolean;
  onClick?: () => void;
}

export const SportSliderItem = forwardRef<HTMLDivElement, SportSliderItemProps>(
  (
    { sport, count, icon: Icon, isDesktop, withCounts = false, ...props },
    ref,
  ) => (
    <StyledSportSliderItem {...props} ref={ref}>
      <Box
        className="icon"
        css={{
          position: 'relative',
          lineHeight: 0,
          '& > *': {
            cursor: 'pointer',
          },
        }}
      >
        {Icon && <Icon />}
        {getSportIcon(sport as SPORTS)}
        <Tag
          text={count}
          className={`${withCounts && 'with-counts'} tag}`}
          color="transparent"
          css={{ ...styledSportSliderTagCSS }}
        />
      </Box>
      <Text
        className="text"
        level={isDesktop ? '14-20' : '10-20'}
        css={{
          cursor: 'pointer',
        }}
      >
        {sport}
      </Text>
    </StyledSportSliderItem>
  ),
);
