import { PaymentAccountsRadios } from '~components/molecules/UserProfile/components/Payments/components/PaymentAccounts/PaymentAccountsRadios';
import { useTranslation } from '~hooks/useTranslation';

import { usePaymentAccounts } from '../../hooks/usePaymentAccounts';

import {
  Container,
  HorizontalSeparator,
  SubContainer,
  Title,
} from './styled.components';

export const PaymentAccounts = () => {
  const { localized } = useTranslation();
  const {
    paystackDepositCards,
    flutterwaveDepositCards,
    withdrawBanks,
    PAYMENT_ACCOUNTS,
    handleDefaultAccountChange,
    handleRemoveDefaultAccount,
  } = usePaymentAccounts();

  return (
    <Container flexRow>
      <SubContainer css={{ borderRight: '1px solid $gray' }}>
        <Title>{localized('paymentAccounts.creditCards')}</Title>
        <HorizontalSeparator />
        <Title level="12-20" color="grayMedium">
          {`${localized('paymentAccounts.paystack')} (${localized(
            'paymentAccounts.deposit',
          )})`}
        </Title>
        <PaymentAccountsRadios
          items={paystackDepositCards}
          emptyTitle={'paymentAccounts.emptyCardAccounts.title'}
          emptyDescription={'paymentAccounts.emptyCardAccounts.title'}
          onChange={(value: string) =>
            handleDefaultAccountChange(
              value,
              PAYMENT_ACCOUNTS.PAYSTACK_DEPOSIT_CARDS,
            )
          }
          onRemove={(id: string) =>
            handleRemoveDefaultAccount(
              id,
              PAYMENT_ACCOUNTS.PAYSTACK_DEPOSIT_CARDS,
            )
          }
        />
        <HorizontalSeparator />
        <Title level="12-20" color="grayMedium">
          {`${localized('paymentAccounts.flutterwave')} (${localized(
            'paymentAccounts.deposit',
          )})`}
        </Title>
        <PaymentAccountsRadios
          items={flutterwaveDepositCards}
          emptyTitle={'paymentAccounts.emptyCardAccounts.title'}
          emptyDescription={'paymentAccounts.emptyCardAccounts.title'}
          onChange={(value: string) =>
            handleDefaultAccountChange(
              value,
              PAYMENT_ACCOUNTS.FLUTTERWAVE_DEPOSIT_CARDS,
            )
          }
          onRemove={(id: string) =>
            handleRemoveDefaultAccount(
              id,
              PAYMENT_ACCOUNTS.FLUTTERWAVE_DEPOSIT_CARDS,
            )
          }
        />
      </SubContainer>
      <SubContainer>
        <Title>{localized('paymentAccounts.bankAccounts')}</Title>
        <HorizontalSeparator />
        <PaymentAccountsRadios
          isBanks
          items={withdrawBanks}
          emptyTitle={'paymentAccounts.emptyBankAccounts.title'}
          emptyDescription={'paymentAccounts.emptyBankAccounts.title'}
          onChange={(value: string) =>
            handleDefaultAccountChange(value, PAYMENT_ACCOUNTS.WITHDRAW_BANKS)
          }
          onRemove={(id: string) =>
            handleRemoveDefaultAccount(id, PAYMENT_ACCOUNTS.WITHDRAW_BANKS)
          }
        />
      </SubContainer>
    </Container>
  );
};
