import { LIVE_GROUP_COMPONENTS } from '~components/molecules/LiveMenu/constants';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { useQueryParams } from '~hooks/useQueryParams';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import {
  setIsFavoriteEventsOpen,
  setIsTopLiveEventsOpen,
} from '~store/slices/liveGroupsSlice';
import { SPORT_GROUPS } from '~types/sportGroups';

interface InplayEventBreadcrumbHookProps {
  menu: MOBILE_MENU;
  countryName?: string;
  leagueName?: string;
  name?: string;
}

export const useInplayEventBreadcrumb = ({
  countryName,
  leagueName,
  name,
}: InplayEventBreadcrumbHookProps) => {
  const { localized } = useTranslation();
  const { breadcrumbLabel } = useAppSelector((state) => state.menus);
  const dispatch = useAppDispatch();
  const search = useQueryParams();
  const isBreadcrumbLabelTopGames = breadcrumbLabel === SPORT_GROUPS.TOP_EVENTS;
  const isBreadcrumbLabelFavoriteGames =
    breadcrumbLabel === LIVE_GROUP_COMPONENTS.FAVORITE_EVENT;

  const getLabel = () => {
    if (isBreadcrumbLabelTopGames) {
      return localized('inplay.topEvents');
    }

    if (isBreadcrumbLabelFavoriteGames) {
      return localized('inplay.favoriteEvents');
    }

    return `${countryName} - ${leagueName}`;
  };

  const populateInplayEventBreadcrumbs = () => {
    dispatch(
      setBreadcrumbTrail([
        {
          label: getLabel(),
          onClickCallback: () => {
            const searchCopy = { ...search };

            delete searchCopy.eventId;

            if (isBreadcrumbLabelTopGames) {
              dispatch(setIsFavoriteEventsOpen(false));
              dispatch(setIsTopLiveEventsOpen(true));
            }

            if (isBreadcrumbLabelFavoriteGames) {
              dispatch(setIsFavoriteEventsOpen(true));
              dispatch(setIsTopLiveEventsOpen(false));
            }
          },
        },
        {
          label: name || '',
        },
      ]),
    );
  };

  return {
    populateInplayEventBreadcrumbs,
  };
};
