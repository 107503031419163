import React from 'react';

import { Box } from '~components/atoms/Box';
import { InfoPopover } from '~components/atoms/InfoPopover';
import { CURRENCY_SYMBOLS } from '~constants/common';
import {
  JACKPOT_SETTLEMENT_STATUS,
  JACKPOT_SETTLEMENT_STATUS_COLORS,
  JACKPOT_SETTLEMENT_STATUS_LABELS,
  JACKPOT_STATUS_COLORS,
  JACKPOT_STATUS_LABELS,
} from '~constants/jackpot';
import { SPORTS } from '~constants/sport';
import { useStakePayout } from '~hooks';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setSelectedJackpotHistoryId } from '~store/slices/betslipSlice';
import { JackpotBetsHistory } from '~types/jackpot';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';
import { formatNumberToStringWithSpaces } from '~utils/numberUtils';

import { InfoContainer, InfoText } from './styled.components';

export const JackpotBetListItem = ({
  jackpot,
}: {
  jackpot: JackpotBetsHistory;
}) => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);
  const currencySymbol = CURRENCY_SYMBOLS[currency];
  const {
    id,
    name,
    sportName,
    status,
    firstEventStartDate,
    marketName,
    ticketsCount,
    stake,
    userWinAmount,
    settlement,
    incomeTaxPercent,
    stakeTaxPercent,
    stakeTax,
    stakeTaxPayer,
  } = jackpot;

  // Set unsettled status if settlement is not provided
  const settlementStatus = settlement
    ? settlement
    : JACKPOT_SETTLEMENT_STATUS.UNSETTLED;

  const formattedStake = `${formatNumberToStringWithSpaces(
    stake,
  )} ${currencySymbol}`;
  const formattedWinAmount = `${formatNumberToStringWithSpaces(
    userWinAmount,
  )} ${currencySymbol}`;

  const { showPayoutInfo, payoutInfoItems } = useStakePayout({
    stake: stake + stakeTax,
    stakeAfterTax: stake,
    stakeTax,
    stakeTaxPercent,
    stakeTaxPayer,
    incomeTaxPercent,
  });

  const handleEventDetailsClick = () => {
    dispatch(setSelectedJackpotHistoryId(id));
  };

  return (
    <Box
      flexCol
      css={{
        borderRadius: '$8',
        backgroundColor: '$betslipHistoryMobileItemBgColor',
      }}
    >
      <InfoContainer>
        <Box flexRow alignCenter gap={2} justifyContentBetween>
          <Box css={{ color: '$grayMedium' }}>
            {getSportIcon(sportName as SPORTS, { width: 16, height: 16 })}
          </Box>
          <Box flexCol>
            <InfoText color="grayMedium">{name}</InfoText>
            <InfoText color="grayMedium">{`ID: ${id}`}</InfoText>
          </Box>
        </Box>
        <Box flexCol alignEnd>
          <Box flexRow gap={1}>
            <InfoText color={JACKPOT_STATUS_COLORS[status]}>
              {JACKPOT_STATUS_LABELS[status]}
            </InfoText>
            <InfoText color={JACKPOT_STATUS_COLORS[status]}>/</InfoText>
            <InfoText
              color={JACKPOT_SETTLEMENT_STATUS_COLORS[settlementStatus]}
            >
              {JACKPOT_SETTLEMENT_STATUS_LABELS[settlementStatus]}
            </InfoText>
          </Box>

          <InfoText color="grayMedium" textAlign="right">
            {formatDateTime(firstEventStartDate, TIME_FORMATS.DATE_TIME_12H)}
          </InfoText>
        </Box>
      </InfoContainer>
      <InfoContainer>
        <InfoText>{localized('jackpot.market')}</InfoText>
        <InfoText>{marketName}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText>{localized('jackpot.ticketsStake')}</InfoText>
        <Box flexRow gap={1}>
          <InfoText>{ticketsCount}</InfoText>
          <InfoText>/</InfoText>
          <Box flexRow gap={2} alignCenter>
            <InfoText color="yellow" fontWeight="medium">
              {formattedStake}
            </InfoText>
            {showPayoutInfo && (
              <InfoPopover
                title={`${localized('betslip.payoutPopover.info')}:`}
                itemsList={payoutInfoItems}
              />
            )}
          </Box>
        </Box>
      </InfoContainer>
      <InfoContainer flexRow css={{ borderBottom: 'none' }}>
        <InfoText>{localized('jackpot.win')}</InfoText>
        <InfoText color="yellow" fontWeight="medium">
          {formattedWinAmount}
        </InfoText>
      </InfoContainer>
      <InfoContainer
        flexRow
        justifyCenter
        css={{
          backgroundColor: '$gray',
          borderRadius: '0 0 $8 $8',
          cursor: 'pointer',
        }}
        onClick={handleEventDetailsClick}
      >
        <InfoText color="white" textTransform="uppercase" fontWeight="medium">
          {localized('jackpot.eventsDetails')}
        </InfoText>
      </InfoContainer>
    </Box>
  );
};
