import { useContext, useEffect } from 'react';

import { BetslipHistoryItems } from '~api/betslip/types';
import {
  SharedRefContext,
  SharedRefContextProps,
} from '~contexts/SharedRefContext';
import debounce from '~utils/debounce';

interface UseTransactionHistoryListProps {
  loadMore: () => void;
  betHistory: BetslipHistoryItems;
  dataLength?: number;
}

export const useBetHistoryList = ({
  loadMore,
  betHistory,
  dataLength,
}: UseTransactionHistoryListProps) => {
  const context = useContext(SharedRefContext) as SharedRefContextProps;

  const isScrolledToBottom = (element: HTMLDivElement) => {
    return element.scrollHeight - element.scrollTop <= element.clientHeight + 5;
  };

  useEffect(() => {
    if (betHistory.length === dataLength) return;
    const { ref: dialogMobileContentWrapperRef } = context;
    const handleDirectScroll = debounce(400, (event: Event) => {
      const element = event.target as HTMLDivElement;

      if (isScrolledToBottom(element)) {
        loadMore();
      }
    });
    const current = dialogMobileContentWrapperRef.current;

    if (current) {
      current.addEventListener('scroll', handleDirectScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleDirectScroll);
      }
    };
  }, [betHistory, dataLength]);
};
