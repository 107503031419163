export interface ScoreColumn {
  title: string;
  home: string | number;
  away: string | number;
}

interface PreparedPeriodScore {
  homeScore: number;
  awayScore: number;
  setNumber: number;
}
interface GetScoresProps {
  gamePeriodName: string;
  localized: (key: string, args?: object) => string;
  homeTeamScore?: number;
  awayTeamScore?: number;
  periodScores?: PreparedPeriodScore[];
}
export const getScores = ({
  localized,
  homeTeamScore,
  awayTeamScore,
  periodScores,
  gamePeriodName,
}: GetScoresProps) => {
  const scores: ScoreColumn[] = [];

  if (homeTeamScore !== undefined && awayTeamScore !== undefined) {
    scores.push({
      title: localized('sportsTerms.finalScore'),
      home: homeTeamScore,
      away: awayTeamScore,
    });
  }

  periodScores &&
    periodScores.forEach(({ homeScore, awayScore, setNumber }) => {
      scores.push({
        title: localized(
          `event.matchStatus.${gamePeriodName}_${
            setNumber <= 3 ? setNumber : 'N'
          }`,
          {
            N: setNumber,
          },
        ),
        home: homeScore,
        away: awayScore,
      });
    });

  return scores;
};
