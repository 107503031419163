import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SWIPE_COMPONENT_TYPES } from '~components/atoms/SwipeComponent';
import { MAIN_PAGE_TABS } from '~components/molecules/MainHighlights/helpers';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import {
  USER_BONUSES_TABS,
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
  USER_PROFILE_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import {
  LIVE_TAB_CONTENTS,
  SPORT_TAB_CONTENTS,
} from '~components/organisms/MobileTabContents';
import { SportTabGroups } from '~types/events';

export type MobileUserProfileDialogActiveContent =
  | USER_PROFILE_TABS
  | USER_BONUSES_TABS
  | USER_PAYMENT_TABS
  | Exclude<
      USER_PROFILE_NAVIGATION_TABS,
      | USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE
      | USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT
    >;

export interface MobileState {
  mobileHeaderHeight: number;
  isSwipeOpen: boolean;
  swipeContent: SWIPE_COMPONENT_TYPES | null;
  mobileHeaderInDialogHeight: number;
  mobileActiveTab: MOBILE_MENU | null;
  liveTabActiveContent: LIVE_TAB_CONTENTS;
  sportTabActiveContent: SPORT_TAB_CONTENTS;
  mobileUserProfileDialogState: USER_PROFILE_DIALOG_MOBILE_STATE;
  mobileUserProfileDialogActiveContent: MobileUserProfileDialogActiveContent | null;
  mobileMainPageTab: MAIN_PAGE_TABS | null;
  locationScrollPositions: Record<string, number>;
  refreshBreadcrumb?: boolean;
  scrollTopMainPage?: boolean;
  activeSportTabGroup: SPORT_TAB_GROUPS | null;
  sportTabGroups: SportTabGroups;
  isFavoriteSelected: boolean;
}

const initialState: MobileState = {
  mobileHeaderHeight: 0,
  isSwipeOpen: false,
  swipeContent: null,
  mobileHeaderInDialogHeight: 0,
  mobileActiveTab: null,
  liveTabActiveContent: LIVE_TAB_CONTENTS.LIVE_MENU,
  sportTabActiveContent: SPORT_TAB_CONTENTS.PREMATCH_MENU,
  mobileUserProfileDialogState: USER_PROFILE_DIALOG_MOBILE_STATE.SIDEBAR,
  mobileUserProfileDialogActiveContent: null,
  mobileMainPageTab: MAIN_PAGE_TABS.UPCOMING,
  locationScrollPositions: {},
  refreshBreadcrumb: false,
  scrollTopMainPage: false,
  activeSportTabGroup: SPORT_TAB_GROUPS.UPCOMMING,
  sportTabGroups: [],
  isFavoriteSelected: false,
};

export const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setMobileHeaderHeight: (state, action: PayloadAction<number>) => {
      state.mobileHeaderHeight = action.payload;
    },
    setMobileHeaderInDialogHeight: (state, action: PayloadAction<number>) => {
      state.mobileHeaderInDialogHeight = action.payload;
    },
    setIsSwipeOpen: (
      state,
      action: PayloadAction<SWIPE_COMPONENT_TYPES | null>,
    ) => {
      state.isSwipeOpen = !!action.payload;
      state.swipeContent = action.payload;
    },
    setMobileActiveTab: (state, action: PayloadAction<MOBILE_MENU | null>) => {
      state.mobileActiveTab = action.payload;
    },
    setLiveTabActiveContent: (
      state,
      action: PayloadAction<LIVE_TAB_CONTENTS>,
    ) => {
      state.liveTabActiveContent = action.payload;
    },
    setSportTabActiveContent: (
      state,
      action: PayloadAction<SPORT_TAB_CONTENTS>,
    ) => {
      state.sportTabActiveContent = action.payload;
    },
    setMobileUserProfileDialogState: (
      state,
      action: PayloadAction<USER_PROFILE_DIALOG_MOBILE_STATE>,
    ) => {
      state.mobileUserProfileDialogState = action.payload;
    },
    setMobileUserProfileDialogActiveContent: (
      state,
      action: PayloadAction<MobileUserProfileDialogActiveContent | null>,
    ) => {
      state.refreshBreadcrumb =
        state.mobileUserProfileDialogActiveContent === action.payload;
      state.mobileUserProfileDialogActiveContent = action.payload;
    },
    setMobileMainPageTab: (
      state,
      action: PayloadAction<MAIN_PAGE_TABS | null>,
    ) => {
      state.mobileMainPageTab = action.payload;
    },
    addLocationScrollPosition: (
      state,
      action: PayloadAction<{ path: string; scroll: number }>,
    ) => {
      const { path, scroll } = action.payload;

      state.locationScrollPositions[path] = scroll;
    },
    removeLocationScrollPosition: (state, action: PayloadAction<string>) => {
      delete state.locationScrollPositions[action.payload];
    },
    clearLocationScrollPositions: (state) => {
      state.locationScrollPositions = {};
    },
    setScrollTopMainPage: (state, action: PayloadAction<boolean>) => {
      state.scrollTopMainPage = action.payload;
    },
    setActiveSportTabGroup: (
      state,
      action: PayloadAction<SPORT_TAB_GROUPS>,
    ) => {
      state.activeSportTabGroup = action.payload;
    },
    setSportTabGroups: (state, action: PayloadAction<SportTabGroups>) => {
      state.sportTabGroups = action.payload;
    },
    setIsFavoriteSelected: (state, action: PayloadAction<boolean>) => {
      state.isFavoriteSelected = action.payload;
    },
  },
});

export const {
  setMobileHeaderHeight,
  setMobileHeaderInDialogHeight,
  setMobileActiveTab,
  setLiveTabActiveContent,
  setSportTabActiveContent,
  setMobileUserProfileDialogState,
  setMobileUserProfileDialogActiveContent,
  setIsSwipeOpen,
  setMobileMainPageTab,
  addLocationScrollPosition,
  removeLocationScrollPosition,
  clearLocationScrollPositions,
  setScrollTopMainPage,
  setActiveSportTabGroup,
  setSportTabGroups,
  setIsFavoriteSelected,
} = mobileSlice.actions;

export default mobileSlice.reducer;
