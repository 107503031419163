import { Transaction, Transactions } from '~api/transaction/types';
import { Box } from '~components/atoms/Box';

import { useTransactionHistoryList } from '../hooks/useTransactionHistoryList';

import { TransactionHistoryListItem } from './TransactionHistoryListItem';

export interface TransactionHistoryListProps {
  transactions: Transactions;
  isAllTransactions?: boolean;
  dataLength?: number;
  loadMore?: () => void;
}

export const TransactionHistoryList = ({
  transactions,
  isAllTransactions,
  dataLength,
  loadMore = () => {},
}: TransactionHistoryListProps) => {
  useTransactionHistoryList({ loadMore, transactions, dataLength });

  return (
    <Box flexCol>
      {transactions.map((transaction: Transaction) => (
        <TransactionHistoryListItem
          isAllTransactions={isAllTransactions}
          transaction={transaction}
          key={transaction.id}
        />
      ))}
    </Box>
  );
};
