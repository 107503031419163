import { Box } from '~components/atoms/Box';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { Select } from '~components/atoms/Select';
import { FilterIcon } from '~icons';

import { StyledButton, StyledText } from './styled.components';
import { useBonusHistoryFilters } from './useBonusHistoryFilters';

interface BonusHistoryFiltersMobileProps {
  showFilters: boolean;
  setShowFilters: (showFilters: boolean) => void;
}

export const BonusHistoryFiltersMobile = ({
  showFilters,
  setShowFilters,
}: BonusHistoryFiltersMobileProps) => {
  const {
    activeParamsNumber,
    selectedDates,
    statusesOptions,
    statusValue,
    minimumAge,
    handleReset,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  } = useBonusHistoryFilters();

  const handleShowResults = () => {
    setShowFilters(false);
    handleUpdateHistory();
  };

  if (!showFilters) {
    return (
      <Box flexRow gap={2}>
        <StyledButton
          css={{ position: 'relative' }}
          onClick={() => setShowFilters(true)}
        >
          <Box
            flexRow
            alignCenter
            justifyCenter
            gap={2}
            css={{ color: '$white' }}
          >
            <FilterIcon />
            <StyledText>{localized('bonuses.button.filters')}</StyledText>
            {activeParamsNumber > 0 && (
              <Box
                flexRow
                alignCenter
                justifyCenter
                css={{
                  position: 'absolute',
                  right: '10px',
                  backgroundColor: '$green',
                  width: '$5',
                  height: '$4',
                  borderRadius: '$2',
                }}
              >
                <StyledText
                  smallFont
                  css={{
                    color: '$buttonTextColor !important',
                  }}
                >
                  {activeParamsNumber}
                </StyledText>
              </Box>
            )}
          </Box>
        </StyledButton>
        <StyledButton onClick={handleReset}>
          <StyledText>{localized('bonuses.button.resetFilters')}</StyledText>
        </StyledButton>
      </Box>
    );
  }

  return (
    <Box flexCol gap={4}>
      <Box
        flexCol
        gap={2}
        css={{ backgroundColor: '$grayDarkMain2', p: '$3', borderRadius: '$8' }}
      >
        <Box flexRow gap={3}>
          <DateRangePicker
            isFutureDatesDisabled
            onOkRange={(dates) => setSelectedDates(dates)}
            value={selectedDates}
            legalAge={minimumAge}
            isFullWidth
          />
        </Box>
        <Select
          ariaLabel={localized('bonuses.status.all')}
          placeholder={localized('bonuses.status.all')}
          value={statusValue.toString()}
          onChange={(value) => setStatusValue(value as string)}
          options={statusesOptions}
          css={{
            height: '$7',
            borderRadius: '$6',
            backgroundColor: '$filtersInputMobileBgColor',
            pl: '$1',
          }}
        />
      </Box>
      <Box flexRow gap={2}>
        <StyledButton
          onClick={() => setShowFilters(false)}
          css={{
            backgroundColor: '$closeFiltersButtonBgColor',
          }}
        >
          <StyledText>{localized('bonuses.button.closeFilters')}</StyledText>
        </StyledButton>
        <StyledButton variant="primary" onClick={handleShowResults}>
          <StyledText
            css={{
              color: '$buttonTextColor !important',
            }}
          >
            {localized('bonuses.button.show')}
          </StyledText>
        </StyledButton>
      </Box>
    </Box>
  );
};
