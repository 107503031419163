import './assets/flags/freakflags.css';

import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { injectGlobalStyles } from 'stitches.config';

import { ErrorBoundary } from '~services/ErrorBoundary';
import { persistor, store } from '~store';

import i18n from './localization/i18n/config';
import App from './App';

// Removing loading on init
document.querySelector('html')?.removeAttribute('style');

injectGlobalStyles();

// Add Google Tag Manager
if (
  (import.meta.env.VITE_MODE as string) === 'production' &&
  window.location.hostname.includes('semabet')
) {
  TagManager.initialize({
    gtmId: 'GTM-NT6QL4TL',
  });
}

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <Router>
            <App />
          </Router>
        </ErrorBoundary>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
);
