import React from 'react';

import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useDocumentDownload } from '~hooks/useDocumentDownload';
import { useTranslation } from '~hooks/useTranslation';

interface DocumentDownloadButtonProps {
  downloadFileName: string;
  url: string;
}

export const DocumentDownloadButton = ({
  downloadFileName,
  url,
}: DocumentDownloadButtonProps) => {
  const { localized } = useTranslation();
  const { downloadFile } = useDocumentDownload();

  return (
    <Button
      size="medium"
      fullWidth
      css={{
        backgroundColor: '$gray',
        borderRadius: '$4',
        boxShadow: 'none',
        fontFamily: 'inherit',
        p: '$1',
      }}
      onClick={async () => {
        await downloadFile(url, downloadFileName);
      }}
    >
      <Text
        level="12-20"
        textAlign="center"
        fontWeight="medium"
        textTransform="uppercase"
      >
        {localized('userProfile.personalProfileInfo.download')}
      </Text>
    </Button>
  );
};
