import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { EVENT_STATUS } from '~constants/common';
import { ellipsis } from '~utils/cssUtils';
import { formatDateTime } from '~utils/formatDateTime';

import { useSearchResult } from './hooks/useSearchResult';

interface SearchResultProps {
  leagueName: string;
  eventTitle: string;
  eventDate: string;
  status: EVENT_STATUS;
  sportId: number;
  countryId: number;
  leagueId: number;
  eventId: string;
}

const SearchResultContainer = styled(Box, {
  p: '$2 $4',
  cursor: 'pointer',
  backgroundColor: '$grayDark',
  borderRadius: '$2',
});

export const SearchResult = ({
  leagueName,
  eventDate,
  eventTitle,
  status,
  sportId,
  countryId,
  leagueId,
  eventId,
}: SearchResultProps) => {
  const { isLive, localized, handleSearchResultClick } = useSearchResult(
    status,
    sportId,
    countryId,
    leagueId,
    eventId,
  );

  return (
    <SearchResultContainer flexCol onClick={handleSearchResultClick}>
      <Text level="xs-4" css={ellipsis}>
        {leagueName}
      </Text>
      <Text level="xxs-4" css={ellipsis}>
        {eventTitle}
      </Text>
      <Box flexRow alignCenter gap={2}>
        <Text level="xxs-4" color="grayMedium">
          {formatDateTime(eventDate)}
        </Text>
        {isLive && (
          <Tag
            color="yellow"
            text={localized('search.results.live')}
            size="small"
            css={{
              height: '14px',
              lineHeight: '$14',
            }}
          />
        )}
      </Box>
    </SearchResultContainer>
  );
};
