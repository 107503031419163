import React, { forwardRef, memo, ReactNode } from 'react';
import { CSS } from '@stitches/react';

import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { SportIconKey } from '~constants/sport';
import { useMedia } from '~hooks/useMedia';
import { getSportIcon } from '~utils/getSportIcon';

import {
  StyledContentMultiAccordion,
  StyledHeader,
  StyledMultiAccordionItem,
  StyledMultiAccordionRoot,
  StyledMultiAccordionTrigger,
} from './styled.components';

export enum MULTI_ACCORDION_ROLES {
  PARENT = 'parent',
  CHILD = 'child',
}

interface TriggerPrefixProps {
  children: ReactNode;
}

interface TriggerPostfixProps extends TriggerPrefixProps {}

const TriggerPrefix = ({ children }: TriggerPrefixProps) => (
  <Box
    css={{
      position: 'absolute',
      left: '14px',
      '@xs_sm': {
        left: '$4',
      },
    }}
    className="prefix"
  >
    {children}
  </Box>
);

const TriggerPostfix = ({ children }: TriggerPostfixProps) => (
  <Box
    css={{
      position: 'absolute',
      right: '$3',
      '@xs_sm': {
        right: '$4',
      },
    }}
    className="postfix"
  >
    {children}
  </Box>
);

export const MultiAccordionRoot = StyledMultiAccordionRoot;

export const MultiAccordionItem = StyledMultiAccordionItem;

interface MultiAccordionTriggerProps {
  css?: CSS;
  title: string;
  secondTitle?: string;
  icon?: SportIconKey;
  count?: string | number | null;
  role?: MULTI_ACCORDION_ROLES;
  country?: string;
  code?: string | null;
  isCountTransparent?: boolean;
  iconComponent?: ReactNode;
  isPrefixWithPadding?: boolean;
}

export const MultiAccordionTrigger = memo(
  forwardRef<HTMLButtonElement, MultiAccordionTriggerProps>(
    (
      {
        css,
        title,
        secondTitle,
        icon,
        count,
        country,
        code,
        iconComponent,
        isPrefixWithPadding,
        isCountTransparent = false,
        role = MULTI_ACCORDION_ROLES.PARENT,
        ...props
      }: MultiAccordionTriggerProps,
      forwardedRef,
    ) => {
      const { isMobileOrTablet } = useMedia();

      return (
        <StyledHeader>
          <StyledMultiAccordionTrigger
            {...props}
            css={css}
            ref={forwardedRef}
            role={role}
            isPrefixWithPadding={isPrefixWithPadding}
          >
            <Box flexCol css={{ overflow: 'hidden' }}>
              <Text className="text" css={{ color: 'inherit' }} level="14-20">
                {title}
              </Text>
              {secondTitle && (
                <Text className="text" css={{ color: 'inherit' }} level="14-20">
                  {secondTitle}
                </Text>
              )}
            </Box>
            {(icon || iconComponent) && (
              <TriggerPrefix>
                {icon ? getSportIcon(icon) : iconComponent}
              </TriggerPrefix>
            )}
            {country && (
              <TriggerPrefix>
                <Flag
                  countryName={country as CountryName}
                  code={code}
                  size={isMobileOrTablet ? 'sm' : 'md'}
                />
              </TriggerPrefix>
            )}

            <TriggerPostfix>
              <Tag
                color={isCountTransparent ? 'transparent' : 'grayDark'}
                className={!isCountTransparent ? 'count' : 'tag'}
                text={count as number}
              />
            </TriggerPostfix>
          </StyledMultiAccordionTrigger>
        </StyledHeader>
      );
    },
  ),
);

interface MultiAccordionContentProps {
  children: React.ReactNode;
  css?: CSS;
  role?: MULTI_ACCORDION_ROLES;
}

export const MultiAccordionContent = memo(
  forwardRef<HTMLDivElement, MultiAccordionContentProps>(
    ({ children, css, role, ...props }, forwardedRef) => (
      <StyledContentMultiAccordion
        {...props}
        role={role}
        ref={forwardedRef}
        css={css}
      >
        <Box className="contentWrapper">{children}</Box>
      </StyledContentMultiAccordion>
    ),
  ),
);
