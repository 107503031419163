import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { useTransactionHistory } from './hooks/useTransactionHistory';
import { TransactionHistoryFilterMobile } from './TransactionHistoryFilter';
import { TransactionHistoryList } from './TransactionHistoryList';

interface TransactionHistoryMobileProps {
  isAllTransactions?: boolean;
}

export const TransactionHistoryMobile = ({
  isAllTransactions = false,
}: TransactionHistoryMobileProps) => {
  const { localized } = useTranslation();
  const { transactions, isLoading, total, handleLoadMore } =
    useTransactionHistory(isAllTransactions);
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Box flexCol gap={4}>
      {!isAllTransactions && (
        <TransactionHistoryFilterMobile
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
      )}

      {!showFilters &&
        (isLoading ? (
          <Box flexCol alignCenter gap={2} css={{ pt: '2rem' }}>
            <Loader css={{ color: '$grayMedium', width: '$6', height: '$6' }} />
            <Text color="grayMedium" level="12-20">
              {localized('transactionHistory.loading')}
            </Text>
          </Box>
        ) : !transactions.length ? (
          <Box flexRow justifyCenter css={{ pt: '$9' }}>
            <Text color="grayMedium" level="12-20">
              {localized('transactionHistory.emptyMobile')}
            </Text>
          </Box>
        ) : (
          <TransactionHistoryList
            transactions={transactions}
            dataLength={total}
            isAllTransactions={isAllTransactions}
            loadMore={handleLoadMore}
          />
        ))}
    </Box>
  );
};
