import { CSS } from 'stitches.config';

import { Bonus, BONUS_STATUS } from '~api/bonus/types';
import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { useBonusHistoryItem } from './useBonusHistoryItem';

const CustomSeparator = ({ css }: { css?: CSS }) => (
  <Separator
    shrinkOut={3}
    verticalSpace={1}
    css={{ backgroundColor: '$grayDarker', ...css }}
  />
);

export const BonusHistoryItemMobile = ({ bonus }: { bonus: Bonus }) => {
  const {
    expiresDate,
    givenDate,
    maxWinName,
    remainingAmount,
    status,
    statusName,
    title,
    totalAmount,
    won,
    currency,
    localized,
  } = useBonusHistoryItem({ bonus });
  const expiresDateText = localized(
    status === BONUS_STATUS.ACTIVE ? 'bonuses.expiresIn' : 'bonuses.expired',
    {
      postfix: '',
    },
  );

  return (
    <Box
      flexCol
      css={{
        borderRadius: '$8',
        backgroundColor: '$blueDark',
        p: '$2 $3 5px',
      }}
    >
      <Box flexRow justifyContentBetween>
        <Text level="13-20" fontWeight="bold" textTransform="uppercase">
          {title}
        </Text>
        <Text
          level="12-20"
          fontWeight="medium"
          color={status === BONUS_STATUS.ACTIVE ? 'green' : 'white'}
        >
          {statusName}
        </Text>
      </Box>
      <CustomSeparator css={{ mt: '7px' }} />
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {localized('bonuses.given', {
            postfix: '',
          })}
        </Text>
        <Text level="12-20">{givenDate}</Text>
      </Box>
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {expiresDateText}
        </Text>
        <Text level="12-20">{expiresDate}</Text>
      </Box>
      <CustomSeparator />
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {localized('bonuses.history.tableHeaders.section')}
        </Text>
        <Text level="12-20" textTransform="capitalize">
          {localized('bonuses.sport')}
        </Text>
      </Box>
      <CustomSeparator />
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium" textTransform="capitalize">
          {localized('bonuses.history.tableHeaders.usedAmount')}
        </Text>
        <Box flexRow>
          <Text level="12-20" fontWeight="medium" css={{ pr: '$1' }}>
            {commaSeparatedNumber(totalAmount - remainingAmount)}
          </Text>
          <Text level="12-20">{`/ ${commaSeparatedNumber(totalAmount)} ${
            CURRENCY_SYMBOLS[currency]
          }`}</Text>
        </Box>
      </Box>
      <CustomSeparator />
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium" textTransform="capitalize">
          {localized('bonuses.history.tableHeaders.wonMaxWin')}
        </Text>
        <Box flexRow>
          <Text level="12-20" fontWeight="medium" css={{ pr: '$1' }}>
            {won}
          </Text>
          <Text level="12-20">{maxWinName}</Text>
        </Box>
      </Box>
    </Box>
  );
};
