import { BetslipHistoryDetails } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Text } from '~components/atoms/Typography';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { QUERY_PARAMS } from '~constants/common';
import { CountryName } from '~constants/flag';
import { SPORTS } from '~constants/sport';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';
import { buildSearchQuery } from '~utils/url';

import { StatusCell } from '../BetslipHistoryTable/components/StatusCell';

interface BetslipHistoryMobileItemDetailsProps {
  details: BetslipHistoryDetails;
  index: number;
}

export const BetslipHistoryMobileItemDetails = ({
  details,
  index,
}: BetslipHistoryMobileItemDetailsProps) => {
  const dispatch = useAppDispatch();
  const {
    eventId,
    country,
    event,
    league,
    market,
    odd,
    selection,
    startDate,
    sport,
    status,
  } = details;
  const { getFormattedOdd, odds } = useWebsiteSettings();

  const startDateObj = new Date(startDate);
  const now = new Date();
  const isLive = startDateObj < now;

  const [homeTeam, awayTeam] = event.split(' vs. ');

  const onEventNameClick = () => {
    dispatch(setMobileActiveTab(isLive ? MOBILE_MENU.LIVE : MOBILE_MENU.SPORT));
    dispatch(closeDialog());
  };

  return (
    <Box
      key={index}
      flexCol
      css={{
        backgroundColor: '$betslipHistoryMobileItemDetailsBgColor',
        p: '$2 $3',
        borderRadius: '$6',
      }}
    >
      <Box flexRow alignCenter gap={2}>
        <Box css={{ transform: 'translateY(3px)' }}>
          {getSportIcon(sport as SPORTS, {
            width: 16,
            height: 16,
          })}
        </Box>
        <Flag countryName={country as CountryName} size="sm" />
        <Text level="12-20" color="grayMedium">
          {league}
        </Text>
      </Box>
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
        </Text>
        <StatusCell status={status} isInnerTable isRegularText />
      </Box>
      <NavigationLink
        key={eventId}
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$1',
        }}
        onClick={onEventNameClick}
        to={{
          search: buildSearchQuery({
            [QUERY_PARAMS.EVENT_ID]: eventId,
            [QUERY_PARAMS.MENU]: isLive
              ? SPORT_MENUS.LIVE
              : SPORT_MENUS.PREMATCH,
          }),
        }}
        end
      >
        <Text level="12-20" css={{ textWrap: 'balance' }}>
          {homeTeam} - {awayTeam}
        </Text>
      </NavigationLink>
      <Text level="12-20">{market} -</Text>
      <Box flexRow justifyContentBetween>
        <Text level="12-20">{selection}</Text>
        <Text color="yellow" level="12-20" fontWeight="medium">
          {getFormattedOdd(odd, odds)}
        </Text>
      </Box>
    </Box>
  );
};
