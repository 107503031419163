import React, { memo } from 'react';

import { LiveGroup } from '~api/category/types';
import { Box } from '~components/atoms/Box';
import { ToggleFavoriteEventSportMarket } from '~components/atoms/ToggleFavoriteEventSportMarket';
import { SportIconKey } from '~constants/sport';
import { useMedia } from '~hooks/useMedia';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionTrigger,
} from '../../MultiAccordion';

import { LiveFavoriteEventListMenu } from './LiveFavoriteEventListMenu';

export interface LiveFavoriteEventGroupProps {
  sportGroupsMemo: LiveGroup[];
}

export const LiveFavoriteEventGroup = memo(
  ({ sportGroupsMemo }: LiveFavoriteEventGroupProps) => {
    const { isMobileOrTablet } = useMedia();

    return sportGroupsMemo?.map(({ id, name, favoriteEvents }) => {
      return (
        !!favoriteEvents?.length && (
          <MultiAccordionItem
            value={id.toString()}
            key={id}
            role={MULTI_ACCORDION_ROLES.CHILD}
          >
            <MultiAccordionTrigger
              isCountTransparent
              title={name}
              icon={name as SportIconKey}
              count={favoriteEvents.length}
              role={MULTI_ACCORDION_ROLES.CHILD}
              isPrefixWithPadding={isMobileOrTablet}
            />
            <MultiAccordionContent
              role={MULTI_ACCORDION_ROLES.CHILD}
              css={{ p: isMobileOrTablet ? '$2' : 'default' }}
            >
              <Box flexCol gap={1}>
                <ToggleFavoriteEventSportMarket sportId={id} />
                <LiveFavoriteEventListMenu events={favoriteEvents} />
              </Box>
            </MultiAccordionContent>
          </MultiAccordionItem>
        )
      );
    });
  },
);
