import { useMemo } from 'react';

import { MainMarket } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { SkeletonFull } from '~components/atoms/Skeletons';
import { ToggleSportMarket } from '~components/atoms/ToggleSportMarket';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { Text } from '~components/atoms/Typography';
import { SPORT_GROUP_COMPONENTS } from '~components/molecules/PrematchMenu/constants';
import { SportSliderItem } from '~components/molecules/PrematchMenu/SportSliderItem';
import { useTranslation } from '~hooks/useTranslation';
import { ChevronDownIcon, FavoriteIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import { setIsFavoriteSelected } from '~store/slices/mobileSlice';
import { SVGElement } from '~types/general';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import { useMainPageMobile } from './hooks/useMainPageMobile';
import { EventsList } from './EventsList';
import { MAIN_PAGE_TABS } from './helpers';
import { MatchHighlightsMarketsHeaders } from './MatchHighlightsMarketsHeaders';

const FAVORITE_ID = 0;

export const MainPageMobileContent = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const {
    activeSportId,
    activeTab,
    activeEventGroups,
    mainSportMarket,
    sports,
    isLoading,
    isAllLoaded,
    handleActiveSportIdChange,
    loadMore,
  } = useMainPageMobile();

  const { groups } = useAppSelector((state) => state.sportGroupsState);

  const sportsItems = useMemo(() => {
    const _firstFavGroup = groups.filter(
      ({ name }) =>
        name === SPORT_GROUP_COMPONENTS.FAVORITE_EVENT ||
        name === SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT,
    )[0];

    const _sports = _firstFavGroup
      ? [
          {
            name: localized('prematch.favorite'),
            icon: FavoriteIcon as SVGElement,
            id: FAVORITE_ID,
            countries: [],
          },
          ...sports,
        ]
      : sports;

    return _sports.map(({ id, name, icon }) => {
      const _id = id.toString();

      return (
        <SportSliderItem
          key={id}
          sport={name}
          icon={icon as SVGElement}
          className={_id === activeSportId ? 'active' : ''}
          onClick={() => {
            if (id === FAVORITE_ID) {
              dispatch(setBreadcrumbsLabel(_firstFavGroup?.name || ''));
              dispatch(setIsFavoriteSelected(true));
            } else {
              handleActiveSportIdChange(_id);
            }
          }}
        />
      );
    });
  }, [sports, activeSportId, groups, handleActiveSportIdChange]);

  const activeEventGroupItems = useMemo(() => {
    if (!activeEventGroups) return null;

    const _isLiveHighlights = activeTab === MAIN_PAGE_TABS.LIVE_HIGHLIGHTS;

    return activeEventGroups.map(({ date, matches }) => {
      const _time = formatDateTime(date, TIME_FORMATS.DATE);

      return (
        <Box
          fullWidth
          flexCol
          key={date}
          css={{
            '& > div:last-child': {
              pt: !mainSportMarket ? '$3' : 0,
              borderRadius: '0 0 $8 $8',
            },
          }}
        >
          <MatchHighlightsMarketsHeaders
            label={_isLiveHighlights ? localized('homePage.today') : _time}
            mainMarkets={mainSportMarket && [mainSportMarket as MainMarket]}
          />
          {matches && !!matches.length && (
            <EventsList
              isLive={_isLiveHighlights}
              activeEvents={matches}
              mainMarkets={[mainSportMarket as MainMarket]}
            />
          )}
        </Box>
      );
    });
  }, [activeEventGroups, activeTab, localized, mainSportMarket]);

  return (
    <Box>
      {!sports.length ? (
        <SkeletonFull />
      ) : (
        <Box
          flexCol
          css={{
            gap: '1px',
          }}
        >
          <TouchSlider>{sportsItems}</TouchSlider>
          {isLoading || !activeEventGroups?.length ? (
            <SkeletonFull />
          ) : (
            <Box flexCol css={{ p: '$1', backgroundColor: '$black' }} gap={1}>
              <ToggleSportMarket sportId={parseInt(activeSportId!)} noMargin />
              <Box
                flexCol
                fullWidth
                gap={1}
                css={{
                  background: '$black',
                  p: '$1',
                  borderRadius: '0 0 $8 $8',
                }}
              >
                {activeEventGroupItems}
              </Box>
              {!isAllLoaded && (
                <Box
                  flexRow
                  justifyCenter
                  alignCenter
                  gap={2}
                  css={{
                    p: '$2',
                    color: '$white',
                    '& > svg': {
                      width: '1rem',
                      height: '1rem',
                    },
                    '&:active': {
                      opacity: 0.7,
                    },
                  }}
                  onClick={loadMore}
                >
                  <Text level="14-20" color="white">
                    {localized('buttons.loadMore')}
                  </Text>
                  <ChevronDownIcon />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
