import { createSelector } from '@reduxjs/toolkit';

import { CURRENCY_CODES } from '~constants/common';
import { RootState } from '~store';

/*  Getters */
export const getCurrency = (state: RootState) => state.settings.currency;

/* Selectors */
export const selectIsUganda = createSelector(
  [getCurrency],
  (currency) => currency === CURRENCY_CODES.UGX,
);
