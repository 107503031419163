import React, { memo, Suspense } from 'react';
import { Routes } from 'react-router-dom';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Fallback, renderRoutes } from '~routes';

const DesktopLayoutContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'inherit',
  minHeight: '100vh',
  maxHeight: '100vh',
  backgroundColor: '$gray',
});

export const DesktopLayout = memo(() => {
  return (
    <DesktopLayoutContainer>
      <Suspense fallback={<Fallback />}>
        <Routes>{renderRoutes(false)}</Routes>
      </Suspense>
    </DesktopLayoutContainer>
  );
});
