import { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Message } from '~components/atoms/Message';
import {
  StyledUserTabContentSeparator,
  StyledUserTabContentTitle,
  StyledUserTabContentWrapper,
} from '~components/atoms/TabContent';
import { DeactivateAccount } from '~components/molecules/UserProfile/components/DeactivateAccount/DeactivateAccount';
import { MESSAGE_TYPES } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';

import { ChangePasswordForm } from '../ChangePasswordForm';

export const AccountSettingsContent = () => {
  const { localized } = useTranslation();
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);

  const onTogglePasswordChanged = (val: boolean) => {
    setIsPasswordChanged(val);
  };

  return (
    <Box flexCol gap={3}>
      <StyledUserTabContentWrapper>
        <StyledUserTabContentTitle>
          {isPasswordChanged ? (
            <Message
              type={MESSAGE_TYPES.SUCCESS}
              text={`${localized(
                'userProfile.personalProfileInfo.accountSettings.changePassword.passwordChangedTitle',
              )} ${localized(
                'userProfile.personalProfileInfo.accountSettings.changePassword.passwordChangedDescription',
              )}`}
            />
          ) : (
            localized(
              'userProfile.personalProfileInfo.accountSettings.changePassword.title',
            )
          )}
        </StyledUserTabContentTitle>
        <StyledUserTabContentSeparator />
        <ChangePasswordForm onTogglePasswordChanged={onTogglePasswordChanged} />
      </StyledUserTabContentWrapper>
      <StyledUserTabContentWrapper
        css={{
          borderRadius: '$8',
          border: '1px solid $gray',
          p: '$2 $3',
        }}
      >
        <StyledUserTabContentTitle>
          {localized(
            'userProfile.personalProfileInfo.accountSettings.deactivateAccount.title',
          )}
        </StyledUserTabContentTitle>
        <StyledUserTabContentSeparator />
        <Box fullWidth>
          <DeactivateAccount />
        </Box>
      </StyledUserTabContentWrapper>
    </Box>
  );
};
