import * as TabsUI from '@radix-ui/react-tabs';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledTabsRoot = styled(TabsUI.Root, {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    fullHeight: {
      true: {
        height: '100%',
      },
    },
  },
});

export const StyledTabsList = styled(TabsUI.List, {
  display: 'flex',
  gap: '$6',
});

export const StyledTabsTrigger = styled(TabsUI.Trigger, {
  all: 'unset',
  display: 'flex',
  color: '$white',
  '&:hover': { color: '$yellow', cursor: 'pointer' },
  '&[data-state="active"]': {
    color: '$yellow',
    fontWeight: '$medium',
    '&:hover': { cursor: 'default' },
  },
  variants: {
    capitalize: {
      true: {
        textTransform: 'capitalize',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
  },
});

export const StyledTabsContent = styled(TabsUI.Content, {
  flexGrow: 1,
  outline: 'none',
  height: '100%',
});

export const ScrollableBox = styled(Box, {
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-webkit-overflow-scrolling': 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
