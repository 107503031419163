import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconButton } from '~components/atoms/Modal';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { CloseIcon } from '~icons';

export const HeaderCloseButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBackOrHome = () => {
    if (location.state?.hasHistory) navigate(-1);
    else navigate(ROUTE_TAB_NAMES.BASE);
  };

  return (
    <IconButton
      relative
      aria-label="Close"
      onClick={goBackOrHome}
      style={{
        padding: '.25rem',
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};
