import React from 'react';

import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { SPORTS } from '~constants/sport';
import { getSportIcon } from '~utils/getSportIcon';

interface TitleProps {
  sport: string | null | undefined;
  country: string | null | undefined;
  league: string | null | undefined;
}

export const Title = ({ sport, league, country }: TitleProps) => {
  return (
    <Box
      flexRow
      alignCenter
      gap={2}
      css={{
        p: '18px',
        color: '$grayMedium',
        '@xs_sm': {
          p: '$2',
        },
      }}
    >
      {getSportIcon(sport as SPORTS, {
        width: 20,
        height: 20,
      })}
      <Flag countryName={country as CountryName} size="sm" />
      <Text level="sm-3" css={{ textShadow: '$generalTextShadow' }}>
        {league}
      </Text>
    </Box>
  );
};
