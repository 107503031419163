import { HubConnection } from '@microsoft/signalr';

type EventCallback = (data: any) => void;
type EventCallbacks = Record<string, EventCallback>;
type ListenerMap = Record<string, EventCallback[]>;
type EventCounterMap = Record<string, number>;

class SocketService {
  private socket: HubConnection | null;
  private listeners: ListenerMap;
  private eventCounters: EventCounterMap;

  constructor(socket: HubConnection | null) {
    this.socket = socket;
    this.listeners = {};
    this.eventCounters = {};
  }

  addListener(eventCallbacks: EventCallbacks): void {
    Object.keys(eventCallbacks).forEach((event) => {
      const callback = eventCallbacks[event];

      if (!callback) return;

      if (!this.listeners[event]) {
        this.listeners[event] = [];
        this.eventCounters[event] = 0;

        if (this.socket) {
          this.socket.on(event, (data: any) => {
            const eventListeners = this.listeners[event];

            if (eventListeners) {
              eventListeners.forEach((cb) => cb(data));
            }
          });
        }
      }

      this.listeners[event]!.push(callback);
      this.eventCounters[event] += 1;
    });
  }

  removeListener(eventCallbacks: EventCallbacks): void {
    Object.keys(eventCallbacks).forEach((event) => {
      const callback = eventCallbacks[event];

      if (!this.listeners[event]) return;

      this.listeners[event] =
        this.listeners[event]?.filter((cb) => cb !== callback) || [];
      this.eventCounters[event] -= 1;

      if (this.eventCounters[event] === 0) {
        if (this.socket) {
          this.socket.off(event);
        }

        delete this.listeners[event];
        delete this.eventCounters[event];
      }
    });
  }

  invoke(event: string, ...args: any[]): any {
    if (this.socket) {
      return this.socket.invoke(event, ...args);
    }
  }
}

export default SocketService;
