import { Bet, BetslipItems } from '~api/betslip/types';
import {
  Selection,
  SportEventItem,
  SportEventItems,
} from '~api/sportEvent/types';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { BetslipPayload } from '~components/molecules/Betslip/hooks/types';
import { MARKET_STATUS } from '~constants/common';
import { useAppDispatch, useAppSelector } from '~store';
import { setOddsCount, setTotalOdd } from '~store/slices/betslipSlice';
import { Market } from '~types/events';
import { getBetslipStake } from '~utils/betslip';
import { isEmptyObject } from '~utils/objectHelpers';

export const useFormatBetslipPayload = () => {
  const dispatch = useAppDispatch();
  const { separateBonusBalance } = useAppSelector((state) => state.settings);
  const { aggregatedBetAmount, singleBetsAmountMap, stakeType } =
    useAppSelector((state) => state.betslip);

  const formatMultipleBetPayload = (betslips: BetslipPayload[] = []): Bet[] => {
    const oddsArray: number[] = [];

    const sportsId: number[] = [];
    const countriesId: number[] = [];
    const leaguesId: number[] = [];
    const marketsId: string[] = [];
    const selectionIds: string[] = [];

    betslips.forEach(({ selectionId, market, ...restEvent }) => {
      const { sportId = 0, countryId = 0, leagueId = 0 } = restEvent;
      const { selection = [], marketId = null } = market;
      const { odd } = (selection || { odd: 0 }) as Selection;

      if (market.status !== MARKET_STATUS.ACTIVE) return;

      if (odd) {
        oddsArray.push(odd);
      }

      if (!!marketId && !marketsId.includes(marketId)) {
        marketsId.push(marketId);
      }

      if (sportId && !sportsId.includes(sportId)) {
        sportsId.push(sportId);
      }

      if (countryId && !countriesId.includes(countryId)) {
        countriesId.push(countryId);
      }

      if (leagueId && !leaguesId.includes(leagueId)) {
        leaguesId.push(leagueId);
      }

      if (selectionId) {
        selectionIds.push(selectionId);
      }
    });

    dispatch(setOddsCount(oddsArray.length));
    dispatch(setTotalOdd(oddsArray.reduce((acc, odd) => acc * odd, 1)));

    return [
      {
        sportsId,
        marketsId: marketsId.map((item) => +item),
        countriesId,
        leaguesId: leaguesId.map((item) => +item),
        selectionIds,
        odds: oddsArray,
        ...getBetslipStake({
          amount: aggregatedBetAmount,
          separateBonusBalance,
        }),
      },
    ];
  };

  const formatSingleBetPayload = (betslips: BetslipPayload[] = []): Bet[] => {
    const payload: Bet[] = [];

    betslips.forEach(({ selectionId, market, ...restEvent }) => {
      const amount = singleBetsAmountMap[selectionId] || {};

      if (isEmptyObject(amount)) return;

      const { sportId = 0, countryId = 0, leagueId = 0 } = restEvent;
      const { selections = [], marketId = '' } = market;
      const { odd } = selections.find(
        (selection: Selection) => selection.id === selectionId,
      ) || { odd: 0 };

      if (market.status !== MARKET_STATUS.ACTIVE) return;

      const betItem: Bet = {
        selectionIds: [selectionId],
        odds: [odd],
        marketsId: [+marketId],
        sportsId: [sportId],
        countriesId: [countryId],
        leaguesId: [+leagueId],
        ...getBetslipStake({ amount, separateBonusBalance }),
      };

      payload.push(betItem);
    });

    return payload;
  };

  const formatSystemBetPayload = (betslips: BetslipPayload[] = []): Bet[] => {
    const payload: Bet[] = [];

    betslips.forEach(({ selectionId, market, ...restEvent }) => {
      const { sportId = 0, countryId = 0, leagueId = 0 } = restEvent;
      const { selections = [], marketId = '' } = market;
      const { odd } = selections.find(
        (selection: Selection) => selection.id === selectionId,
      ) || { odd: 0 };

      if (market.status !== MARKET_STATUS.ACTIVE) return;

      const betItem: Bet = {
        selectionIds: [selectionId],
        odds: [odd],
        marketsId: [+marketId],
        sportsId: [sportId],
        countriesId: [countryId],
        leaguesId: [+leagueId],
        ...getBetslipStake({
          amount: aggregatedBetAmount,
          separateBonusBalance,
        }),
      };

      payload.push(betItem);
    });

    return payload;
  };

  const generateBetslipsData = (
    events: BetslipItems,
    eventsData: SportEventItems,
  ): BetslipPayload[] =>
    events.map(({ eventId, marketId, selectionId }) => {
      const event = eventsData.find(
        (eventData) => eventData.id === eventId,
      ) as SportEventItem;
      const market = event.markets.find(
        (marketData) => marketData.id === marketId,
      ) as Market;
      const selection = market?.selections?.find(
        (selectionData) => selectionData?.id === selectionId,
      );

      return {
        eventId,
        selectionId,
        marketId,
        ...event,
        market: {
          ...market,
          selection,
        },
      };
    });

  const formatBetslipPayload = (
    events: BetslipItems,
    eventsData: SportEventItems,
  ): Bet[] => {
    const betslips = generateBetslipsData(events, eventsData);

    if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE) {
      return formatMultipleBetPayload(betslips);
    }

    if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM) {
      return formatSystemBetPayload(betslips);
    }

    return formatSingleBetPayload(betslips);
  };

  return {
    formatBetslipPayload,
  };
};
