import { ConfigProvider, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { styled } from 'stitches.config';

import { LEGAL_AGE } from '~constants/common';
import { THEMES } from '~constants/ui';
import { useMedia } from '~hooks/useMedia';
import { DATE_FORMATS } from '~utils/formatDateTime';
import { getTheme } from '~utils/getTheme';

const { RangePicker } = DatePicker;

export const DATE_PLACEHOLDERS_MAP = {
  [DATE_FORMATS.DATE]: '__/__/__',
  [DATE_FORMATS.DATE_WITH_DOT]: '__.__.__',
  [DATE_FORMATS.DATE_YEAR_FIRST]: '____.__',
  [DATE_FORMATS.MONTH]: '__.____',
};

const disablePastMonths = (currentDate: dayjs.Dayjs) => {
  const today = dayjs().startOf('day');

  return currentDate ? currentDate.isBefore(today, 'month') : false;
};

export const StyledRangePicker = styled(RangePicker, {
  backgroundColor: '$blueDark',
  border: 'none',
  width: '256px',
  variants: {
    isFullWidth: {
      true: {
        width: '100%',
        height: '$7',
        '& > .ant-picker-input:first-child': {
          flexGrow: 0,
          flexBasis: '140px',
        },
      },
    },
  },
});

export const StyledDatePicker = styled(DatePicker, {
  backgroundColor: '$gray',
  border: 'none',
  width: '176px',
  variants: {
    isFullWidth: {
      true: {
        width: '100%',
      },
    },
  },
});

const defaultThemeConfig = {
  token: {
    fontFamily: 'Poppins',
    colorTextBase: '#E5E5E5',
    colorPrimary: '#048531',
    colorBgBase: '#202730',
    colorPrimaryBg: '#2C3540',
    colorBorder: '#2C3540',
    colorBorderSecondary: '#2C3540',
    fontWeightStrong: 500,
    colorSplit: '#2C3540',
    colorTextHeading: '#E5E5E5',
    colorTextDisabled: '#454B52',
    colorIcon: '#7A7A7A',
  },
  components: {
    DatePicker: {
      cellHoverBg: '#2C3540',
      cellActiveWithRangeBg: '#11432A',
      cellHoverWithRangeBg: '#11432A',
      cellRangeBorderColor: '#048531',
      colorTextLightSolid: '#E5E5E5',
      colorTextQuaternary: '#E5E5E5',
    },
  },
};

const semaBetThemeConfig = {
  token: {
    fontFamily: 'Poppins',
    colorTextBase: '#F3F3F3',
    colorPrimary: '#F9F503',
    colorBgBase: '#093054',
    colorPrimaryBg: '#2C3540',
    colorBorder: '#003C75',
    colorBorderSecondary: '#003C75',
    fontWeightStrong: 500,
    colorSplit: '#003C75',
    colorTextHeading: '#F3F3F3',
    colorTextDisabled: '#295987',
    colorIcon: '#879BAD',
  },
  components: {
    DatePicker: {
      cellHoverBg: '#003C75',
      cellActiveWithRangeBg: '#003C75',
      cellHoverWithRangeBg: '#003C75',
      cellRangeBorderColor: '#F9F503',
      colorTextLightSolid: '#09243D',
      colorTextQuaternary: '#09243D',
    },
  },
};

export type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>
>[0];

interface DateRangePickerProps {
  theme?: typeof defaultThemeConfig;
  isSinglePicker?: boolean;
  placeholder?: string;
  initialValue?: dayjs.Dayjs;
  checkAge?: boolean;
  picker?: string;
  format?: DATE_FORMATS;
  isFutureDatesDisabled?: boolean;
  value?: RangeValue;
  isFullWidth?: boolean;
  isCreditCardDate?: boolean;
  legalAge?: number;
  onOkSingle?: (date: dayjs.Dayjs) => void;
  onOkRange?: (dates: RangeValue) => void;
  onSingleChange?: (value: dayjs.Dayjs) => void;
  onRangeChange?: (values: RangeValue, formatString: [string, string]) => void;
  onClose?: () => void;
  onHandleOpenChange?: (open: boolean) => void;
}

export const DateRangePicker = ({
  theme,
  isSinglePicker = false,
  placeholder,
  initialValue,
  checkAge,
  picker,
  format = DATE_FORMATS.DATE,
  isFutureDatesDisabled = false,
  value = null,
  isFullWidth,
  isCreditCardDate,
  legalAge,
  onSingleChange,
  onRangeChange,
  onOkRange,
  onOkSingle,
  onClose,
  onHandleOpenChange,
}: DateRangePickerProps) => {
  const { isMobileOrTablet } = useMedia();

  const today = dayjs().startOf('day');
  const legalAgeDate = dayjs().subtract(legalAge || LEGAL_AGE, 'years');

  let defaultValue = initialValue;

  if (isCreditCardDate) {
    defaultValue = dayjs(
      initialValue ? initialValue : today,
      DATE_FORMATS.MONTH,
    );
  }

  const currentTheme =
    getTheme() === THEMES.SEMA_BET ? semaBetThemeConfig : defaultThemeConfig;

  const getDisabledDate = () => {
    if (isCreditCardDate) {
      return disablePastMonths;
    }

    if (checkAge && isSinglePicker) {
      return (currentDate: dayjs.Dayjs) => {
        return currentDate > legalAgeDate;
      };
    }

    if (isFutureDatesDisabled && !isSinglePicker) {
      return (currentDate: dayjs.Dayjs) => {
        return currentDate < dayjs().endOf('day');
      };
    }

    return undefined;
  };

  const handleOnOpenChange = (open: boolean) => {
    onHandleOpenChange && onHandleOpenChange(open);

    setTimeout(() => {
      if (!open) {
        onClose && onClose();
      }
    }, 500);
  };

  const handleRangeChange = (dates: RangeValue) => {
    const [from, to] = dates || [null, null];
    const range = from && to && from <= to ? dates : [null, null];

    onOkRange && onOkRange(range as RangeValue);
  };

  return (
    <ConfigProvider theme={currentTheme || theme}>
      {isSinglePicker ? (
        <StyledDatePicker
          showTime={true}
          inputReadOnly={isMobileOrTablet}
          defaultPickerValue={
            legalAge && !initialValue
              ? legalAgeDate
              : legalAge
                ? initialValue
                : today
          }
          picker={picker as any}
          format={format}
          needConfirm={false}
          placeholder={placeholder || DATE_PLACEHOLDERS_MAP[format]}
          onChange={(date: unknown) => {
            const dayjsDate = date as dayjs.Dayjs;

            onSingleChange && onSingleChange(dayjsDate);
            onOkSingle && onOkSingle(dayjsDate);
          }}
          value={defaultValue}
          disabledDate={getDisabledDate()}
          isFullWidth={isFullWidth}
          onOpenChange={handleOnOpenChange}
        />
      ) : (
        <StyledRangePicker
          showTime={true}
          inputReadOnly={isMobileOrTablet}
          picker={picker as any}
          value={value}
          format={DATE_FORMATS.DATE}
          placeholder={[
            DATE_PLACEHOLDERS_MAP[format],
            DATE_PLACEHOLDERS_MAP[format],
          ]}
          disabledDate={getDisabledDate()}
          isFullWidth={isFullWidth}
          onChange={onRangeChange as any}
          onOk={handleRangeChange as any}
        />
      )}
    </ConfigProvider>
  );
};
