import { QueryParameters } from '~constants/common';
import { SPORT_MENUS } from '~types/sportMenus';

export const getPreparedParamsString = (search: QueryParameters) => {
  const {
    sportId = '',
    countryId = '',
    leagueId = '',
    eventId = '',
    menu = '',
  } = search;

  let resQueryString = '';

  if (menu === SPORT_MENUS.PREMATCH) {
    if (eventId) {
      resQueryString = `${sportId}-${countryId}-${leagueId}-${eventId}-${menu}`;
    } else if (menu === SPORT_MENUS.PREMATCH && leagueId && !eventId) {
      resQueryString = `${sportId}-${countryId}-${leagueId}-${menu}`;
    } else {
      resQueryString = `${sportId}-${menu}`;
    }
  } else if (menu === SPORT_MENUS.LIVE) {
    if (eventId) {
      resQueryString = `${sportId}-${countryId}-${leagueId}-${eventId}-${menu}`;
    } else {
      resQueryString = `${sportId}-${menu}`;
    }
  }

  return resQueryString;
};
