import React from 'react';

import { Box } from '~components/atoms/Box';
import { JackpotBetEvent } from '~types/jackpot';

import { JackpotBetEventListItem } from './JackpotBetEventListItem';

export const JackpotBetEventsList = ({
  events,
}: {
  events: JackpotBetEvent[];
}) => (
  <Box flexCol gap={1}>
    {events.map((event) => (
      <JackpotBetEventListItem key={event.id} event={event} />
    ))}
  </Box>
);
