import { useEffect, useState } from 'react';

import { BalanceChangeResponse, BetAmount } from '~api/betslip/types';
import {
  BETSLIP_VALUES_MAP_OPTIONS,
  DEFAULT_BALANCE_CHANGE,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '~components/molecules/Betslip/constants';
import { useStakePayout } from '~hooks/useStakePayout';
import { useAppSelector } from '~store';
import { selectAllMarkets } from '~store/slices/selectors/betslip';
import { getBetTotalAmount } from '~utils/betslip';

export const useBetslipAggregatedValues = (totalBetAmount: BetAmount) => {
  const {
    stakeType,
    events,
    balanceChangesOnBetData,
    balanceChangesMap,
    systemBetOption,
  } = useAppSelector((state) => state.betslip);
  const markets = useAppSelector(selectAllMarkets);

  const { maxWinAmount, siteConfiguration } = useAppSelector(
    (state) => state.settings,
  );

  const [totalOdd, setTotalOdd] = useState(1);

  useEffect(() => {
    const odd = events.reduce((acc, { marketId, selectionId }) => {
      const market = markets.find((market) => market.id === marketId);
      const selection = market?.selections.find(
        (selection) => selection.id === selectionId,
      );

      return selection?.odd ? acc * selection.odd : acc;
    }, 1);

    setTotalOdd(odd);
  }, [events, markets]);

  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const {
    systemBetOriginalWinAmount = 0,
    systemBetTotalPossibleWin = 0,
    systemBetWinBoostAmount = 0,
  } = balanceChangesOnBetData || ({} as BalanceChangeResponse);

  const {
    totalPossibleWin,
    winBoostAmount,
    winBoostMultiplier,
    winBoostDescriptions = [],
    originalWinAmount,
    stakeTax,
    possibleIncomeTax,
  } = balanceChangesMap[BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED] ||
  DEFAULT_BALANCE_CHANGE;

  const possibleWin =
    totalPossibleWin > maxWinAmount ? maxWinAmount : totalPossibleWin;

  const totalStake = getBetTotalAmount(totalBetAmount, systemBetOption);
  const { showPayoutInfo, payoutInfoItems } = useStakePayout({
    stake: totalStake,
    stakeAfterTax: totalStake - stakeTax,
    stakeTax,
    stakeTaxPercent: siteConfiguration?.stakeTaxPercent,
    stakeTaxPayer: siteConfiguration?.taxPayer,
    possibleWin,
    possibleIncomeTax,
    incomeTaxPercent: siteConfiguration?.incomeTaxPercent,
  });

  return {
    totalStake,
    totalOdd,
    winBoostMultiplier,
    winBoostDescriptions,
    showPayoutInfo,
    payoutInfoItems,
    possibleIncomeTax,
    possibleWin: isSystemBet ? systemBetTotalPossibleWin : possibleWin,
    winBoostAmount: isSystemBet ? systemBetWinBoostAmount : winBoostAmount,
    originalWinAmount: isSystemBet
      ? systemBetOriginalWinAmount
      : originalWinAmount,
  };
};
