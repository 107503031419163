import { useEffect } from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

export const EmptyMarkets = () => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();

  useEffect(() => {
    if (isMobileOrTablet) {
      dispatch(setMobileActiveTab(MOBILE_MENU.LIVE));
    }
  }, [isMobileOrTablet]);

  return (
    <Box
      flexCol
      gap={4}
      justifyCenter
      alignCenter
      css={{
        pt: isMobileOrTablet ? '$9' : `$11`,
        color: '$grayMedium',
        fontSize: '$20',
        lineHeight: '$32',
        px: '$4',
        '@xs_sm': {
          fontSize: '$12',
          lineHeight: '$20',
          pb: '96px',
        },
      }}
    >
      <Text level="14-20">{localized('inplayEvent.emptyMarkets')}</Text>
      {isMobileOrTablet && (
        <Button
          size="small"
          onClick={() => {
            dispatch(setMobileActiveTab(MOBILE_MENU.LIVE));
            updateQueryParams(
              {
                [QUERY_PARAMS.MENU]: SPORT_MENUS.LIVE,
              } as QueryParams<typeof QUERY_PARAMS>,
              true,
            );
          }}
        >
          {localized('inplayEvent.backToLive')}
        </Button>
      )}
    </Box>
  );
};
