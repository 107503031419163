import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  MobileSectionContainer,
  MobileSectionTitleContainer,
} from '~components/molecules/UserProfile/components/Payments/components/PaymentAccounts/styled.components';
import { useTranslation } from '~hooks/useTranslation';

import {
  PAYMENT_ACCOUNTS_TYPES,
  useMobilePaymentAccounts,
} from '../../hooks/useMobilePaymentAccounts';
import { usePaymentAccounts } from '../../hooks/usePaymentAccounts';

import { PaymentAccountsRadios } from './PaymentAccountsRadios';
import { PaymentAccountsToggleButtons } from './PaymentAccountsToggleButtons';

export const PaymentAccountsMobile = () => {
  const { localized } = useTranslation();
  const {
    PAYMENT_ACCOUNTS,
    paystackDepositCards,
    flutterwaveDepositCards,
    withdrawBanks,
    handleDefaultAccountChange,
    handleRemoveDefaultAccount,
  } = usePaymentAccounts();
  const { activePaymentsTab, onTabChange, tabs } = useMobilePaymentAccounts();

  return (
    <Box flexCol gap={3}>
      <PaymentAccountsToggleButtons
        tabs={tabs}
        value={activePaymentsTab.toString()}
        onChange={onTabChange}
      />
      {activePaymentsTab === PAYMENT_ACCOUNTS_TYPES.CARD ? (
        <>
          <MobileSectionContainer>
            <MobileSectionTitleContainer flexRow alignCenter>
              <Text
                level="12-20"
                color="grayMedium"
                fontWeight="medium"
                textTransform="uppercase"
              >
                {`${localized('paymentAccounts.paystack')} (${localized(
                  'paymentAccounts.deposit',
                )}): `}
              </Text>
              <Text level="12-20" color="grayMedium" textTransform="uppercase">
                {localized('paymentAccounts.creditCards')}
              </Text>
            </MobileSectionTitleContainer>
            <PaymentAccountsRadios
              items={paystackDepositCards}
              emptyTitle={'paymentAccounts.emptyCardAccounts.title'}
              emptyDescription={'paymentAccounts.emptyCardAccounts.title'}
              onChange={(value: string) =>
                handleDefaultAccountChange(
                  value,
                  PAYMENT_ACCOUNTS.PAYSTACK_DEPOSIT_CARDS,
                )
              }
              onRemove={(id: string) =>
                handleRemoveDefaultAccount(
                  id,
                  PAYMENT_ACCOUNTS.PAYSTACK_DEPOSIT_CARDS,
                )
              }
            />
          </MobileSectionContainer>
          <MobileSectionContainer>
            <MobileSectionTitleContainer flexRow alignCenter>
              <Text
                level="12-20"
                color="grayMedium"
                fontWeight="medium"
                textTransform="uppercase"
              >
                {`${localized('paymentAccounts.flutterwave')} (${localized(
                  'paymentAccounts.deposit',
                )}): `}
              </Text>
              <Text level="12-20" color="grayMedium" textTransform="uppercase">
                {localized('paymentAccounts.creditCards')}
              </Text>
            </MobileSectionTitleContainer>
            <PaymentAccountsRadios
              items={flutterwaveDepositCards}
              emptyTitle={'paymentAccounts.emptyCardAccounts.title'}
              emptyDescription={'paymentAccounts.emptyCardAccounts.title'}
              onChange={(value: string) =>
                handleDefaultAccountChange(
                  value,
                  PAYMENT_ACCOUNTS.FLUTTERWAVE_DEPOSIT_CARDS,
                )
              }
              onRemove={(id: string) =>
                handleRemoveDefaultAccount(
                  id,
                  PAYMENT_ACCOUNTS.FLUTTERWAVE_DEPOSIT_CARDS,
                )
              }
            />
          </MobileSectionContainer>
        </>
      ) : (
        <>
          <MobileSectionContainer>
            <MobileSectionTitleContainer flexRow alignCenter>
              <Text
                level="12-20"
                color="grayMedium"
                fontWeight="medium"
                textTransform="uppercase"
              >
                {localized('paymentAccounts.bankAccounts')}
              </Text>
            </MobileSectionTitleContainer>
            <PaymentAccountsRadios
              isBanks
              items={withdrawBanks}
              emptyTitle={'paymentAccounts.emptyBankAccounts.title'}
              emptyDescription={'paymentAccounts.emptyBankAccounts.title'}
              onChange={(value: string) =>
                handleDefaultAccountChange(
                  value,
                  PAYMENT_ACCOUNTS.WITHDRAW_BANKS,
                )
              }
              onRemove={(id: string) =>
                handleRemoveDefaultAccount(id, PAYMENT_ACCOUNTS.WITHDRAW_BANKS)
              }
            />
          </MobileSectionContainer>
        </>
      )}
    </Box>
  );
};
