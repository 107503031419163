import { TabsList } from '~components/atoms/Tabs';
import { useTranslation } from '~hooks/useTranslation';

import { USER_BONUSES_TABS } from '../UserProfileDialog';

export const userBonusesTabs = [
  USER_BONUSES_TABS.ACTIVE_BONUSES,
  USER_BONUSES_TABS.BONUS_HISTORY,
];

export const UserBonusesTabs = () => {
  const { localized } = useTranslation();

  return (
    <TabsList
      uppercase
      tabs={userBonusesTabs}
      ariaLabel={localized('userProfile.personalProfileInfo.ariaLabel')}
    />
  );
};
