import React from 'react';

import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { PaymentAccountButton } from '../../hooks/useMobilePaymentAccounts';

interface PaymentAccountsMobileProps {
  value: string;
  onChange: (value: string) => void;
  tabs: PaymentAccountButton[];
}

export const PaymentAccountsToggleButtons = ({
  value,
  onChange,
  tabs,
}: PaymentAccountsMobileProps) => {
  const { localized } = useTranslation();

  return (
    <ToggleGroup
      type="single"
      variant="userProfileMobileTab"
      onValueChange={onChange}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      value={value}
      rovingFocus={false}
      loop={false}
    >
      {tabs.map(({ value, label }) => (
        <ToggleGroupItem
          key={value}
          type="userProfileMobileTab"
          value={value.toString()}
        >
          <Text as="span">{localized(label)}</Text>
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
};
