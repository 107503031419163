import React, { memo } from 'react';

import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useAppDispatch, useAppSelector } from '~store';
import { setMarketsOfFavoriteEventsSelected } from '~store/slices/liveMenuSlice';

interface ToggleFavoriteEventSportMarketProps {
  sportId: number;
  noMargin?: boolean;
}

export const ToggleFavoriteEventSportMarket = memo(
  ({ sportId, noMargin }: ToggleFavoriteEventSportMarketProps) => {
    const dispatch = useAppDispatch();
    const { marketsOfFavoriteEventsSelected } = useAppSelector(
      (state) => state.liveMenu,
    );
    const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
    const { markets = [] } = mainMarkets.find(({ id }) => id === sportId) || {};

    const handleValueChange = (marketId: string) => {
      dispatch(
        setMarketsOfFavoriteEventsSelected({
          sportId,
          marketId: parseInt(marketId),
        }),
      );
    };

    const showMarkets = markets && markets?.length > 1;

    return (
      showMarkets && (
        <ToggleGroup
          type="single"
          variant="lightMarket"
          onValueChange={handleValueChange}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          value={marketsOfFavoriteEventsSelected[sportId] || markets[0].id}
          rovingFocus={false}
          loop={false}
          css={{
            margin: noMargin ? '0' : '$2 0',
            borderBottom: '1px solid $gray',
          }}
        >
          {markets.map(({ name, id }) => (
            <ToggleGroupItem
              key={name}
              type="lightMarket"
              value={id}
              disabled={id === marketsOfFavoriteEventsSelected[sportId]}
            >
              <Text as="span">{name}</Text>
            </ToggleGroupItem>
          ))}
        </ToggleGroup>
      )
    );
  },
);
