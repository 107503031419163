import React, { useState } from 'react';

import { useCallUserBack } from '~api/user/userMutations';
import { Box } from '~components/atoms/Box';
import { InputWithButton } from '~components/atoms/InputWithButton';
import { PhoneNumberInput } from '~components/atoms/PhoneNumberInput';
import { PHONE_FIELD_MASKS } from '~constants/auth';
import { usePhoneNumber, useTranslation } from '~hooks';
import {
  StyledSupportText,
  StyledTitleText,
  StyledTopSectionWrapper,
  StyledWorkingHoursText,
} from '~pages/Info/styled.components';
import { useAppSelector } from '~store';

export const CallBackSection = () => {
  const { localized } = useTranslation();
  const { formatPhoneNumberPayload } = usePhoneNumber();
  const { callUserBackMutation, callUserBackIsLoading } = useCallUserBack();

  const { currency } = useAppSelector((state) => state.settings);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const isDisabled =
    phoneNumber.length < PHONE_FIELD_MASKS[currency].length ||
    callUserBackIsLoading;

  const handleCallbackClick = async () => {
    try {
      const formattedPhoneNumber = formatPhoneNumberPayload(phoneNumber);

      await callUserBackMutation(formattedPhoneNumber).unwrap();
      setIsSuccess(true);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  const handleInputChange = (value: string) => {
    if (isError) {
      setIsError(false);
    }

    setPhoneNumber(value);
  };

  const renderInputWithButton = () => (
    <InputWithButton
      input={{
        customComponent: (
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            onChange={handleInputChange}
            isError={isError}
          />
        ),
        isError,
      }}
      button={{
        onClick: handleCallbackClick,
        text: localized('contactUsPage.callback'),
        isLoading: callUserBackIsLoading,
        isDisabled,
      }}
      isSuccess={isSuccess}
      successText={localized('contactUsPage.successText')}
    />
  );

  return (
    <StyledTopSectionWrapper>
      <Box flexCol alignCenter gap={2} css={{ maxWidth: '568px' }}>
        <StyledTitleText>{localized('contactUsPage.title')}</StyledTitleText>
        <StyledSupportText textTransform="uppercase">
          {localized('contactUsPage.support')}
        </StyledSupportText>
      </Box>
      <Box>{renderInputWithButton()}</Box>
      <StyledWorkingHoursText color="grayMedium">
        {localized('contactUsPage.workingHours')}
      </StyledWorkingHoursText>
    </StyledTopSectionWrapper>
  );
};
