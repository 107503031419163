import * as Accordion from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { slideDown, slideUp } from '~utils/keyframes';

export const AccordionRoot = Accordion.Root;

export const StyledAccordionItem = styled(Accordion.Item, {
  '&[data-state="closed"]': {
    height: '$5',
  },
});

export const StyledAccordionTrigger = styled(Accordion.Trigger, {
  width: '100%',
  m: 0,
  p: 0,
  backgroundColor: 'transparent',
  border: 'none',
  '&[data-state="closed"]': {
    color: '$grayMedium',
  },
  '&[data-state="open"]': {
    color: '$white',
  },
});

export const StyledCollapsibleContent = styled(Accordion.Content, {
  '&[data-state="open"]': {
    animation: `${slideDown} 200ms ease-in`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 10ms ease-in`,
  },
});
