import React, { useCallback, useEffect, useRef, useState } from 'react';

import { SportEventItems } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Select } from '~components/atoms/Select';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import { useSearch } from '~components/molecules/PrematchMenu/hooks/useSearch';
import { SportSliderItem } from '~components/molecules/PrematchMenu/SportSliderItem';
import { SEARCH_TERM_MIN_LENGTH } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import {
  AllSportIcon,
  FilterIcon,
  GameIcon,
  RedCrossIcon,
  SearchIcon,
  UpcomingIcon,
} from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import {
  setActiveSportTabGroup,
  setIsFavoriteSelected,
} from '~store/slices/mobileSlice';
import { setDateToFilter, setIsLoading } from '~store/slices/prematchMenuSlice';
import { SVGElement } from '~types/general';
import { TIME_RANGES } from '~types/timeRanges';
import { getLocalizedTimeRanges } from '~utils/getLocalizedTimeRanges';

interface SearchBarProps {
  onSearchedItemsChange: (items: SportEventItems) => void;
  onShowSearchResultsChange: (show: boolean) => void;
}

export const SearchBar = ({
  onSearchedItemsChange,
  onShowSearchResultsChange,
}: SearchBarProps) => {
  const dispatch = useAppDispatch();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {
    activeSportTabGroup: activeTabGroup = SPORT_TAB_GROUPS.ALL,
    isFavoriteSelected,
  } = useAppSelector((state) => state.mobileState);

  const { localized } = useTranslation();
  const [timeRange, setTimeRange] = useState(TIME_RANGES.ALL);

  const sportTabGroups = [
    {
      name: SPORT_TAB_GROUPS.TOP,
      icon: GameIcon,
      label: localized('sportTab.top'),
      id: 0,
    },
    {
      name: SPORT_TAB_GROUPS.UPCOMMING,
      icon: UpcomingIcon,
      label: localized('sportTab.upcoming'),
      id: 2,
    },
    {
      name: SPORT_TAB_GROUPS.ALL,
      icon: AllSportIcon,
      label: localized('sportTab.all'),
      id: 1,
    },
  ];

  const {
    searchRef,
    isSearchActive,
    searchValue,
    showSearchResults,
    searchedItems,
    onToggleSearch,
    handleSearchValueChange,
    handleClearSearch,
  } = useSearch();

  const handleTimeRangeChange = useCallback((value: TIME_RANGES) => {
    setTimeRange(value);
    dispatch(setDateToFilter(value === TIME_RANGES.ALL ? null : value));
  }, []);

  useEffect(() => {
    if (isSearchActive) {
      searchInputRef.current?.focus();
    }
  }, [searchInputRef, isSearchActive]);

  useEffect(() => {
    onSearchedItemsChange(searchedItems);
  }, [searchedItems]);

  useEffect(() => {
    onShowSearchResultsChange(showSearchResults);
  }, [showSearchResults]);

  const searchBarPaddings =
    isSearchActive || searchValue.length ? '0 $4' : '0 $4 0 0';

  return (
    <Box
      flexRow
      alignCenter
      fullWidth
      css={{
        backgroundColor: '$panelBgColor',
        height: '44px',
        p: searchBarPaddings,
        mb: searchValue.length >= SEARCH_TERM_MIN_LENGTH ? 0 : '$1',
      }}
    >
      {isSearchActive || searchValue.length ? (
        <Box
          ref={searchRef}
          fullWidth
          flexRow
          alignCenter
          css={{
            height: '32px',
            backgroundColor: '$popoverSecondaryBgColor',
            borderRadius: '$4',
            p: '0 $2',
            color: '$white',
            border: '1px solid $gray1',
            '& > input': {
              backgroundColor: 'transparent',
              border: 'none',
              fontSize: '$12',
              lineHeight: '$20',
              width: '100%',
              '&:focus': {
                outline: 'none',
              },
            },
          }}
        >
          <SearchIcon width={16} height={16} />
          <Input
            value={searchValue}
            ref={searchInputRef}
            onChange={handleSearchValueChange}
          />
          <RedCrossIcon onClick={handleClearSearch} />
        </Box>
      ) : (
        <Box flexRow fullWidth alignCenter gap={2} justifyContentBetween>
          <Box
            css={{
              width: `calc(100% - ${
                (activeTabGroup === SPORT_TAB_GROUPS.ALL ? 72 : 32) + 8
              }px)`,
            }}
          >
            <TouchSlider type="sportGroup">
              {sportTabGroups.map(({ id, name, label, icon }) => {
                const isActiveTab =
                  activeTabGroup === name && !isFavoriteSelected;

                return (
                  <SportSliderItem
                    key={id}
                    sport={label}
                    icon={icon as SVGElement}
                    className={isActiveTab ? 'active' : ''}
                    onClick={() => {
                      if (isActiveTab) return;

                      if (name === SPORT_TAB_GROUPS.ALL) {
                        dispatch(setBreadcrumbsLabel(null));
                        dispatch(setIsLoading(true));
                      }

                      dispatch(setIsFavoriteSelected(false));
                      dispatch(setActiveSportTabGroup(name));
                    }}
                  />
                );
              })}
            </TouchSlider>
          </Box>
          <Box flexRow alignCenter gap={2}>
            {activeTabGroup === SPORT_TAB_GROUPS.ALL && !isFavoriteSelected ? (
              <Select
                ariaLabel={localized('sidebar.timeRanges.ariaLabel')}
                hideLabel
                value={timeRange}
                onChange={(value) =>
                  handleTimeRangeChange(value as TIME_RANGES)
                }
                icon={<FilterIcon />}
                options={getLocalizedTimeRanges(localized)}
                isCustomContentWidth
                contentWidth={118}
                css={{
                  svg: {
                    color:
                      timeRange !== TIME_RANGES.ALL ? 'white' : '$grayMedium',
                  },
                }}
              />
            ) : null}
            <Button
              iconButton
              type={'button'}
              css={{
                color: '$grayMedium',
                background: '$grayDarkMain2',
                p: '$3 $2',
                '@xs_sm': {
                  height: '32px',
                  p: '$2 $2',
                  lineHeight: '$20',
                },
              }}
              onClick={onToggleSearch}
            >
              <SearchIcon width={16} height={16} />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
