import proto from '~/proto/messages_pb';
import { Sport } from '~api/category/types';
import { SportMainMarket } from '~api/market/types';
import {
  InPlayMenuSport,
  InPlayMenuSports,
  TopPrematchEvents,
  Tournament,
} from '~api/sportEvent/types';
import { prepareRespData } from '~components/molecules/LiveMenu/utils/eventsParsing';
import { SportsMenuCounts } from '~features/sportsMenu/sportsMenuSlice';
import {
  prepareEventData,
  prepareInPlayEventsByMarketData,
  prepareInPlaySportsData,
  prepareMainMarketsSportsData,
  preparePrematchEventsByLeagueData,
  preparePrematchSportsData,
  prepareTopTournamentsData,
  prepareUpcomingSportsOrTopGamesData,
} from '~proto/utils';
import { SportEvent } from '~types/events';
import { matrixToObject } from '~utils/arrayHelpers';

export const getProtoInPlayData = (binary: unknown): InPlayMenuSports => {
  const { eventsList }: ProtoInPlayMenuResponse =
    proto.InPlayMenuResponse.deserializeBinary(binary).toObject();

  const events = prepareInPlaySportsData(eventsList);

  return prepareRespData(events as InPlayMenuSports);
};

export const getProtoUpcomingData = (binary: unknown): TopPrematchEvents => {
  const { sportsList, categoriesMap }: ProtoUpcomingResponse =
    proto.UpcomingResponse.deserializeBinary(binary).toObject();

  const upcomingCategoriesMap = matrixToObject(categoriesMap);

  return prepareUpcomingSportsOrTopGamesData(sportsList, upcomingCategoriesMap);
};

export const getProtoMainMarketsData = (
  binary: unknown,
): {
  mainMarkets: SportMainMarket[];
  defaultSelectedMainMarkets: Record<number, number>;
} => {
  const { sportsList }: ProtoMainMarketsResponse =
    proto.MainMarketsResponse.deserializeBinary(binary).toObject();

  return prepareMainMarketsSportsData(sportsList);
};

export const getProtoPrematchData = (binary: unknown): Sport[] => {
  const { sportsList }: ProtoGetPrematchResponse =
    proto.GetPrematchResponse.deserializeBinary(binary).toObject();

  return preparePrematchSportsData(sportsList) as Sport[];
};

export const getProtoTopTournamentsData = (binary: unknown): Tournament[] => {
  const { tournamentsList }: ProtoGetTopTournamentsResponse =
    proto.GetTopTournamentsResponse.deserializeBinary(binary).toObject();

  return prepareTopTournamentsData(tournamentsList);
};

export const getProtoTopGamesData = (binary: unknown): TopPrematchEvents => {
  const { categoriesMap, sportsList }: ProtoGetTopGamesResponse =
    proto.GetTopGamesResponse.deserializeBinary(binary).toObject();

  const topGamesCategoriesMap = matrixToObject(categoriesMap);

  return prepareUpcomingSportsOrTopGamesData(sportsList, topGamesCategoriesMap);
};

export const getProtoPrematchEventData = (binary: unknown): SportEvent => {
  const event: ProtoEvent = proto.Event.deserializeBinary(binary).toObject();

  return prepareEventData(event);
};

export const getProtoInPlayEventData = (binary: unknown): SportEvent => {
  const event: ProtoEvent = proto.Event.deserializeBinary(binary).toObject();

  return prepareEventData(event);
};

export const getProtoCountsData = (binary: unknown): SportsMenuCounts => {
  return proto.GetCountsResponse.deserializeBinary(binary).toObject();
};

export const getProtoInPlayEventsByMarketData = (
  binary: unknown,
): SportEvent[] => {
  const { eventsList }: ProtoEventsWithMainMarketsResponse =
    proto.EventsWithMainMarketsResponse.deserializeBinary(binary).toObject();

  return prepareInPlayEventsByMarketData(eventsList) as SportEvent[];
};

export const getProtoPrematchEventsByLeagueData = (
  binary: unknown,
): {
  total: number;
  data: InPlayMenuSport[];
} => {
  const { total, dataList }: ProtoPrematchSportPaged =
    proto.PrematchSportPaged.deserializeBinary(binary).toObject();

  return {
    total,
    data: preparePrematchEventsByLeagueData(dataList) as InPlayMenuSport[],
  };
};

export const getProtoPrematchSubMenuData = (binary: unknown) => {
  const { categoriesList }: ProtoSubMenuCategoriesResponse =
    proto.SubMenuCategoriesResponseDto.deserializeBinary(binary).toObject();

  return categoriesList;
};
