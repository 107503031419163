import { useEffect, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

import { useBonuses } from '../../useBonuses';
import { BonusHistoryFiltersMobile } from '../BonusHistoryFilters';
import { BonusHistoryItemMobile } from '../BonusHistoryItem';

export const BonusHistoryMobile = () => {
  const { localized } = useTranslation();
  const { bonuses, isBonusesLoading } = useAppSelector(
    (state) => state.bonuses,
  );
  const [showFilters, setShowFilters] = useState(false);

  const { loadBonuses } = useBonuses();

  useEffect(() => {
    loadBonuses({});
  }, []);

  return (
    <Box flexCol gap={4}>
      <BonusHistoryFiltersMobile
        showFilters={showFilters}
        setShowFilters={setShowFilters}
      />
      {!showFilters &&
        (isBonusesLoading ? (
          <Box flexCol alignCenter gap={3} css={{ pt: '$4' }}>
            <Loader
              css={{ color: '$grayMedium', width: '23px', height: '23px' }}
            />
            <Text color="grayMedium" level="12-20">
              {localized('bonuses.loading')}
            </Text>
          </Box>
        ) : !bonuses.length ? (
          <Box flexRow alignCenter justifyCenter css={{ pt: '32px' }}>
            <Text color="grayMedium" level="14-20" fontWeight="medium">
              {localized('bonuses.noBonusToShow')}
            </Text>
          </Box>
        ) : (
          <Box fullHeight css={{ p: '$2 0 $1' }}>
            <ScrollArea>
              <Box flexCol gap={2}>
                {bonuses.map((bonus) => (
                  <BonusHistoryItemMobile key={bonus.id} bonus={bonus} />
                ))}
              </Box>
            </ScrollArea>
          </Box>
        ))}
    </Box>
  );
};
