import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionRoot,
  MultiAccordionTrigger,
} from '~components/molecules/MultiAccordion';
import { TopEvent } from '~components/molecules/PrematchMenu/SportMenu/TopEvent';

import { LIVE_GROUP_COMPONENTS } from '../constants';
import { useLiveMenuGroups } from '../hooks/useLiveMenuGroups';

import { LiveFavoriteEventGroup } from './LiveFavoriteEventGroup';

const groupComponentsMap = {
  [LIVE_GROUP_COMPONENTS.FAVORITE_EVENT]: LiveFavoriteEventGroup,
};

export const LiveMenuGroups = () => {
  const {
    groupsMemo,
    groupCountsMemo,
    openLiveGroups,
    liveGroupsMemo,
    liveMenuSports: sports,
    handleOnValueChange,
  } = useLiveMenuGroups();

  if (!groupsMemo.length) {
    return null;
  }

  return (
    <>
      <Box flexCol css={{ backgroundColor: '$panelBgColor' }}>
        {groupsMemo.map(({ id, name, icon: Icon, label }) => {
          if (name == LIVE_GROUP_COMPONENTS.TOP_EVENT) {
            return <TopEvent key={id} count={groupCountsMemo[name]} />;
          }

          const Component = groupComponentsMap[name];

          return (
            <MultiAccordionRoot
              key={id}
              type="multiple"
              role={MULTI_ACCORDION_ROLES.PARENT}
              value={openLiveGroups}
              onValueChange={handleOnValueChange}
            >
              <MultiAccordionItem
                value={id.toString()}
                role={MULTI_ACCORDION_ROLES.PARENT}
              >
                <MultiAccordionTrigger
                  title={label}
                  iconComponent={
                    <Box
                      css={{
                        color: '$grayMedium',
                        '&:hover': {
                          color: '$white',
                        },
                      }}
                    >
                      <Icon />
                    </Box>
                  }
                  css={{
                    '& .text': {
                      fontSize: '$16',
                      lineHeight: '$28',
                    },
                  }}
                  count={groupCountsMemo[name]}
                  role={MULTI_ACCORDION_ROLES.PARENT}
                />
                {groupCountsMemo[name] && (
                  <MultiAccordionContent role={MULTI_ACCORDION_ROLES.PARENT}>
                    <MultiAccordionRoot
                      disabled
                      type="multiple"
                      value={(sports || []).map(({ id }) => id.toString())}
                      role={MULTI_ACCORDION_ROLES.CHILD}
                    >
                      <Component sportGroupsMemo={liveGroupsMemo} />
                    </MultiAccordionRoot>
                  </MultiAccordionContent>
                )}
              </MultiAccordionItem>
            </MultiAccordionRoot>
          );
        })}
      </Box>
      <Separator shrinkOut={0} verticalSpace={0} />
    </>
  );
};
