import React, { ReactNode, useState } from 'react';
import * as DropdownUI from '@radix-ui/react-dropdown-menu';

import { useChangeDefaultBalance } from '~api/user/userMutations';
import { BalanceInfo } from '~components/atoms/BalanceInfo';
import { Box } from '~components/atoms/Box';
import {
  RadioGroupRoot,
  StyledRadioGroupItem,
  StyledRadioGroupItemIndicator,
} from '~components/atoms/RadioGroup';
import {
  StyledBalanceInfoText,
  StyledBalanceInfoWrapper,
} from '~components/molecules/UserProfile/components/UserProfileSidebar/styled.components';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setDefaultBalance,
  USER_DEFAULT_BALANCE,
} from '~store/slices/userSlice';
import isSportWebsite from '~utils/isSportWebsite';

import {
  StyledDropdownMenuContent,
  StyledDropdownTrigger,
} from './styled.components';

interface UserBalancesNavigationDropDownProps {
  children: ReactNode;
  onOpenChange: (val: boolean) => void;
}

export const UserBalancesNavigationDropDown = ({
  children,
  onOpenChange,
}: UserBalancesNavigationDropDownProps) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { profile, defaultBalance } = useAppSelector(
    (state) => state.userState,
  );
  const { balance, freeBetBalance } = profile || {
    balance: 0,
    freeBetBalance: 0,
  };

  const { changeDefaultBalanceMutation } = useChangeDefaultBalance();
  const { localized } = useTranslation();

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);

    onOpenChange(open);
  };

  const onDefaultBalanceChange = async (value: string) => {
    const newTypeValue = parseInt(value) as USER_DEFAULT_BALANCE;

    dispatch(setDefaultBalance(newTypeValue));

    try {
      await changeDefaultBalanceMutation(newTypeValue).unwrap();
    } catch (error) {
      console.error('Error during changing preferred balance type', error);
    }
  };

  return (
    <DropdownUI.Root onOpenChange={handleOpenChange} open={isOpen}>
      <StyledDropdownTrigger asChild>
        <div>{children}</div>
      </StyledDropdownTrigger>
      <DropdownUI.Portal>
        <StyledDropdownMenuContent
          sideOffset={20}
          align="start"
          alignOffset={-115}
        >
          <RadioGroupRoot
            value={defaultBalance.toString()}
            onValueChange={onDefaultBalanceChange}
          >
            <Box flexCol gap={2}>
              <StyledBalanceInfoWrapper>
                <Box flexRow gap={3} alignCenter>
                  <StyledRadioGroupItem
                    className="RadioGroupItem"
                    value={USER_DEFAULT_BALANCE.MAIN.toString()}
                  >
                    <StyledRadioGroupItemIndicator />
                  </StyledRadioGroupItem>
                  <Box flexCol>
                    <StyledBalanceInfoText>
                      {localized('userProfile.personalProfileInfo.mainBalance')}
                    </StyledBalanceInfoText>
                    <BalanceInfo
                      balance={balance}
                      color="yellow"
                      size="small"
                    />
                  </Box>
                </Box>
              </StyledBalanceInfoWrapper>
              {isSportWebsite() && (
                <StyledBalanceInfoWrapper>
                  <Box flexRow gap={3} alignCenter>
                    <StyledRadioGroupItem
                      className="RadioGroupItem"
                      value={USER_DEFAULT_BALANCE.BONUS.toString()}
                    >
                      <StyledRadioGroupItemIndicator />
                    </StyledRadioGroupItem>
                    <Box flexCol alignStart>
                      <StyledBalanceInfoText>
                        {localized(
                          'userProfile.personalProfileInfo.bonusBalance',
                        )}
                      </StyledBalanceInfoText>
                      <BalanceInfo
                        freeBetBalance={freeBetBalance}
                        color="yellow"
                        size="small"
                      />
                    </Box>
                  </Box>
                </StyledBalanceInfoWrapper>
              )}
            </Box>
          </RadioGroupRoot>
        </StyledDropdownMenuContent>
      </DropdownUI.Portal>
    </DropdownUI.Root>
  );
};
