import React, { useState } from 'react';

import { SportEventItems } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { MainPageMobileContent } from '~components/molecules/MainHighlights/MainPageMobileContent';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import { usePrematchMenu } from '~components/molecules/PrematchMenu/hooks/usePrematchMenu';
import { SearchBar } from '~components/molecules/PrematchMenu/PrematchMenu/SearchBar';
import { SearchResultList } from '~components/molecules/SearchEvents/SearchResultList';
import { useAppSelector } from '~store';

import { SportMenuMobile } from '../SportMenu/SportMenuMobile';

export const MobilePrematchMenu = () => {
  const { isLoading } = usePrematchMenu();
  const {
    activeSportTabGroup: activeTabGroup = SPORT_TAB_GROUPS.UPCOMMING,
    isFavoriteSelected,
  } = useAppSelector((state) => state.mobileState);

  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchedItems, setSearchedItems] = useState<SportEventItems>([]);

  return (
    <Box flexCol>
      <SearchBar
        onSearchedItemsChange={setSearchedItems}
        onShowSearchResultsChange={setShowSearchResults}
      />
      {showSearchResults ? (
        <SearchResultList isRelative searchEvents={searchedItems} />
      ) : activeTabGroup === SPORT_TAB_GROUPS.UPCOMMING &&
        !isFavoriteSelected ? (
        <MainPageMobileContent />
      ) : (
        <SportMenuMobile isLoading={isLoading} />
      )}
    </Box>
  );
};
