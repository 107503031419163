import { Text } from '~components/atoms/Typography';
import { SPORT_BETSLIP_TYPE_CODES } from '~components/molecules/Betslip/constants';

interface OddsCellProps {
  type: SPORT_BETSLIP_TYPE_CODES;
  totalOdd: number;
}

export const OddsCell = ({ type, totalOdd }: OddsCellProps) => (
  <Text level="12-20">
    {type === SPORT_BETSLIP_TYPE_CODES.SYSTEM ? '-' : totalOdd}
  </Text>
);
