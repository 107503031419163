import { Box } from '~components/atoms/Box';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { Select } from '~components/atoms/Select';
import { useTranslation } from '~hooks/useTranslation';
import { FilterIcon } from '~icons';

import { useTransactionHistoryFilter } from '../hooks/useTransactionHistoryFilter';
import {
  StyledMobileButton,
  StyledMobileText,
} from '../TransactionHistoryTable/styled.components';

interface BetslipHistoryFiltersMobileProps {
  showFilters: boolean;
  setShowFilters: (showFilters: boolean) => void;
}

export const TransactionHistoryFilterMobile = ({
  showFilters,
  setShowFilters,
}: BetslipHistoryFiltersMobileProps) => {
  const { localized } = useTranslation();
  const {
    activeFilterCount,
    transactionStatus,
    transactionType,
    range,
    statusesOptions,
    typesOptions,
    minimumAge,
    onRangeChange,
    onTransactionStatusChange,
    onTransactionTypeChange,
    onReset,
    onApply,
  } = useTransactionHistoryFilter();

  const handleShowResults = () => {
    setShowFilters(false);
    onApply();
  };

  if (!showFilters) {
    return (
      <Box flexRow gap={2}>
        <StyledMobileButton
          css={{
            position: 'relative',
          }}
          onClick={() => setShowFilters(true)}
        >
          <Box
            flexRow
            alignCenter
            justifyCenter
            gap={2}
            css={{
              color: '$white',
            }}
          >
            <FilterIcon />
            <StyledMobileText>
              {localized('transactionHistory.button.filters')}
            </StyledMobileText>
            {activeFilterCount > 0 && (
              <Box
                flexRow
                alignCenter
                justifyCenter
                css={{
                  position: 'absolute',
                  right: '10px',
                  backgroundColor: '$green',
                  width: '$5',
                  height: '$4',
                  borderRadius: '$2',
                }}
              >
                <StyledMobileText
                  smallFont
                  css={{
                    color: '$buttonTextColor !important',
                  }}
                >
                  {activeFilterCount}
                </StyledMobileText>
              </Box>
            )}
          </Box>
        </StyledMobileButton>
        <StyledMobileButton onClick={onReset}>
          <StyledMobileText>
            {localized('transactionHistory.button.resetFilters')}
          </StyledMobileText>
        </StyledMobileButton>
      </Box>
    );
  }

  return (
    <Box flexCol gap={4}>
      <Box fullWidth flexRow gap={2}>
        <DateRangePicker
          isFutureDatesDisabled
          legalAge={minimumAge}
          onOkRange={(dates) => onRangeChange(dates)}
          value={range}
        />
      </Box>
      <Select
        placeholder={localized('transactionHistory.transactionTypeLabel')}
        value={transactionType?.toString() || ''}
        onChange={(value) => onTransactionTypeChange(value as string)}
        options={typesOptions}
        css={{
          borderRadius: '$8',
          backgroundColor: '$filtersInputMobileBgColor',
        }}
      />
      <Select
        placeholder={localized('transactionHistory.transactionStatusLabel')}
        value={transactionStatus?.toString() || ''}
        onChange={(value) => onTransactionStatusChange(value as string)}
        options={statusesOptions}
        css={{
          borderRadius: '$8',
          backgroundColor: '$filtersInputMobileBgColor',
        }}
      />
      <Box flexRow gap={2}>
        <StyledMobileButton
          onClick={() => setShowFilters(false)}
          css={{
            backgroundColor: '$closeFiltersButtonBgColor',
          }}
        >
          <StyledMobileText>
            {localized('transactionHistory.button.closeFilters')}
          </StyledMobileText>
        </StyledMobileButton>
        <StyledMobileButton variant="primary" onClick={handleShowResults}>
          <StyledMobileText
            css={{
              color: '$buttonTextColor !important',
            }}
          >
            {localized('transactionHistory.button.show')}
          </StyledMobileText>
        </StyledMobileButton>
      </Box>
    </Box>
  );
};
