import React, { Suspense, useEffect } from 'react';
import { Routes } from 'react-router-dom';

import { useInitialBetslipState } from '~components/molecules/Betslip/hooks/useInitialBetslipState';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { useQueryParams } from '~hooks/useQueryParams';
import { Fallback, renderRoutes } from '~routes';
import { useAppDispatch, useAppSelector } from '~store';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { setWindowScrolled } from '~store/slices/userUISlice';
import debounce from '~utils/debounce';

import { SwipeComponent } from '../SwipeComponent';

export const MobileLayout = () => {
  const { menu: menuQuery } = useQueryParams();
  const dispatch = useAppDispatch();
  const { isSwipeOpen } = useAppSelector((state) => state.mobileState);

  useInitialBetslipState();

  useEffect(() => {
    const handleWindowScroll = debounce(100, () => {
      dispatch(setWindowScrolled(!!window.scrollY));
    });

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      if (!menuQuery || menuQuery === MOBILE_MENU.SPORT) {
        dispatch(setMobileActiveTab(MOBILE_MENU.SPORT));
      } else if (menuQuery === MOBILE_MENU.LIVE) {
        dispatch(setMobileActiveTab(MOBILE_MENU.LIVE));
      }
    }
  }, []);

  return (
    <>
      {isSwipeOpen && <SwipeComponent />}
      <Suspense fallback={<Fallback />}>
        <Routes>{renderRoutes(true)}</Routes>
      </Suspense>
    </>
  );
};
