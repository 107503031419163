import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import {
  INFO_PAGE_BASE_PATH,
  INFO_PAGE_SECTION_NAMES,
} from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { CrossIcon } from '~icons';

import {
  StyledActionButton,
  StyledButtonText,
  StyledErrorPageContainer,
  StyledErrorText,
  StyledErrorTitle,
  StyledIconWrapper,
} from './styled.components';

interface ErrorPageProps {
  title: string;
  text?: string;
}

export const ErrorPage = ({ title, text }: ErrorPageProps) => {
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const { localizedError, localized } = useTranslation();
  const { navigateBack } = useRouterQuery();

  const handleContactUsClick = () => {
    navigate(`${INFO_PAGE_BASE_PATH}/${INFO_PAGE_SECTION_NAMES.CONTACT_US}`);
  };

  const handleBackClick = () => {
    navigateBack();
  };

  return (
    <StyledErrorPageContainer>
      <StyledIconWrapper>
        <CrossIcon />
      </StyledIconWrapper>
      <Box>
        <StyledErrorTitle>
          {title || localizedError('root.someErrorOccurredTitle')}
        </StyledErrorTitle>
      </Box>
      {text ? (
        <Box>
          <StyledErrorText>{text}</StyledErrorText>
        </Box>
      ) : (
        <Box flexCol justifyCenter alignCenter>
          <StyledErrorText>
            {localizedError('root.somethingNotWorking')}
          </StyledErrorText>
          <StyledErrorText>
            {localizedError('root.apologiesForError')}
          </StyledErrorText>
        </Box>
      )}
      <Separator />
      <Box flexRow fullWidth justifyCenter gap={3}>
        <StyledActionButton variant="secondary" onClick={handleBackClick}>
          <StyledButtonText>{localized('buttons.back')}</StyledButtonText>
        </StyledActionButton>
        <StyledActionButton variant="secondary" onClick={handleContactUsClick}>
          <StyledButtonText>
            {`${localized('buttons.contactUs')} ${
              !isMobile ? ' / ' + localized('buttons.help') : '' // Mobile - "Contact Us", Desktop - "Contact Us / Help"
            }`}
          </StyledButtonText>
        </StyledActionButton>
      </Box>
    </StyledErrorPageContainer>
  );
};
