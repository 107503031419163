import { Box } from '~components/atoms/Box';
import { Select } from '~components/atoms/Select';
import { SportMenuList } from '~components/atoms/SportMenuList';
import { ToggleSportMarket } from '~components/atoms/ToggleSportMarket';
import { SPORTS } from '~constants/sport';
import { getSportIcon } from '~utils/getSportIcon';

import { LiveTopEventListMobile } from '../LiveTopEventListMobile';

import { useLiveTopEventsMobile } from './useLiveTopEventsMobile';

export const LiveTopEventsMobile = () => {
  const {
    sports,
    matches,
    sportName,
    mainSportMarket,
    showSports,
    selectedSportIndex,
    sportId,
    handleSportMenuClick,
    setShowSports,
  } = useLiveTopEventsMobile();

  return (
    <Box flexCol>
      <Box flexRow css={{ px: '$1', py: '$2', backgroundColor: '$black' }}>
        <Select
          placeholder={sportName}
          shouldClose={!showSports}
          onClose={() => setShowSports(false)}
          isCustomChildren
          icon={getSportIcon(sportName! as SPORTS, { width: 20, height: 20 })}
          css={{
            flexGrow: 1,
            pl: '$1',
            py: '10px',
            color: '$white',
            fontSize: '$14',
            lineHeight: '$24',
            fontWeight: '$medium',
          }}
          contentCss={{ left: '-6px' }}
          iconSize={16}
          isCustomContentWidth
          onOpenChange={setShowSports}
        >
          <Box flexCol css={{ width: 'calc(100vw - 32px)' }}>
            <SportMenuList
              sports={sports}
              activeSportIdIndex={selectedSportIndex}
              onClick={handleSportMenuClick}
            />
          </Box>
        </Select>
      </Box>
      <Box flexCol css={{ position: 'relative', zIndex: 1 }}>
        {sportId && (
          <Box flexRow css={{ backgroundColor: '$black', px: '$1' }}>
            <ToggleSportMarket sportId={sportId} />
          </Box>
        )}
        {matches && (
          <Box flexCol css={{ p: '$2 $1', backgroundColor: '$black' }}>
            <LiveTopEventListMobile
              events={matches}
              mainSportMarket={mainSportMarket}
            />
          </Box>
        )}
        {showSports && (
          <Box
            fullWidth
            fullHeight
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              background: '$blackOpacity8',
              backdropFilter: 'blur(4px)',
              zIndex: 2,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
