import { Box } from '~components/atoms/Box';
import {
  StyledTabsContent,
  StyledTabsRoot,
} from '~components/atoms/Tabs/styled.components';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_METHODS,
} from '~constants/payments';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNew,
  setAddingNewPaymentMethodFormStatus,
  setSelectedPayment,
} from '~store/slices/paymentsSlice';
import { setContentTab } from '~store/slices/personalDetailsSlice';
import { ContentsMap } from '~types/general';

import { PaymentAccounts } from '../../components/Payments/components/PaymentAccounts';
import { TransactionHistory } from '../../components/Payments/components/TransactionHistory';
import { Payments } from '../Payments';
import { UserPaymentsTabs } from '../UserPaymentsTabs';
import { USER_PAYMENT_TABS } from '../UserProfileDialog/constants';

export const UserPaymentsWrapper = () => {
  const dispatch = useAppDispatch();
  const { contentTab = USER_PAYMENT_TABS.WITHDRAW } = useAppSelector(
    (state) => state.personalDetails,
  );

  const tabsContentMap: ContentsMap = {
    [USER_PAYMENT_TABS.WITHDRAW]: <Payments />,
    [USER_PAYMENT_TABS.DEPOSIT]: <Payments />,
    [USER_PAYMENT_TABS.PAYMENT_ACCOUNTS]: <PaymentAccounts />,
    [USER_PAYMENT_TABS.TRANSACTION_HISTORY]: (
      <TransactionHistory key="user-transactions" />
    ),
  };

  const getTabComponent = () => {
    const content = tabsContentMap[contentTab as USER_PAYMENT_TABS];

    return (
      <StyledTabsContent value={contentTab as USER_PAYMENT_TABS}>
        {content}
      </StyledTabsContent>
    );
  };

  if (!contentTab) {
    return null;
  }

  return (
    <StyledTabsRoot
      onValueChange={(value) => {
        dispatch(setContentTab(value as USER_PAYMENT_TABS));
        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
          ),
        );
        dispatch(setAddingNew(false));
        if (value === USER_PAYMENT_TABS.WITHDRAW) {
          dispatch(setSelectedPayment(PAYMENT_METHODS.INSTANT_CARD));
        }
      }}
      value={contentTab as string}
      fullHeight
    >
      <Box flexCol gap={2} fullHeight>
        <UserPaymentsTabs />
        <StyledTabsContent value={contentTab as USER_PAYMENT_TABS}>
          {getTabComponent()}
        </StyledTabsContent>
      </Box>
    </StyledTabsRoot>
  );
};
