import { useEffect } from 'react';
import { useTranslation as useI18nTranslation } from 'react-i18next';
import { TOptions } from 'i18next';

import { useAppSelector } from '~store';

export const useTranslation = () => {
  const { language } = useAppSelector((state) => state.websiteSettings);
  const { i18n, t } = useI18nTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const localized = (key: string, args?: object) => t(key, args as TOptions);
  const localizedError = (key: string, args?: object) =>
    t(key, { ns: 'error', ...args });

  return { t, localized, localizedError };
};
