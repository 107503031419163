import React, { memo, useEffect, useState } from 'react';

import { InPlayMenuCountries } from '~api/sportEvent/types';
import { ToggleSportMarket } from '~components/atoms/ToggleSportMarket';
import { SportIconKey } from '~constants/sport';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionTrigger,
} from '../../MultiAccordion';

import { LiveCountryMenu } from './LiveCountryMenu';

interface LiveSportMenuItemProps {
  id: number;
  menuRole: MULTI_ACCORDION_ROLES;
  name: string;
  countries: InPlayMenuCountries;
  getSportEventsCount: (countries: InPlayMenuCountries) => number;
}

export const LiveSportMenuItem = ({
  id,
  menuRole,
  name,
  getSportEventsCount,
  countries,
}: LiveSportMenuItemProps) => {
  const [countriesData, setCountriesData] =
    useState<InPlayMenuCountries>(countries);

  useEffect(() => {
    if (areTwoObjectsEqual(countriesData, countries)) return;
    setCountriesData(countries);
  }, [countries]);

  return (
    <LiveSportMenuItemContent
      {...{ id, menuRole, name, getSportEventsCount }}
      countries={countriesData || []}
    />
  );
};

export const LiveSportMenuItemContent = memo(
  ({
    id,
    menuRole,
    name,
    getSportEventsCount,
    countries,
  }: LiveSportMenuItemProps) => {
    return (
      <MultiAccordionItem value={id.toString()} key={id} role={menuRole}>
        <MultiAccordionTrigger
          title={name}
          icon={name as SportIconKey}
          count={getSportEventsCount(countries)}
          role={menuRole}
        />
        <MultiAccordionContent role={menuRole}>
          <ToggleSportMarket sportId={id} />
          <LiveCountryMenu sportId={id} countries={countries} />
        </MultiAccordionContent>
      </MultiAccordionItem>
    );
  },
);
