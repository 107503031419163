import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { BasicButton } from '~components/atoms/Button';

export const StyledShowMorePanelWrapper = styled(Box, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '30px',
    background:
      'linear-gradient(180deg, $showMorePanelShadeColorOpacity 0%, $showMorePanelShadeColor 100%)',
    pointerEvents: 'none',
  },
  variants: {
    open: {
      true: {
        overflow: 'auto',
        '&:after': {
          display: 'none',
        },
      },
    },
  },
});

export const StyledShowMorePanel = styled(Box, {
  position: 'relative',
  overflow: 'hidden',
  variants: {
    open: {
      true: {
        overflow: 'auto',
      },
    },
  },
});

export const StyledShowMoreButton = styled(BasicButton, {
  position: 'relative',
  border: 'none',
  outline: 'none',
  background: 'transparent',
  fontSize: '$10',
  lineHeight: '$20',
  fontWeight: '$normal',
  color: '$grayMedium',
  p: 0,
  pb: '$2',
  cursor: 'pointer',
  '&:active': {
    color: '$white',
  },
});

export const StyledShowMoreButtonTrigger = styled(Box, {
  position: 'absolute',
  bottom: '0',
  width: '$3',
  left: '50%',
  transform: 'translateX(-50%) rotate(0deg)',
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  display: 'flex',
  color: 'inherit',
  variants: {
    open: {
      true: {
        transform: 'translateX(-50%) rotate(180deg)',
      },
    },
  },
});
