import { memo, useMemo } from 'react';
import { styled } from 'stitches.config';

import { MainMarket } from '~api/market/types';
import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks/useMedia';

import { EventsListItem } from './EventsListItem';

export interface EventsListProps {
  activeEvents: SportEventItem[];
  mainMarkets: MainMarket[];
  isLive?: boolean;
  withTopPadding?: boolean;
  maxMarketsCount?: number;
}

const StyledWrapperBox = styled(Box, {
  variants: {
    hasValidMarket: {
      true: {},
      false: { background: '$gray', pt: '.5rem !important' },
    },
  },
});

const StyledInnerBox = styled(Box, {
  background: '$gray',
  borderBottomRightRadius: '$6',
  borderBottomLeftRadius: '$6',
  width: '100%',
  position: 'relative',
  variants: {
    withTopPadding: {
      true: { padding: '$2 $3 $2 !important' },
      false: { padding: '$0 $3 $3' },
    },
    withTopGap: {
      true: { pt: '$3' },
      false: { pt: 0 },
    },
  },
});

export const EventsList = memo(
  ({
    activeEvents,
    mainMarkets,
    isLive,
    withTopPadding,
    maxMarketsCount,
  }: EventsListProps) => {
    const { isMobileOrTablet } = useMedia();

    // Here we're checking that at least one non-null market exists
    const hasValidMarket = mainMarkets.some((item) => !!item);

    const eventListItems = useMemo(() => {
      return activeEvents.map((eventData, index) => (
        <EventsListItem
          maxMarketsCount={maxMarketsCount}
          isFirst={index === 0}
          isLive={isLive}
          key={eventData?.id + index}
          eventData={eventData}
          mainMarkets={mainMarkets}
        />
      ));
    }, [activeEvents, maxMarketsCount, isLive, mainMarkets]);

    const withTopGap = !isMobileOrTablet || !eventListItems.length;

    return (
      <StyledWrapperBox hasValidMarket={hasValidMarket}>
        <StyledInnerBox
          flexCol
          fullWidth
          fullHeight
          withTopPadding={!!withTopPadding}
          withTopGap={withTopGap}
          gap={3}
        >
          {eventListItems}
        </StyledInnerBox>
      </StyledWrapperBox>
    );
  },
);
