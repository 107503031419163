import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';

import {
  StyledExpiresLabel,
  StyledExpiresTimeNumber,
} from './styled.components';

interface ActiveBonusExpirationProps {
  days: number;
  hours: number;
  minutes: number;
}

const ColonSeparator = () => (
  <Text level="18-28" fontWeight="bold">
    :
  </Text>
);

export const ActiveBonusExpiration = ({
  days,
  hours,
  minutes,
}: ActiveBonusExpirationProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  return (
    <Box
      flexCol
      alignCenter
      gap={1}
      css={{
        pb: '$6',
        '@xs_sm': {
          pb: '$5 ',
        },
      }}
    >
      <Text
        color="whiteDark"
        level={isMobileOrTablet ? '11-24' : '12-24'}
        fontWeight="medium"
        textTransform="capitalize"
      >
        {localized('bonuses.expiresIn', { postfix: ':' })}
      </Text>
      <Box flexRow gap={1}>
        <Box flexCol alignCenter gap={1} css={{ position: 'relative' }}>
          <StyledExpiresTimeNumber flexRow alignCenter justifyCenter>
            <Text level="15-24" fontWeight="bold">
              {days}
            </Text>
          </StyledExpiresTimeNumber>
          <StyledExpiresLabel flexRow>
            <Text
              level={isMobileOrTablet ? '10-16' : '12-20'}
              fontWeight="medium"
            >
              {localized('bonuses.days')}
            </Text>
          </StyledExpiresLabel>
        </Box>
        <ColonSeparator />
        <Box flexCol alignCenter gap={1} css={{ position: 'relative' }}>
          <StyledExpiresTimeNumber flexRow alignCenter justifyCenter>
            <Text level="15-24" fontWeight="bold">
              {hours}
            </Text>
          </StyledExpiresTimeNumber>
          <StyledExpiresLabel flexRow>
            <Text
              level={isMobileOrTablet ? '10-16' : '12-20'}
              fontWeight="medium"
            >
              {localized('bonuses.hours')}
            </Text>
          </StyledExpiresLabel>
        </Box>
        <ColonSeparator />
        <Box flexCol alignCenter gap={1} css={{ position: 'relative' }}>
          <StyledExpiresTimeNumber flexRow alignCenter justifyCenter>
            <Text level="15-24" fontWeight="bold">
              {minutes}
            </Text>
          </StyledExpiresTimeNumber>
          <StyledExpiresLabel flexRow>
            <Text
              level={isMobileOrTablet ? '10-16' : '12-20'}
              fontWeight="medium"
            >
              {localized('bonuses.minutes')}
            </Text>
          </StyledExpiresLabel>
        </Box>
      </Box>
    </Box>
  );
};
