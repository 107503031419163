import { Box } from '~components/atoms/Box';
import {
  RadioGroupRoot,
  StyledRadioGroupItem,
  StyledRadioGroupItemIndicator,
} from '~components/atoms/RadioGroup';
import { SelectWithLabel } from '~components/atoms/SelectWithLabel';
import {
  StyledOddsContainer,
  StyledSettingsPopoverContent,
  StyledSettingsPopoverDesktopLabel,
  StyledSettingsPopoverItem,
} from '~components/atoms/SettingsDropdown/styled.components';
import { Text } from '~components/atoms/Typography';
import { ODDS } from '~constants/odds';
import { TIMEZONES } from '~constants/timezones';
import { useTranslation } from '~hooks/useTranslation';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';

// type Props = {
//   flagsMap: Record<string, ReactNode>;
//   langOptions: SelectOptionType[];
// };

export const SettingsDropdownPopoverDesktop = () => {
  const { localized } = useTranslation();

  const { odds, timezone, onTimezoneChange, onOddsChange } =
    useWebsiteSettings();

  return (
    <StyledSettingsPopoverContent>
      <StyledSettingsPopoverItem
        css={{ padding: '12px 16px', flexDirection: 'row' }}
      >
        {/*<div style={{ width: '240px' }}>*/}
        {/*  <StyledSettingsPopoverDesktopLabel*/}
        {/*    level="14-20"*/}
        {/*    textTransform="uppercase"*/}
        {/*    color="grayMedium"*/}
        {/*  >*/}
        {/*    {localized('settings.language')}*/}
        {/*  </StyledSettingsPopoverDesktopLabel>*/}
        {/*  <SelectWithLabel*/}
        {/*    value={language}*/}
        {/*    setValue={onLanguageChange}*/}
        {/*    options={langOptions}*/}
        {/*    additionalSelectedComponents={flagsMap}*/}
        {/*    dropdownWidth="230px"*/}
        {/*    style={{ width: '100%' }}*/}
        {/*  />*/}
        {/*</div>*/}
        <div style={{ width: '240px' }}>
          <StyledSettingsPopoverDesktopLabel
            level="14-20"
            textTransform="uppercase"
            color="grayMedium"
          >
            {localized('settings.timeZone')}
          </StyledSettingsPopoverDesktopLabel>
          <SelectWithLabel
            value={timezone}
            setValue={onTimezoneChange}
            options={TIMEZONES}
            style={{ width: '100%' }}
          />
        </div>
      </StyledSettingsPopoverItem>
      <StyledSettingsPopoverItem>
        <StyledSettingsPopoverDesktopLabel
          level="14-20"
          textTransform="uppercase"
          color="grayMedium"
        >
          {localized('settings.oddsFormat')}
        </StyledSettingsPopoverDesktopLabel>
        <RadioGroupRoot value={odds as string} onValueChange={onOddsChange}>
          <StyledOddsContainer>
            {ODDS.map(({ value, label }) => (
              <Box flexRow alignCenter gap={2} key={value}>
                <StyledRadioGroupItem value={value}>
                  <StyledRadioGroupItemIndicator />
                </StyledRadioGroupItem>
                <Text level="14-24">{label}</Text>
              </Box>
            ))}
          </StyledOddsContainer>
        </RadioGroupRoot>
      </StyledSettingsPopoverItem>
    </StyledSettingsPopoverContent>
  );
};
