import { TabsList } from '~components/atoms/Tabs';
import { useTranslation } from '~hooks/useTranslation';

import { USER_PAYMENT_TABS } from '../UserProfileDialog/constants';

export const userPaymentsTabs = [
  USER_PAYMENT_TABS.WITHDRAW,
  USER_PAYMENT_TABS.DEPOSIT,
  USER_PAYMENT_TABS.TRANSACTION_HISTORY,
];

export const UserPaymentsTabs = () => {
  const { localized } = useTranslation();

  return (
    <TabsList
      uppercase
      tabs={userPaymentsTabs}
      ariaLabel={localized('userProfile.personalProfileInfo.ariaLabel')}
    />
  );
};
