import { useEffect, useState } from 'react';

import { useLazyGetBetslipHistory } from '~api/betslip/betslipQueries';
import { BetslipHistoryPayload } from '~api/betslip/types';
import { useAppDispatch, useAppSelector } from '~store';
import { setIsBetslipHistoryLoading } from '~store/slices/betslipSlice';
import { setBetslipHistory, setParams } from '~store/slices/openBetSlice';

const PAGING_LIMIT = 20;

export const useBetslipHistory = () => {
  const { lazyGetBetslipHistoryQuery } = useLazyGetBetslipHistory();
  const { betslipHistory, params: filterParams } = useAppSelector(
    (state) => state.openBets,
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(0);

  const dispatch = useAppDispatch();

  const loadBetslipHistory = async (
    params: BetslipHistoryPayload | undefined,
    loadMore?: boolean,
  ) => {
    try {
      if (!loadMore) {
        dispatch(setParams({}));
        betslipHistory.length && dispatch(setBetslipHistory([]));
      }

      if (params) {
        dispatch(setParams(params));
      }

      const page = !loadMore
        ? 1
        : Math.ceil(betslipHistory.length / PAGING_LIMIT) + 1;

      dispatch(setIsBetslipHistoryLoading(true));
      const { data, total } = await lazyGetBetslipHistoryQuery({
        ...(params || {}),
        ...(loadMore ? filterParams : {}),
        limit: PAGING_LIMIT,
        page,
      }).unwrap();

      setTotal(total);
      dispatch(setIsBetslipHistoryLoading(false));

      if (!data) return;
      dispatch(
        setBetslipHistory([...(!loadMore ? [] : betslipHistory), ...data]),
      );
      setIsLoaded(true);
    } catch (e) {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    return () => {
      setIsLoaded(false);
    };
  }, []);

  return {
    loadBetslipHistory,
    isLoaded,
    betHistory: betslipHistory,
    total,
    loadMore: () => loadBetslipHistory(undefined, true),
  };
};
