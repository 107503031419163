import { useEffect, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { nigeriaPaymentProviders } from '~constants/payments';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setSelectedNigeriaDepositProvider } from '~store/slices/paymentsSlice';

export const DepositMethod = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { selectedNigeriaDepositProvider, selectedNigeriaMethod } =
    useAppSelector((state) => state.payments);

  const [options, setOptions] = useState(nigeriaPaymentProviders);

  const handleOptionClick = (value: string) => {
    dispatch(setSelectedNigeriaDepositProvider(value));
  };

  useEffect(() => {
    if (selectedNigeriaMethod === 'enaira') {
      const options = nigeriaPaymentProviders.filter(
        (provider) => provider.value === 'flutterwave',
      );

      dispatch(setSelectedNigeriaDepositProvider('flutterwave'));
      setOptions(options);
    } else {
      setOptions(nigeriaPaymentProviders);
    }
  }, [selectedNigeriaMethod]);

  return (
    <Box flexCol fullWidth css={{ p: '$2 $3' }}>
      {isMobileOrTablet && (
        <Text color="grayMedium" level="12-20" textTransform="uppercase">
          {localized('paymentProvider')}
        </Text>
      )}
      <Box
        flexRow
        justifyCenter
        alignCenter
        gap={5}
        fullWidth
        css={{
          padding: '$2',

          ...(options.length > 1 && isMobileOrTablet
            ? {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: '$3',
              }
            : {
                background: '$blueDark',
              }),
        }}
      >
        {options.map(({ label, value, icon, activeIcon }) => {
          const isSelected = selectedNigeriaDepositProvider === value;
          const Icon = isSelected ? activeIcon : icon;

          return (
            <Box
              key={label}
              flexCol={isMobileOrTablet}
              flexRow={!isMobileOrTablet}
              alignCenter
              gap={1}
              onClick={() => handleOptionClick(value)}
              css={{
                cursor: 'pointer',
                ...(isMobileOrTablet
                  ? {
                      background: '$grayMain',
                      borderRadius: '$8',
                      p: '$2',
                    }
                  : {}),
              }}
            >
              <Icon />
              <Text level="13-24" color={isSelected ? 'yellow' : 'white'}>
                {localized(label)}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
