import React, { ReactNode } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ContactUsButton } from '~components/atoms/ContactUsButton';
import { Separator } from '~components/atoms/Separator';
import { SettingsDropdown } from '~components/atoms/SettingsDropdown';
import { SignIn } from '~components/molecules/Authentication/components/sign-in/SignIn';
import { SignUp } from '~components/molecules/Authentication/components/sign-up/SignUp';
import { UserProfileBar } from '~components/molecules/UserProfile/components/UserProfileBar/UserProfileBar';
import { HeaderCloseButton } from '~components/organisms/HeaderComponent/Header/HeaderCloseButton';
import { HeaderMenuCasino } from '~components/organisms/HeaderComponent/Header/HeaderMenuCasino';
import { HeaderMenuSport } from '~components/organisms/HeaderComponent/Header/HeaderMenuSport';
import { LogoHeaderWrapper } from '~components/organisms/HeaderComponent/Header/LogoHeaderWrapper';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';
import isSportWebsite from '~utils/isSportWebsite';

const StyledHeader = styled('header', {
  position: 'relative',
  fontFamily: 'inherit',
  height: '140px',
  maxWidth: '1920px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$headerBgColor',
  p: '10px $5',
  '@xs_sm': {
    p: '10px $4',
    borderBottom: '1px solid $headerBottomBorder',
    height: 'unset',
  },
  '@md': {
    height: '128px',
  },
  variants: {
    noBottomBorder: {
      true: {
        borderBottom: 'none',
      },
    },
    inModal: {
      true: {
        backgroundColor: '$modalHeaderBgColor',
        '@xs_sm': {
          p: '10px  52px 10px $4 !important',
          borderBottom: '1px solid $headerBottomBorder',
        },
      },
    },
  },
});

interface HeaderProps {
  children?: ReactNode;
  showSettings?: boolean;
  noBottomBorder?: boolean;
  showContactUs?: boolean;
  showCloseButton?: boolean;
  inModal?: boolean;
}

export const Header = ({
  noBottomBorder = false,
  inModal = false,
  showSettings,
  showContactUs,
  showCloseButton,
}: HeaderProps) => {
  const isSport = isSportWebsite();
  const { isMobileOrTablet } = useMedia();
  const { isLaptop } = useMedia();

  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);

  return (
    <Box
      css={{
        backgroundColor: '$headerBgColor',
        gridArea: 'header',
      }}
      flexRow
      justifyCenter
      fullWidth
    >
      <StyledHeader noBottomBorder={noBottomBorder} inModal={inModal}>
        <Box
          flexCol
          fullWidth
          css={{
            gap: isLaptop ? '10px' : '$3',
          }}
        >
          <Box flexRow justifyContentBetween alignCenter>
            <LogoHeaderWrapper />
            <Box flexRow alignCenter gap={isMobileOrTablet ? 3 : 4}>
              {false && showContactUs && <ContactUsButton />}
              {!isUserLoggedIn ? (
                <Box
                  flexRow
                  alignCenter
                  gap={2}
                  css={{
                    div: {
                      height: '44px',
                      '@xs_sm': {
                        height: '28px',
                      },
                    },
                  }}
                >
                  <SignIn />
                  <SignUp />
                </Box>
              ) : (
                <UserProfileBar />
              )}
              {showSettings && <SettingsDropdown />}
              {showCloseButton && <HeaderCloseButton />}
            </Box>
          </Box>
          {!inModal && (
            <>
              <Separator
                css={{ backgroundColor: '$gray' }}
                verticalSpace={0}
                shrinkOut={5}
              />
              {isSport ? <HeaderMenuSport /> : <HeaderMenuCasino />}
            </>
          )}
        </Box>
      </StyledHeader>
    </Box>
  );
};
