import { memo, MouseEvent, useRef } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { useInplayFavoriteEvents } from '~components/molecules/InplayEvents/hooks/useInplayFavoriteEvents';
import { FavoriteIcon } from '~icons';
import { useAppSelector } from '~store';

const StyledFavoriteIcon = styled(FavoriteIcon, {
  cursor: 'pointer',
  '@md_lg_xl': {
    '&:hover': {
      color: '$white',
    },
  },
  '@xs_sm': {
    '&:focus': {
      color: '$white',
    },
  },
});

const StyledMarketCount = styled(Tag, {
  cursor: 'pointer',
  backgroundColor: '$grayMedium',
  '&:hover': {
    backgroundColor: '$white',
  },
});

interface LiveEventDetailsProps {
  isMobile: boolean;
  marketsCount: string;
  startDate: string | null;
  matchStatus: string;
  eventId: string;
  details?: string | null;
}

export const LiveEventDetails = memo(
  ({
    isMobile,
    marketsCount,
    startDate,
    matchStatus,
    eventId,
    details = null,
  }: LiveEventDetailsProps) => {
    const { isUserLoggedIn } = useAppSelector((state) => state.userState);
    const { handleFavoriteInplayEventClick, isEventFavorite } =
      useInplayFavoriteEvents(eventId);
    const favoriteWrapperRef = useRef<HTMLDivElement>(null);

    const handleFavoriteIconClick = async (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      await handleFavoriteInplayEventClick();

      if (favoriteWrapperRef.current) {
        favoriteWrapperRef.current.blur();
      }
    };

    return (
      <Box fullWidth>
        {isMobile && (
          <Separator
            shrinkOut={3}
            verticalSpace={1}
            css={{ backgroundColor: '$gray' }}
          />
        )}
        <Box
          css={{
            '@xs_sm': {
              marginTop: !details ? '$2' : 0,
            },
          }}
          flexRow
          justifyContentBetween
          alignCenter
        >
          <Box flexCol justifyCenter alignStart css={{ gap: '2px' }}>
            <Text level="12-20" color="grayMedium">
              {isMobile ? startDate + ', ' : ''}
              {matchStatus}
            </Text>
            {details && (
              <Text level="12-20" color="grayMedium">
                {details}
              </Text>
            )}
          </Box>
          {isMobile ? (
            <Box flexRow alignCenter gap={2} css={{ height: '$5' }}>
              {isUserLoggedIn && (
                <Box
                  flexRow
                  css={{ color: isEventFavorite ? '$white' : '$grayMedium' }}
                  ref={favoriteWrapperRef}
                >
                  <StyledFavoriteIcon
                    width={14}
                    height={14}
                    onClick={handleFavoriteIconClick}
                  />
                </Box>
              )}
              <Box
                css={{
                  width: '1px',
                  height: '14px',
                  backgroundColor: '$grayDark',
                }}
              />
              <StyledMarketCount
                color="grayMedium"
                text={marketsCount}
                size="small"
                css={{
                  width: '32px',
                  height: '14px',
                  lineHeight: '$15',
                }}
              />
            </Box>
          ) : (
            <Box
              flexCol
              justifyContentEnd={!isMobile}
              alignCenter={isMobile}
              alignEnd={!isMobile}
              css={{ gap: '2px' }}
            >
              <Box flexRow alignCenter gap={1} css={{ height: '$5' }}>
                {isUserLoggedIn && (
                  <Box
                    flexRow
                    css={{ color: isEventFavorite ? '$white' : '$grayMedium' }}
                  >
                    <StyledFavoriteIcon onClick={handleFavoriteIconClick} />
                  </Box>
                )}
                <StyledMarketCount
                  color="grayMedium"
                  text={marketsCount}
                  size="small"
                  css={{
                    width: '32px',
                    height: '14px',
                    lineHeight: '$15',
                  }}
                />
              </Box>
              <Text level="12-20" noWrap color="grayMedium">
                {startDate}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);
