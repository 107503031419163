import { useContext, useEffect } from 'react';

import { Transactions } from '~api/transaction/types';
import {
  SharedRefContext,
  SharedRefContextProps,
} from '~contexts/SharedRefContext';
import debounce from '~utils/debounce';

interface UseTransactionHistoryListProps {
  loadMore: () => void;
  transactions: Transactions;
  dataLength?: number;
}

export const useTransactionHistoryList = ({
  loadMore,
  transactions,
  dataLength,
}: UseTransactionHistoryListProps) => {
  const context = useContext(SharedRefContext) as SharedRefContextProps;

  const isScrolledToBottom = (element: HTMLDivElement) => {
    return element.scrollHeight - element.scrollTop <= element.clientHeight + 5;
  };

  useEffect(() => {
    if (transactions.length === dataLength) return;
    const { ref: dialogMobileContentWrapperRef } = context;
    const handleDirectScroll = debounce(400, (event: Event) => {
      const element = event.target as HTMLDivElement;

      if (isScrolledToBottom(element)) {
        loadMore();
      }
    });
    const current = dialogMobileContentWrapperRef.current;

    if (current) {
      current.addEventListener('scroll', handleDirectScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleDirectScroll);
      }
    };
  }, [transactions, dataLength]);
};
