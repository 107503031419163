import React, { lazy, ReactNode, useEffect } from 'react';
import { Route } from 'react-router-dom';

import { DesktopPageTemplate } from '~components/atoms/layouts/DesktopPageTemplate';
import { MobilePageTemplate } from '~components/atoms/layouts/MobilePageTemplate';
import { ROUTE_TAB_NAMES } from '~constants/common';
import isSportWebsite from '~utils/isSportWebsite';

const isSport = isSportWebsite();

const NotFoundPage = lazy(() => import('~components/atoms/NotFoundPage'));
const MobileTabContents = lazy(
  () => import('~components/organisms/MobileTabContents/MobileTabContents'),
);
const VerifyMessengerPage = lazy(
  () => import('~components/VerifyMessengerPage'),
);
const SportsPage = lazy(() => import('~pages/SportsPage'));
const InfoPage = lazy(() => import('~pages/Info/InfoPage'));
const JackpotPage = lazy(() => import('~pages/JackpotPage'));
const BetslipMobilePage = lazy(
  () => import('~components/molecules/Betslip/BetslipMobilePage'),
);
const CasinoPage = lazy(() => import('~pages/CasinoPage'));
const GamePage = lazy(() => import('~pages/GamePage'));

export const Fallback = () => {
  useEffect(() => {
    return () => {
      // Removing loading div after all chunks are loaded
      document.querySelector('#loading')?.remove();
      document.body.removeAttribute('style');
    };
  }, []);

  return null;
};

export interface RouteConfig {
  path: ROUTE_TAB_NAMES;
  component: ReactNode;
  header?: ReactNode | null;
  footer?: ReactNode | null;
  isMobileOrTablet?: boolean;
  isSportPage?: boolean;
}

export type RoutesConfig = RouteConfig[];

// isMobileOrTablet = true is a boolean that determines if the route should be rendered only on mobile,
// = false only desktop
// = undefined - both mob/desk
export const routesConfig: RoutesConfig = [
  {
    path: ROUTE_TAB_NAMES.BASE,
    component: <MobileTabContents />,
    isMobileOrTablet: true,
    isSportPage: true,
  },
  {
    path: ROUTE_TAB_NAMES.BETSLIP,
    component: <BetslipMobilePage />,
    isMobileOrTablet: true,
    footer: null,
    header: null,
    isSportPage: true,
  },
  {
    path: ROUTE_TAB_NAMES.BASE,
    component: <SportsPage />,
    isMobileOrTablet: false,
    footer: null,
    isSportPage: true,
  },
  {
    path: ROUTE_TAB_NAMES.INFO,
    component: <InfoPage />,
  },
  {
    path: ROUTE_TAB_NAMES.JACKPOT,
    component: <JackpotPage />,
  },
  {
    path: ROUTE_TAB_NAMES.NOT_FOUND,
    component: <NotFoundPage />,
  },
  {
    path: ROUTE_TAB_NAMES.VERIFY_MESSANGER,
    component: <VerifyMessengerPage />,
  },
  { path: ROUTE_TAB_NAMES.BASE, component: <CasinoPage />, isSportPage: false },
  {
    path: ROUTE_TAB_NAMES.GAMES,
    component: <CasinoPage />,
    isSportPage: false,
  },
  {
    path: ROUTE_TAB_NAMES.VIRTUAL,
    component: <CasinoPage isVirtual />,
    isSportPage: false,
  },
  {
    path: ROUTE_TAB_NAMES.LIVE,
    component: <CasinoPage isLive />,
    isSportPage: false,
  },
  {
    path: ROUTE_TAB_NAMES.GAME,
    component: <GamePage />,
    footer: null,
    isSportPage: false,
  },
];

export const renderRoutes = (isMobileOrTablet: boolean) =>
  routesConfig
    .filter(
      ({ isMobileOrTablet: isMobile }) =>
        isMobile === undefined || isMobile === isMobileOrTablet,
    )
    .filter(
      ({ isSportPage }) => isSportPage === undefined || isSportPage === isSport,
    )
    .map((routeItem) => {
      return (
        <Route
          key={routeItem.path}
          path={routeItem.path}
          element={
            isMobileOrTablet ? (
              <MobilePageTemplate {...routeItem} />
            ) : (
              <DesktopPageTemplate {...routeItem} />
            )
          }
        />
      );
    });
