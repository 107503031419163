import { noDetailsStatuses } from '~components/molecules/LiveMenu/constants';
import { ExtraData } from '~types/sportExtraData';
import {
  breakRegexp,
  halfRegexp,
  periodRegexp,
  quarterRegexp,
  setRegexp,
} from '~utils/regex';

export const getFormattedMatchStatus = (
  status: string,
  localized: (key: string, args?: object) => string,
) => {
  const regexes: Record<string, RegExp> = {
    Break: breakRegexp,
    Period: periodRegexp,
    Half: halfRegexp,
    Set: setRegexp,
    Quarter: quarterRegexp,
  };

  for (const key in regexes) {
    if (regexes[key]!.test(status)) {
      const N = parseInt(status.split('_')[1]!);

      return N > 3
        ? localized(`event.matchStatus.${key}_N`, { N })
        : localized(`event.matchStatus.${key}_${N}`);
    }
  }

  return localized(`event.matchStatus.${status}`)
    .replace('event.matchStatus.', '')
    .replace(/_/g, ' ');
};

export const getMatchStatus = (extraData: ExtraData | null) => {
  return extraData?.MatchStatus || '';
};

export const shouldShowMatchDetail = (extraData: ExtraData | null): boolean => {
  const matchStatus = getMatchStatus(extraData);

  return !noDetailsStatuses.includes(matchStatus);
};
