import proto from '~/proto/messages_pb';
import { getBackendUrl } from '~api/common/baseQuery';

export interface Category {
  name: string;
  code: string | null;
}

export interface Categories {
  [key: string]: Category;
}

export enum ACTION_TYPE {
  PING = proto.ActionType.PING,
  PONG = proto.ActionType.PONG,
  GET_IN_PLAY = proto.ActionType.GETINPLAY,
  GET_UPCOMING = proto.ActionType.GETUPCOMING,
  GET_MAIN_MARKETS = proto.ActionType.GETMAINMARKETS,
  GET_PREMATCH = proto.ActionType.GETPREMATCHMENU,
  GET_TOP_TOURNAMENTS = proto.ActionType.GETTOPTOURNAMENTS,
  GET_TOP_GAMES = proto.ActionType.GETTOPGAMES,
  GET_PREMATCH_EVENT = proto.ActionType.GETPREMATCHEVENT,
  GET_IN_PLAY_EVENT = proto.ActionType.GETINPLAYEVENT,
  GET_COUNTS = proto.ActionType.GETCOUNTS,
  GET_IN_PLAY_EVENTS_BY_MARKET_ID = proto.ActionType.GETINPLAYEVENTSBYMARKETID,
  GET_PREMATCH_EVENTS_BY_LEAGUE = proto.ActionType.GETPREMATCHEVENTS,
  GET_PREMATCH_SUB_MENU = proto.ActionType.GETPREMATCHSUBMENU,
}

const Proto = new proto.UpdateMessage();

import { isDebugMode } from '~utils/url';

export const getActionMessage = (
  type: ACTION_TYPE,
  paramsProp?: Record<string, unknown>,
) => {
  let payload;
  let data;

  const isJson =
    isDebugMode() || import.meta.env.VITE_WEBSOCKET_IS_JSON === 'true';

  if (isJson) {
    const params = typeof paramsProp === 'object' ? paramsProp : {};

    return JSON.stringify({ Type: type, Data: { ...params } });
  }

  switch (type) {
    case ACTION_TYPE.GET_IN_PLAY:
      payload = new proto.LanguageFilter();

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_UPCOMING:
      payload = new proto.GetUpcomingEvents();

      if (paramsProp?.SportId) payload.setSportid(paramsProp.SportId);
      if (paramsProp?.Page) payload.setPage(paramsProp.Page);
      if (paramsProp?.Limit) payload.setLimit(paramsProp.Limit);

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_MAIN_MARKETS:
      payload = new proto.LanguageFilter();

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_PREMATCH:
      payload = new proto.PrematchFilter();

      if (paramsProp?.CountryId) payload.setCountryid(paramsProp.CountryId);
      if (paramsProp?.SportId) payload.setSportid(paramsProp.SportId);
      if (paramsProp?.DateTo) payload.setDateto(paramsProp.DateTo);

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_TOP_TOURNAMENTS:
      payload = new proto.LanguageFilter();

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_TOP_GAMES:
      payload = new proto.LimitFilter();

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_PREMATCH_EVENT:
      payload = new proto.IdFilter();

      if (paramsProp?.Id) payload.setId(paramsProp.Id);

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_IN_PLAY_EVENT:
      payload = new proto.IdFilter();

      if (paramsProp?.Id) payload.setId(paramsProp.Id);

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_COUNTS:
      payload = new proto.GetCountsRequest();

      if (paramsProp?.DateTo) payload.setDateto(paramsProp.DateTo);

      data = payload.serializeBinary();

      break;
    case ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID:
      payload = new proto.GetInPlayEventsByMarketIdRequest();

      if (paramsProp?.Ids) payload.setIdsList(paramsProp.Ids);
      if (paramsProp?.MarketId) payload.setMarketid(paramsProp.MarketId);

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_PREMATCH_EVENTS_BY_LEAGUE:
      payload = new proto.EventPrematchRequest();

      if (paramsProp?.LeagueId) payload.setLeagueid(paramsProp.LeagueId);
      if (paramsProp?.DateTo) payload.setDateto(paramsProp.DateTo);

      data = payload.serializeBinary();
      break;
    case ACTION_TYPE.GET_PREMATCH_SUB_MENU:
      payload = new proto.SubMenuFilter();

      if (paramsProp?.CountryId) payload.setCountryid(paramsProp.CountryId);
      if (paramsProp?.SportId) payload.setSportid(paramsProp.SportId);
      if (paramsProp?.DateTo) payload.setDateto(paramsProp.DateTo);

      data = payload.serializeBinary();
      break;
  }

  Proto.setType(type);
  if (data) Proto.setData(data);

  return Proto.serializeBinary();
};

export const SOCKET_URL = getBackendUrl(
  import.meta.env.VITE_BACKEND_EVENTS_LOADING_WEBSOCKET as string,
);
