import { SportMainMarkets } from '~api/market/types';
import {
  InPlayMenuSports,
  InPlaySportEvents,
  TopInplayEvents,
} from '~api/sportEvent/types';
import { EVENT_STATUS } from '~constants/common';
import { Market, StoreSportEvents } from '~types/events';

export const prepareRespData = (inPlayMenuEvents: InPlayMenuSports) => {
  return inPlayMenuEvents.map((sport) => {
    const { countries = [], id: sportId, name: sportName } = sport;

    const preparedCountries = countries.map((country) => {
      const {
        leagues = [],
        id: countryId,
        name: countryName,
        code: countryCode,
      } = country;

      const preparedLeagues = leagues.map((league) => {
        const {
          events: liveDataEvents = [],
          id: leagueId,
          name: leagueName,
        } = league;

        const leagueEvents = liveDataEvents.map((event) => {
          const { markets = [], ...restEvent } = event;

          return {
            ...restEvent,
            status: EVENT_STATUS.IN_PROGRESS,
            sportId,
            sportName,
            countryId,
            countryName,
            countryCode,
            leagueId,
            leagueName,
            markets,
          };
        });

        return {
          id: leagueId,
          name: leagueName,
          events: leagueEvents,
        };
      });

      return {
        id: countryId,
        name: countryName,
        code: countryCode,
        leagues: preparedLeagues,
      };
    });

    return { id: sportId, name: sportName, countries: preparedCountries };
  });
};

export const prepareTopInplayRespData = (data: TopInplayEvents) => {
  let allEvents: StoreSportEvents = [];

  const allMarkets: Market[] = [];

  data.map((topInplayEvent) => {
    const { sportId, sportName, matches } = topInplayEvent;

    const preparedMatches = matches.map((match) => {
      const { markets = [], ...restMatch } = match;
      const marketIds = markets.map((market) => {
        allMarkets.push(market);

        return market.id;
      });

      return {
        ...restMatch,
        status: EVENT_STATUS.IN_PROGRESS,
        sportId,
        sportName,
        marketIds,
      };
    });

    allEvents = [...allEvents, ...preparedMatches];

    return {
      sportId,
      sportName,
      matches: preparedMatches.map(({ id }) => id),
    };
  });

  return { allEvents, allMarkets };
};

export const reduceEventsByLeague = (
  liveMenu: InPlayMenuSports,
  leagueId: string,
): { events: string[]; sportId: number } => {
  const leagueIdVal = parseInt(leagueId);

  const sportId = liveMenu.find((sport) =>
    sport.countries.some((country) =>
      country.leagues.some((league) => league.id === leagueIdVal),
    ),
  )?.id;

  const events = liveMenu.flatMap((sport) =>
    sport.countries.flatMap((country) =>
      country.leagues
        .filter((league) => league.id === leagueIdVal)
        .flatMap((league) => league.events),
    ),
  );

  return {
    events: events.map(({ id }) => id as string) || [],
    sportId: sportId || 0,
  };
};

export const reduceEventsBySport = ({
  liveMenu,
  openedCountryLeagues,
  sportId,
}: {
  liveMenu: InPlayMenuSports;
  openedCountryLeagues: string[];
  sportId: number;
}): string[] => {
  let resEvents: InPlaySportEvents = [];

  const countries = liveMenu.find((sport) => sport.id === sportId)?.countries;

  if (countries && countries.length) {
    countries.forEach((country) => {
      country.leagues.forEach((league) => {
        if (openedCountryLeagues.includes(league.id.toString())) {
          resEvents = [...resEvents, ...league.events];
        }
      });
    });
  }

  return resEvents.map(({ id }) => id as string);
};

export const getMainMarketId = ({
  mainMarkets,
  sportId,
}: {
  mainMarkets: SportMainMarkets;
  sportId: number;
}): number => {
  const sportMainMarkets = mainMarkets.find(
    (sportMainMarkets) => sportMainMarkets.id === sportId,
  );

  return sportMainMarkets?.markets[0]?.id || 0;
};

export const reduceOpenedSportEventsBySport = ({
  liveMenu,
  sportId,
  openedCountryLeagues,
}: {
  liveMenu: InPlayMenuSports;
  sportId: number;
  openedCountryLeagues: string[];
}): string[] => {
  const sportCountryEvents =
    liveMenu.find((sport) => sport.id === sportId)?.countries || null;

  if (!sportCountryEvents) return [];
  const openedCountryLeaguesNumberArray = openedCountryLeagues.map((leagueId) =>
    parseInt(leagueId),
  );

  let resEventIds: InPlaySportEvents = [];

  sportCountryEvents.forEach((country) => {
    country.leagues.forEach((league) => {
      if (openedCountryLeaguesNumberArray.includes(league.id)) {
        resEventIds = [...resEventIds, ...league.events];
      }
    });
  });

  return resEventIds.map(({ id }) => id as string);
};
