import React, { memo } from 'react';

import { SportEventItem } from '~api/sportEvent/types';
import { ArrowOddIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { SWIPE_COMPONENT_TYPES } from '~components/atoms/SwipeComponent';
import { Text } from '~components/atoms/Typography';
import { MARKET_STATUS, SELECTION_STATUS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { LockIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { addStake, removeStakeBySelection } from '~store/slices/betslipSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
import { Selection, SportEvent } from '~types/events';
import { isQuickBet } from '~utils/isQuickBet';

interface SelectionItemProps {
  selection: Selection;
  status: SELECTION_STATUS;
  eventId: string;
  marketId: string;
  eventData: SportEvent;
}

export const SelectionItem = memo(
  ({ selection, eventId, marketId, status, eventData }: SelectionItemProps) => {
    const dispatch = useAppDispatch();
    const { isMobileOrTablet } = useMedia();
    const betslipEvents = useAppSelector((state) => state.betslip.events);
    const { quickBet } = useAppSelector((state) => state.settings);
    const selectionId = selection.id;
    const isSelectedItem = betslipEvents.some(
      (event) => event.selectionId === selectionId,
    );
    const eventStatus = eventData?.status;
    const market = eventData?.markets?.find((market) => market.id === marketId);
    const marketStatus = market?.status;

    const isStatusOpen =
      status === SELECTION_STATUS.OPEN &&
      marketStatus !== MARKET_STATUS.SUSPENDED;

    const handleBetClick = () => {
      if (selectionId) {
        if (!isSelectedItem) {
          if (
            betslipEvents.length === 0 &&
            eventStatus &&
            isQuickBet(eventStatus, quickBet)
          ) {
            dispatch(setIsSwipeOpen(SWIPE_COMPONENT_TYPES.QUICK_BET));
          }

          dispatch(
            addStake({
              eventId,
              marketId,
              selectionId,
              eventData: eventData as SportEventItem,
            }),
          );
        } else {
          dispatch(removeStakeBySelection(selectionId));
        }
      }
    };

    return (
      <Box
        flexRow
        fullWidth
        alignCenter
        justifyContentBetween
        onClick={handleBetClick}
        gap={2}
        css={{
          background: '$gray',
          p: '$4 $3',
          '@xs': {
            p: '6px $2',
          },
          '@sm': {
            p: '6px $2',
          },
          cursor: isStatusOpen ? 'pointer' : 'default',
          pointerEvents: isStatusOpen ? 'auto' : 'none',
          '&[data-state=on]': {
            backgroundColor: '$oddBgColorActive',
            color: '$oddTextColorActive',
            '*': {
              color: '$oddTextColorActive',
            },
          },
          '&[data-state=off]:hover': {
            backgroundColor: '$oddBgColorHover',
          },
        }}
        data-state={isSelectedItem ? 'on' : 'off'}
      >
        <Text
          textTransform="capitalize"
          level={isMobileOrTablet ? '12-16' : '16-24'}
          color="grayMedium"
        >
          {selection.name}
        </Text>
        {isStatusOpen ? (
          <ArrowOddIndicator odd={selection.odd} />
        ) : (
          <Box flexRow css={{ color: '$white' }}>
            <LockIcon width={12} height={16} />
          </Box>
        )}
      </Box>
    );
  },
);
