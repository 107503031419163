import { useEffect, useState } from 'react';

import { Tournaments } from '~api/sportEvent/types';
import { SPORT_GROUP_COMPONENTS } from '~components/molecules/PrematchMenu/constants';
import { useInitializeBreadcrumbForSport } from '~hooks/useInitializeBreadcrumbsForSport';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';

import { useLeagueEventsMenu } from './useLeagueEventsMenu';

export const useLeagueEventsMenuMobile = () => {
  const { sportId, countryId } = useQueryParams();
  const [showLeagues, setShowLeagues] = useState(false);
  const { sports } = useAppSelector((state) => state.prematchMenu);
  const countries = sports?.find(
    (sport) => sport.id === parseInt(sportId!),
  )?.countries;

  const { favoriteTournaments, topTournaments } = useAppSelector(
    (state) => state.sportGroupsState,
  );
  const { breadcrumbLabel } = useAppSelector((state) => state.menus);
  const country = countries?.find(
    (country) => country.id === parseInt(countryId!),
  );
  const leagues = country?.leagues;
  const { initializeBreadcrumb } = useInitializeBreadcrumbForSport(
    parseInt(sportId!),
    country?.name,
  );
  const {
    openItems,
    setOpenItems,
    shouldRender,
    leagueName,
    countryName,
    leagueEventsData,
    mainMarkets,
    isLoading,
    leagueId,
  } = useLeagueEventsMenu();

  const { events, sportName } =
    leagues?.find((league) => league.id === leagueId) || {};

  useEffect(() => {
    initializeBreadcrumb(sportId, countryName);
  }, [sportId, countryName, mainMarkets, leagueName]);

  const leaguesDataMap: Record<SPORT_GROUP_COMPONENTS, Tournaments | null> = {
    [SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT]: topTournaments,
    [SPORT_GROUP_COMPONENTS.TOP_EVENT]: null,
    [SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT]: favoriteTournaments,
    [SPORT_GROUP_COMPONENTS.FAVORITE_EVENT]: null,
  };

  return {
    leagueId,
    country,
    countryId,
    isLoading,
    leagueEventsData,
    leagueName,
    leagues:
      leaguesDataMap[breadcrumbLabel as SPORT_GROUP_COMPONENTS] || leagues,
    mainMarkets,
    openItems,
    shouldRender,
    showLeagues,
    sportId,
    events,
    sportName,
    countryName,
    setOpenItems,
    setShowLeagues,
  };
};
