import React, { useMemo, useState } from 'react';

import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { DeactivateAccount } from '~components/molecules/UserProfile/components/DeactivateAccount/DeactivateAccount';
import { useTranslation } from '~hooks/useTranslation';

import { ChangePasswordForm } from '../ChangePasswordForm';

export enum ACCOUNT_SETTINGS_MOBILE_TABS {
  PASSWORD = 'password',
  DEACTIVATE_ACCOUNT = 'deactivateAccount',
}

interface AccountSettingsToggle {
  value: ACCOUNT_SETTINGS_MOBILE_TABS;
  label: string;
}

export const AccountSettingsContentMobile = () => {
  const { localized } = useTranslation();

  const [activeTab, setActiveTab] = useState(
    ACCOUNT_SETTINGS_MOBILE_TABS.PASSWORD,
  );
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);

  const onTogglePasswordChanged = (val: boolean) => {
    setIsPasswordChanged(val);
  };

  const componentsMap = {
    [ACCOUNT_SETTINGS_MOBILE_TABS.PASSWORD]: (
      <ChangePasswordForm
        onTogglePasswordChanged={onTogglePasswordChanged}
        isPasswordChanged={isPasswordChanged}
      />
    ),
    [ACCOUNT_SETTINGS_MOBILE_TABS.DEACTIVATE_ACCOUNT]: <DeactivateAccount />,
  };

  const accountSettingsToggles: AccountSettingsToggle[] = useMemo(
    () => [
      {
        value: ACCOUNT_SETTINGS_MOBILE_TABS.PASSWORD,
        label: localized(
          'userProfile.personalProfileInfo.accountSettings.changePassword.mobile.toggle',
        ),
      },
      {
        value: ACCOUNT_SETTINGS_MOBILE_TABS.DEACTIVATE_ACCOUNT,
        label: localized(
          'userProfile.personalProfileInfo.accountSettings.deactivateAccount.mobile.toggle',
        ),
      },
    ],
    [],
  );

  const handleValueChange = (newActiveTab: string) => {
    if (!newActiveTab) {
      return;
    }

    setActiveTab(newActiveTab as ACCOUNT_SETTINGS_MOBILE_TABS);
  };

  return (
    <>
      <ToggleGroup
        type="single"
        variant="userProfileMobileTab"
        onValueChange={handleValueChange}
        value={activeTab}
        rovingFocus={false}
        loop={false}
        css={{ mb: '$4' }}
      >
        {accountSettingsToggles.map(({ value, label }) => (
          <ToggleGroupItem
            key={value}
            type="userProfileMobileTab"
            value={value}
          >
            <Text as="span">{label}</Text>
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
      {componentsMap[activeTab]}
    </>
  );
};
