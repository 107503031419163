import React from 'react';

import { Box } from '~components/atoms/Box';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { Text } from '~components/atoms/Typography';
import { StyledJackpotListWrapper } from '~components/molecules/Jackpot/Menu/styled.components';
import { SportSliderItem } from '~components/molecules/PrematchMenu/SportSliderItem';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { JackpotIcon, ResultsIcon, RulesIcon } from '~icons/menu';
import { useAppDispatch, useAppSelector } from '~store';
import { clearJackpotMessages } from '~store/slices/jackpotSlice';
import { SVGElement } from '~types/general';
import { QueryParams } from '~utils/url';

export enum JACKPOT_MENU_ITEMS {
  ACTIVE_JACKPOTS = 'jackpot',
  SETTLED_JACKPOTS = 'previousResults',
  RULES = 'rules',
}

interface MenuItem {
  name: string;
  icon: SVGElement;
  value: JACKPOT_MENU_ITEMS;
}

export const Menu = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { menu } = useQueryParams();
  const { updateQueryParams } = useRouterQuery();
  const { settledJackpots, activeJackpots } = useAppSelector(
    (state) => state.jackpot,
  );

  const handleActiveJackpotPageChange = (menu: JACKPOT_MENU_ITEMS) => {
    dispatch(clearJackpotMessages());
    updateQueryParams(
      {
        menu,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );
  };

  const menuItems: MenuItem[] = [
    {
      name: localized('jackpot.jackpot'),
      icon: JackpotIcon,
      value: JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS,
    },
    {
      name: localized('jackpot.previousResults'),
      icon: ResultsIcon,
      value: JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS,
    },
    {
      name: localized('jackpot.rules'),
      icon: RulesIcon,
      value: JACKPOT_MENU_ITEMS.RULES,
    },
  ];

  const filteredMobileMenu = menuItems.filter(({ value }) => {
    if (value === JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS) {
      return activeJackpots.length > 0;
    }

    if (value === JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS) {
      return settledJackpots.length > 0;
    }

    return true;
  });

  if (isMobileOrTablet) {
    return (
      <TouchSlider type="sportGroup" fullWidth>
        {filteredMobileMenu.map(({ name, icon, value }) => {
          return (
            <SportSliderItem
              key={name}
              sport={name}
              icon={icon}
              className={value === menu ? 'active' : ''}
              onClick={() => handleActiveJackpotPageChange(value)}
            />
          );
        })}
      </TouchSlider>
    );
  }

  const desktopFilteredMenuItems = menuItems.filter(({ value }) => {
    const isActiveJackpotMenu = value === JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS;
    const isSettledJackpotsEmpty =
      value === JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS &&
      settledJackpots.length === 0;

    return !(isActiveJackpotMenu || isSettledJackpotsEmpty);
  });

  return (
    <StyledJackpotListWrapper>
      {desktopFilteredMenuItems.map(({ name, icon: Icon, value }, index) => {
        const isNotLast = index !== desktopFilteredMenuItems.length - 1;
        const isActive = menu === value;

        return (
          <Box
            key={index}
            flexRow
            alignCenter
            css={{
              borderBottom: isNotLast ? '1px solid $grayDark' : 'none',
              p: '$1',
            }}
          >
            <Box
              flexRow
              fullWidth
              alignCenter
              gap={2}
              onClick={() => handleActiveJackpotPageChange(value)}
              css={{
                cursor: 'pointer',
                border: isActive ? '1px solid $green' : '1px solid transparent',
                p: '$2 $3',
                borderRadius: '$6',
                color: '$grayMedium',
                backgroundColor: isActive ? '$grayDark' : 'transparent',
                '& > svg': {
                  width: '20px !important',
                  height: '20px !important',
                },
              }}
            >
              <Icon />
              <Text level="14-24">{name}</Text>
            </Box>
          </Box>
        );
      })}
    </StyledJackpotListWrapper>
  );
};
