import { memo } from 'react';

import { SportListItems } from '~components/molecules/LiveMenu/types';

import { Box } from './Box';
import { SportMenuItem } from './SportMenuItem';

interface SportMenuListProps {
  sports: SportListItems;
  activeSportIdIndex: number;
  onClick?: (index: number) => void;
}

export const SportMenuList = memo(
  ({ sports, activeSportIdIndex, onClick }: SportMenuListProps) => {
    const onSportClick = (index: number) => {
      if (onClick) {
        onClick(index);
      }
    };

    return (
      <Box flexCol gap={1}>
        {sports.map(({ sportId, sportName, eventsAmount }, index) => (
          <SportMenuItem
            key={sportId}
            sportName={sportName}
            eventsAmount={eventsAmount}
            isActive={index === activeSportIdIndex}
            handleOnClick={() => onSportClick(index)}
          />
        ))}
      </Box>
    );
  },
);
