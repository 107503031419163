import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';

export const StyledTopSectionWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$4',
  width: '100%',
  backgroundColor: '$grayDark',
  padding: '24px',
  borderRadius: '$12',
  variants: {
    inviteFriendsPage: {
      true: {
        backgroundColor: '$grayMain',
      },
    },
  },
});

export const StyledTitleText = styled(Text, {
  textTransform: 'uppercase',
  textAlign: 'center !important',
  fontSize: '16px',
  lineHeight: '28px',
  '@lg_xl': {
    fontSize: '18px',
    lineHeight: '24px',
  },
  '@xs': {
    fontSize: '14px',
    lineHeight: '20px',
  },
});

export const StyledSupportText = styled(Text, {
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center !important',
  '@xs': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

export const StyledWorkingHoursText = styled(Text, {
  color: '$grayMedium !important',
  fontSize: '14px',
  lineHeight: '20px',
  '@lg_xl': {
    fontSize: '16px',
    lineHeight: '24px',
  },
  '@xs': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

export const StyledInviteFriendsButton = styled(Button, {
  width: '100%',
  fontSize: '$18 !important',
  lineHeight: '$24 !important',
  padding: '$2 !important',
  '@xs': {
    fontSize: '$16 !important',
    lineHeight: '$20 !important',
    maxWidth: '304px',
  },
});
