import React, { useMemo, useState } from 'react';

import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { AccountVerificationContentMobile } from '../PersonalDetailsContent/components/AccountVerificationContentMobile';
import { PersonalDetailsForm } from '../PersonalDetailsForm';

export enum PERSONAL_DETAILS_MOBILE_TABS {
  DETAILS = 'details',
  VERIFICATION = 'verification',
}

interface UserProfileToggle {
  value: PERSONAL_DETAILS_MOBILE_TABS;
  label: string;
}

export const PersonalDetailsContentMobile = () => {
  const { localized } = useTranslation();

  const [activeTab, setActiveTab] = useState(
    PERSONAL_DETAILS_MOBILE_TABS.DETAILS,
  );
  const componentsMap = {
    [PERSONAL_DETAILS_MOBILE_TABS.DETAILS]: <PersonalDetailsForm />,
    [PERSONAL_DETAILS_MOBILE_TABS.VERIFICATION]: (
      <AccountVerificationContentMobile />
    ),
  };

  const userProfileToggles: UserProfileToggle[] = useMemo(
    () => [
      {
        value: PERSONAL_DETAILS_MOBILE_TABS.DETAILS,
        label: localized(
          'userProfile.personalProfileInfo.accountDetails.mobile.toggle',
        ),
      },
      {
        value: PERSONAL_DETAILS_MOBILE_TABS.VERIFICATION,
        label: localized(
          'userProfile.personalProfileInfo.accountVerification.mobile.toggle',
        ),
      },
    ],
    [],
  );

  const handleValueChange = (newActiveTab: string) => {
    if (!newActiveTab) {
      return;
    }

    setActiveTab(newActiveTab as PERSONAL_DETAILS_MOBILE_TABS);
  };

  return (
    <>
      <ToggleGroup
        type="single"
        variant="userProfileMobileTab"
        onValueChange={handleValueChange}
        value={activeTab}
        rovingFocus={false}
        loop={false}
        css={{ mb: '$4' }}
      >
        {userProfileToggles.map(({ value, label }) => (
          <ToggleGroupItem
            key={value}
            type="userProfileMobileTab"
            value={value}
          >
            <Text as="span">{label}</Text>
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
      {componentsMap[activeTab]}
    </>
  );
};
