import { useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { useAppSelector } from '~store';

import { SportListItems } from '../../types';

export const useLiveTopEventsMobile = () => {
  const { topLiveEvents } = useAppSelector((state) => state.liveGroupsState);
  const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);
  const [mainSportMarket, setMainSportMarket] = useState<
    MainMarket | undefined
  >(undefined);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const [selectedSportIndex, setSelectedSportIndex] = useState(0);
  const [showSports, setShowSports] = useState(false);

  const sports: SportListItems = topLiveEvents.map(
    ({ sportName, sportId, matches }) => ({
      sportName,
      sportId,
      eventsAmount: matches?.length || 0,
    }),
  );

  const { matches, sportId, sportName } =
    topLiveEvents[selectedSportIndex] || {};

  const handleSportMenuClick = (index: number) => {
    setShowSports(false);
    setSelectedSportIndex(index);
  };

  useEffect(() => {
    const mainSportMarketId = mainMarketsSelected[sportId!];
    const { markets = [] } =
      mainMarkets.find((item) => item.id === sportId) || {};

    if (markets.length) {
      const mainSportMarket = markets.find(
        (item) => item.id === mainSportMarketId,
      );

      setMainSportMarket(mainSportMarket!);
    }
  }, [mainMarketsSelected, mainMarkets, sportId]);

  return {
    sports,
    matches,
    sportName,
    mainSportMarket,
    showSports,
    selectedSportIndex,
    sportId,
    handleSportMenuClick,
    setShowSports,
  };
};
