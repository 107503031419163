import { useEffect } from 'react';

import { useGetLikedGames, useGetPopularGames } from '~api/games/gamesQueries';
import { useAppDispatch, useAppSelector } from '~store';
import { setLikedGames, setPopular } from '~store/slices/gamesSlice';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';
import isSportWebsite from '~utils/isSportWebsite';

export const useCasinoDataLoad = () => {
  const isSport = isSportWebsite();
  const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const { getPopularGamesQuery } = useGetPopularGames();
  const { getLikedGamesQuery } = useGetLikedGames();

  const loadMostLikedGames = async () => {
    try {
      const data = await getPopularGamesQuery().unwrap();

      dispatch(setPopular(data));
    } catch (e) {
      console.log(e);
    }
  };

  const loadLikedGames = async () => {
    try {
      const data = await getLikedGamesQuery().unwrap();

      dispatch(setLikedGames(data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isSport) return;
    loadMostLikedGames();
  }, []);

  useEffect(() => {
    if (isSport) return;
    if (isUserLoggedIn) {
      loadLikedGames();
    }
  }, [isUserLoggedIn]);
};

export default useCasinoDataLoad;
