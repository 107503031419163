import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useTwoTeamScore } from '~hooks/useTwoTeamScore';
import { StoreSportEvent } from '~types/events';
import { ExtraData } from '~types/sportExtraData';
import { MATCH_STATUS_NOT_STARTED } from '~types/sportExtraData/generalExtraDataTypes';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import {
  getFormattedMatchStatus,
  getMatchStatus,
} from '~utils/matchStatusHelpers';
import { splitStringOnVs } from '~utils/stringUtils';

export const useSportScoreboard = (
  restEventData: Omit<StoreSportEvent, 'extraData'>,
  parsedExtraData: ExtraData | null,
) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { name, sportName, countryName, leagueName, startDate } = restEventData;
  const matchStatus = getMatchStatus(parsedExtraData);
  const formattedMatchStatus = getFormattedMatchStatus(matchStatus, localized);
  const { homeTeamScore, awayTeamScore } = useTwoTeamScore(parsedExtraData);
  const [homeTeamName, awayTeamName] = splitStringOnVs(name);
  const isMatchStarted = matchStatus !== MATCH_STATUS_NOT_STARTED;
  const formattedStartDate =
    startDate && formatDateTime(startDate, TIME_FORMATS.DATE_TIME_24H);

  return {
    sportName,
    countryName,
    leagueName,
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    isMatchStarted,
    formattedStartDate,
    isMobileOrTablet,
    localized,
  };
};
