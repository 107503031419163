import * as SportImages from '~assets/sportBackgrounds';
import * as SportIcons from '~icons/sports';
import { SVGElement } from '~types/general';

export enum SPORTS {
  E_SPORT_HALO = 'ESport Halo',
  RINK_HOCKEY = 'Rink Hockey',
  E_SPORT_ARTIFACT = 'ESport Artifact',
  MOTOGP = 'MotoGP',
  BEACH_TENNIS = 'Beach Tennis',
  AUSSIE_RULES = 'Aussie Rules',
  E_CRICKET = 'eCricket',
  ENDURANCE_RACING = 'Endurance Racing',
  E_SOCCER = 'eSoccer',
  MOTORSPORT = 'Motorsport',
  KABADDI = 'Kabaddi',
  SNOOKER = 'Snooker',
  E_SPORT_ROCKET_LEAGUE = 'ESport Rocket League',
  KARATE = 'Karate',
  FREESTYLE = 'Freestyle',
  BADMINTON = 'Badminton',
  PESAPALLO = 'Pesapallo',
  ARCHERY = 'Archery',
  NORDIC_COMBINED = 'Nordic Combined',
  E_SPORT_TEKKEN = 'ESport Tekken',
  NASCAR_XFINITY_SERIES = 'Nascar Xfinity Series',
  BIATHLON = 'Biathlon',
  BASQUE_PELOTA = 'Basque Pelota',
  E_SPORT_PLAYERUNKNOWNS_BATTLEGROUNDS = 'ESport PlayerUnknowns Battlegrounds',
  AMERICAN_FOOTBALL = 'American Football',
  E_SPORT_DRAGON_BALL_FIGHTERZ = 'ESport Dragon Ball FighterZ',
  MOUNTAIN_BIKE = 'Mountain Bike',
  SUMO = 'Sumo',
  CYCLING_BMX_RACING = 'Cycling BMX Racing',
  FREESTYLE_SKIING = 'Freestyle Skiing',
  NHRA = 'NHRA',
  E_SPORT_ARENA_OF_VALOR = 'ESport Arena of Valor',
  CANOE_SLALOM = 'Canoe slalom',
  POOL = 'Pool',
  CURLING = 'Curling',
  E_SPORT_MOTORSPORT = 'ESport Motorsport',
  ROWING = 'Rowing',
  E_SPORT_WORLD_OF_WARCRAFT = 'ESport World of Warcraft',
  E_SPORT_WORLD_OF_TANKS = 'ESport World of Tanks',
  SAILING = 'Sailing',
  SPEED_SKATING = 'Speed Skating',
  E_SPORT_KING_OF_GLORY = 'ESport King of Glory',
  INLINE_HOCKEY = 'Inline Hockey',
  MOTO2 = 'Moto2',
  TRIATHLON = 'Triathlon',
  SQUASH = 'Squash',
  BOXING = 'Boxing',
  E_SPORT_AUTO_CHESS = 'Esport Auto Chess',
  TABLE_TENNIS = 'Table Tennis',
  E_SPORT_OVERWATCH = 'ESport Overwatch',
  E_SPORT_CROSSFIRE = 'ESport Crossfire',
  EQUESTRIAN = 'Equestrian',
  E_ICE_HOCKEY = 'eIce Hockey',
  SNOWBOARDCROSS_PARALLEL = 'Snowboardcross/Parallel',
  WRESTLING = 'Wrestling',
  E_SPORT_PRO_EVOLUTION_SOCCER = 'ESport Pro Evolution Soccer',
  E_SPORT_GEARS_OF_WAR = 'ESport Gears of War',
  CYCLING = 'Cycling',
  WATERPOLO = 'Waterpolo',
  CYCLING_BMX_FREESTYLE = 'Cycling BMX Freestyle',
  E_SPORT_VAINGLORY = 'ESport Vainglory',
  E_SPORT_CLASH_ROYALE = 'ESport Clash Royale',
  TROTTING = 'Trotting',
  E_SPORT_SMITE = 'ESport Smite',
  FENCING = 'Fencing',
  E_SPORT_QUAKE = 'ESport Quake',
  CROSS_COUNTRY = 'Cross-Country',
  E_SPORT_WILD_RIFT = 'ESport Wild Rift',
  E_SPORT_COUNTER_STRIKE = 'ESport Counter-Strike',
  SEPAK_TAKRAW = 'Sepak Takraw',
  PADEL = 'Padel',
  E_SPORT_RAINBOW_SIX = 'ESport Rainbow Six',
  SWIMMING = 'Swimming',
  T_BASKET = 'T-Basket',
  GOLF = 'Golf',
  CYCLING_CYCLE_BALL = 'Cycling Cycle Ball',
  STREETHOCKEY = 'Streethockey',
  CRICKET = 'Cricket',
  AIR_RACING = 'Air Racing',
  FISHING = 'Fishing',
  BOBSLEIGH = 'Bobsleigh',
  E_VOLLEYBALL = 'eVolleyball',
  HANDBALL = 'Handball',
  E_BASKETBALL = 'eBasketball',
  SKI_JUMPING = 'Ski Jumping',
  SPEEDWAY = 'Speedway',
  LUGE = 'Luge',
  ARTISTIC_SWIMMING = 'Artistic Swimming',
  FORMULA_E = 'Formula E',
  E_SPORT_DOTA = 'ESport Dota',
  TOURING_CAR_RACING = 'Touring Car Racing',
  SUPERBIKE = 'Superbike',
  VOLLEYBALL = 'Volleyball',
  BEACH_HANDBALL = 'Beach Handball',
  GAELIC_HURLING = 'Gaelic Hurling',
  CHESS = 'Chess',
  E_SPORT_HEARTHSTONE = 'ESport Hearthstone',
  E_SPORT_FORTNITE = 'ESport Fortnite',
  FLOORBALL = 'Floorball',
  MOTORCYCLE_RACING = 'Motorcycle Racing',
  BASKETBALL = 'Basketball',
  E_SPORT_CALL_OF_DUTY = 'ESport Call of Duty',
  OFF_ROAD = 'Off Road',
  MOTO3 = 'Moto3',
  INDY_RACING = 'Indy Racing',
  SKATEBOARDING = 'Skateboarding',
  E_SPORT_WARCRAFT_III = 'ESport WarCraft III',
  E_SPORT_PALADINS = 'ESport Paladins',
  E_SPORT_VALORANT = 'ESport Valorant',
  TRACK_CYCLING = 'Track cycling',
  TRUCK_AND_TRACTOR_PULLING = 'Truck & Tractor Pulling',
  E_SPORT_TF2 = 'ESport TF2',
  SPORT_CLIMBING = 'Sport Climbing',
  SNOWBOARD = 'Snowboard',
  SCHWINGEN = 'Schwingen',
  NASCAR_CAMPING_WORLD_TRUCK = 'Nascar Camping World Truck',
  BANDY = 'Bandy',
  DTM = 'DTM',
  E_SPORT_DOTA_UNDERLORDS = 'Esport Dota Underlords',
  E_SPORT_STREET_FIGHTER_V = 'ESport Street Fighter V',
  SHOOTING = 'Shooting',
  WINTER_SPORTS = 'Winter Sports',
  GYMNASTICS = 'Gymnastics',
  E_SPORT_LEAGUE_OF_LEGENDS = 'ESport League of Legends',
  E_SPORT_MADDEN_NFL = 'ESport Madden NFL',
  SPEED_BOAT_RACING = 'Speed Boat Racing',
  SOCCER = 'Soccer',
  BIKES = 'Bikes',
  ATHLETICS = 'Athletics',
  TRAMPOLINE_GYMNASTICS = 'Trampoline Gymnastics',
  GAELIC_SPORTS = 'Gaelic sports',
  STOCK_CAR_RACING = 'Stock Car Racing',
  RUGBY = 'Rugby',
  BEACH_VOLLEY = 'Beach Volley',
  BASKETBALL_3X3 = 'Basketball 3x3',
  SURFING = 'Surfing',
  RALLY = 'Rally',
  NASCAR_CUP_SERIES = 'Nascar Cup Series',
  DARTS = 'Darts',
  MMA = 'MMA',
  FIELD_HOCKEY = 'Field hockey',
  MARATHON_SWIMMING = 'Marathon Swimming',
  WORLD_CHAMPIONSHIP = 'World Championship',
  E_SPORT_SSBM = 'ESport SSBM',
  JUDO = 'Judo',
  AQUATICS = 'Aquatics',
  DIVING = 'Diving',
  OLYMPICS = 'Olympics',
  WORLD_LOTTERY = 'World Lottery',
  FORMULA_1 = 'Formula 1',
  E_SPORT_BRAWL_STARS = 'ESport Brawl Stars',
  RIDING = 'Riding',
  WEIGHTLIFTING = 'Weightlifting',
  FINNISH_BASEBALL = 'Finnish Baseball',
  RUGBY_LEAGUE = 'Rugby League',
  NETBALL = 'Netball',
  HORSE_RACING = 'Horse racing',
  E_SPORT_APEX_LEGENDS = 'ESport Apex Legends',
  E_SPORT = 'eSport',
  MODIFIED_RACING = 'Modified Racing',
  MOTOCROSS = 'Motocross',
  LACROSSE = 'Lacrosse',
  SHORT_TRACK = 'Short Track',
  HORSEBALL = 'Horseball',
  E_SPORT_HEROES_OF_THE_STORM = 'ESport Heroes of the Storm',
  SOCCER_MYTHICAL = 'Soccer Mythical',
  BEACH_SOCCER = 'Beach Soccer',
  INDOOR_SOCCER = 'Indoor Soccer',
  BOWLS = 'Bowls',
  ICE_HOCKEY = 'Ice Hockey',
  DEPRECATED_SC = 'DEPRECATED sc',
  SPRINT_CAR_RACING = 'Sprint Car Racing',
  BASEBALL = 'Baseball',
  E_SPORT_FIGHTING_GAMES = 'Esport Fighting Games',
  CANOEING = 'Canoeing',
  SEVEN_BALLRUN = '7BallRun',
  SPECIALS = 'Specials',
  INDY_LIGHTS = 'Indy Lights',
  FORMULA_2 = 'Formula 2',
  MODERN_PENTATHLON = 'Modern Pentathlon',
  E_SPORT_STARCRAFT = 'ESport StarCraft',
  TENNIS = 'Tennis',
  E_SPORT_TEAMFIGHT_TACTICS = 'Esport Teamfight Tactics',
  BMX_RACING = 'BMX racing',
  E_TENNIS = 'eTennis',
  E_SPORT_MTG = 'ESport MTG',
  OLYMPICS_YOUTH = 'Olympics Youth',
  SOFTBALL = 'Softball',
  GAELIC_FOOTBALL = 'Gaelic Football',
  POLO = 'Polo',
  RHYTHMIC_GYMNASTICS = 'Rhythmic gymnastics',
  FUTSAL = 'Futsal',
  PETANQUE = 'Petanque',
  TAEKWONDO = 'Taekwondo',
  DRAG_RACING = 'Drag Racing',
  FIGURE_SKATING = 'Figure Skating',
  SKELETON = 'Skeleton',
  ALPINE_SKIING = 'Alpine Skiing',
  GREYHOUND = 'Greyhound',
  RACQUETBALL = 'Racquetball',
  MUAY_THAI = 'Muay Thai',
  CORNHOLE = 'Cornhole',
  BREAKING = 'Breaking',
  SOCCER_SPECIALS = 'Soccer Specials',
}

export const sportIconMap: Record<SPORTS, SVGElement | null> = {
  [SPORTS.BASKETBALL]: SportIcons.BasketballIcon,
  [SPORTS.HANDBALL]: SportIcons.HandballIcon,
  [SPORTS.SOCCER]: SportIcons.SoccerIcon,
  [SPORTS.TENNIS]: SportIcons.TennisIcon,
  [SPORTS.ALPINE_SKIING]: SportIcons.AlpineIcon,
  [SPORTS.AMERICAN_FOOTBALL]: SportIcons.AmericanFootballIcon,
  [SPORTS.AUSSIE_RULES]: SportIcons.AussieRulesIcon,
  [SPORTS.BADMINTON]: SportIcons.BadmintonIcon,
  [SPORTS.BASEBALL]: SportIcons.BaseballIcon,
  [SPORTS.BASKETBALL_3X3]: SportIcons.Basketball3x3Icon,
  [SPORTS.BIATHLON]: SportIcons.BiathlonIcon,
  [SPORTS.BOWLS]: SportIcons.BowlsIcon,
  [SPORTS.BOXING]: SportIcons.BoxingIcon,
  [SPORTS.CRICKET]: SportIcons.CricketIcon,
  [SPORTS.CYCLING]: SportIcons.CyclingIcon,
  [SPORTS.DARTS]: SportIcons.DartsIcon,
  [SPORTS.FUTSAL]: SportIcons.FutsalIcon,
  [SPORTS.GAELIC_FOOTBALL]: SportIcons.GaelicFootballIcon,
  [SPORTS.ICE_HOCKEY]: SportIcons.IceHockeyIcon,
  [SPORTS.MMA]: SportIcons.MmaIcon,
  [SPORTS.MOTORSPORT]: SportIcons.MotorSports,
  // [SPORTS.OUTRIGHT]: SportIcons.OutrightIcon,
  [SPORTS.PESAPALLO]: SportIcons.Pesapallo,
  // [SPORTS.PLAYERS_SOCCER]: SportIcons.PlayersSoccerIcon,
  [SPORTS.RUGBY]: SportIcons.RugbyIcon,
  [SPORTS.SKI_JUMPING]: SportIcons.SkiJumpingIcon,
  [SPORTS.SNOOKER]: SportIcons.SnookerIcon,
  [SPORTS.SPECIALS]: SportIcons.SpecialsIcon,
  [SPORTS.TABLE_TENNIS]: SportIcons.TableTennisIcon,
  [SPORTS.VOLLEYBALL]: SportIcons.VolleyBallIcon,
  [SPORTS.WATERPOLO]: SportIcons.WaterpoloIcon,
  [SPORTS.E_BASKETBALL]: SportIcons.BasketballIcon,
  [SPORTS.E_SOCCER]: SportIcons.SoccerIcon,
  /*TODO: for below sports we do not have icons, showing soccer icon for now*/
  [SPORTS.E_SPORT_HALO]: null,
  [SPORTS.RINK_HOCKEY]: null,
  [SPORTS.E_SPORT_ARTIFACT]: null,
  [SPORTS.MOTOGP]: null,
  [SPORTS.BEACH_TENNIS]: null,
  [SPORTS.E_CRICKET]: null,
  [SPORTS.ENDURANCE_RACING]: null,
  [SPORTS.KABADDI]: null,
  [SPORTS.E_SPORT_ROCKET_LEAGUE]: null,
  [SPORTS.KARATE]: null,
  [SPORTS.FREESTYLE]: null,
  [SPORTS.ARCHERY]: null,
  [SPORTS.NORDIC_COMBINED]: null,
  [SPORTS.E_SPORT_TEKKEN]: null,
  [SPORTS.NASCAR_XFINITY_SERIES]: null,
  [SPORTS.BASQUE_PELOTA]: null,
  [SPORTS.E_SPORT_PLAYERUNKNOWNS_BATTLEGROUNDS]: null,
  [SPORTS.E_SPORT_DRAGON_BALL_FIGHTERZ]: null,
  [SPORTS.MOUNTAIN_BIKE]: null,
  [SPORTS.SUMO]: null,
  [SPORTS.CYCLING_BMX_RACING]: null,
  [SPORTS.FREESTYLE_SKIING]: null,
  [SPORTS.NHRA]: null,
  [SPORTS.E_SPORT_ARENA_OF_VALOR]: null,
  [SPORTS.CANOE_SLALOM]: null,
  [SPORTS.POOL]: null,
  [SPORTS.CURLING]: null,
  [SPORTS.E_SPORT_MOTORSPORT]: null,
  [SPORTS.ROWING]: null,
  [SPORTS.E_SPORT_WORLD_OF_WARCRAFT]: null,
  [SPORTS.E_SPORT_WORLD_OF_TANKS]: null,
  [SPORTS.SAILING]: null,
  [SPORTS.SPEED_SKATING]: null,
  [SPORTS.E_SPORT_KING_OF_GLORY]: null,
  [SPORTS.INLINE_HOCKEY]: null,
  [SPORTS.MOTO2]: null,
  [SPORTS.TRIATHLON]: null,
  [SPORTS.SQUASH]: null,
  [SPORTS.E_SPORT_AUTO_CHESS]: null,
  [SPORTS.E_SPORT_OVERWATCH]: null,
  [SPORTS.E_SPORT_CROSSFIRE]: null,
  [SPORTS.EQUESTRIAN]: null,
  [SPORTS.E_ICE_HOCKEY]: null,
  [SPORTS.SNOWBOARDCROSS_PARALLEL]: null,
  [SPORTS.WRESTLING]: null,
  [SPORTS.E_SPORT_PRO_EVOLUTION_SOCCER]: null,
  [SPORTS.E_SPORT_GEARS_OF_WAR]: null,
  [SPORTS.CYCLING_BMX_FREESTYLE]: null,
  [SPORTS.E_SPORT_VAINGLORY]: null,
  [SPORTS.E_SPORT_CLASH_ROYALE]: null,
  [SPORTS.TROTTING]: null,
  [SPORTS.E_SPORT_SMITE]: null,
  [SPORTS.FENCING]: null,
  [SPORTS.E_SPORT_QUAKE]: null,
  [SPORTS.CROSS_COUNTRY]: null,
  [SPORTS.E_SPORT_WILD_RIFT]: null,
  [SPORTS.E_SPORT_COUNTER_STRIKE]: null,
  [SPORTS.SEPAK_TAKRAW]: null,
  [SPORTS.PADEL]: null,
  [SPORTS.E_SPORT_RAINBOW_SIX]: null,
  [SPORTS.SWIMMING]: null,
  [SPORTS.T_BASKET]: null,
  [SPORTS.GOLF]: null,
  [SPORTS.CYCLING_CYCLE_BALL]: null,
  [SPORTS.STREETHOCKEY]: null,
  [SPORTS.AIR_RACING]: null,
  [SPORTS.FISHING]: null,
  [SPORTS.BOBSLEIGH]: null,
  [SPORTS.E_VOLLEYBALL]: null,
  [SPORTS.SPEEDWAY]: null,
  [SPORTS.LUGE]: null,
  [SPORTS.ARTISTIC_SWIMMING]: null,
  [SPORTS.FORMULA_E]: null,
  [SPORTS.E_SPORT_DOTA]: null,
  [SPORTS.TOURING_CAR_RACING]: null,
  [SPORTS.SUPERBIKE]: null,
  [SPORTS.BEACH_HANDBALL]: null,
  [SPORTS.GAELIC_HURLING]: null,
  [SPORTS.CHESS]: null,
  [SPORTS.E_SPORT_HEARTHSTONE]: null,
  [SPORTS.E_SPORT_FORTNITE]: null,
  [SPORTS.FLOORBALL]: null,
  [SPORTS.MOTORCYCLE_RACING]: null,
  [SPORTS.E_SPORT_CALL_OF_DUTY]: null,
  [SPORTS.OFF_ROAD]: null,
  [SPORTS.MOTO3]: null,
  [SPORTS.INDY_RACING]: null,
  [SPORTS.SKATEBOARDING]: null,
  [SPORTS.E_SPORT_WARCRAFT_III]: null,
  [SPORTS.E_SPORT_PALADINS]: null,
  [SPORTS.E_SPORT_VALORANT]: null,
  [SPORTS.TRACK_CYCLING]: null,
  [SPORTS.TRUCK_AND_TRACTOR_PULLING]: null,
  [SPORTS.E_SPORT_TF2]: null,
  [SPORTS.SPORT_CLIMBING]: null,
  [SPORTS.SNOWBOARD]: null,
  [SPORTS.SCHWINGEN]: null,
  [SPORTS.NASCAR_CAMPING_WORLD_TRUCK]: null,
  [SPORTS.BANDY]: null,
  [SPORTS.DTM]: null,
  [SPORTS.E_SPORT_DOTA_UNDERLORDS]: null,
  [SPORTS.E_SPORT_STREET_FIGHTER_V]: null,
  [SPORTS.SHOOTING]: null,
  [SPORTS.WINTER_SPORTS]: null,
  [SPORTS.GYMNASTICS]: null,
  [SPORTS.E_SPORT_LEAGUE_OF_LEGENDS]: null,
  [SPORTS.E_SPORT_MADDEN_NFL]: null,
  [SPORTS.SPEED_BOAT_RACING]: null,
  [SPORTS.BIKES]: null,
  [SPORTS.ATHLETICS]: null,
  [SPORTS.TRAMPOLINE_GYMNASTICS]: null,
  [SPORTS.GAELIC_SPORTS]: null,
  [SPORTS.STOCK_CAR_RACING]: null,
  [SPORTS.BEACH_VOLLEY]: SportIcons.VolleyBallIcon,
  [SPORTS.SURFING]: null,
  [SPORTS.RALLY]: null,
  [SPORTS.NASCAR_CUP_SERIES]: null,
  [SPORTS.FIELD_HOCKEY]: null,
  [SPORTS.MARATHON_SWIMMING]: null,
  [SPORTS.WORLD_CHAMPIONSHIP]: null,
  [SPORTS.E_SPORT_SSBM]: null,
  [SPORTS.JUDO]: null,
  [SPORTS.AQUATICS]: null,
  [SPORTS.DIVING]: null,
  [SPORTS.OLYMPICS]: null,
  [SPORTS.WORLD_LOTTERY]: null,
  [SPORTS.FORMULA_1]: null,
  [SPORTS.E_SPORT_BRAWL_STARS]: null,
  [SPORTS.RIDING]: null,
  [SPORTS.WEIGHTLIFTING]: null,
  [SPORTS.FINNISH_BASEBALL]: null,
  [SPORTS.RUGBY_LEAGUE]: null,
  [SPORTS.NETBALL]: null,
  [SPORTS.HORSE_RACING]: null,
  [SPORTS.E_SPORT_APEX_LEGENDS]: null,
  [SPORTS.E_SPORT]: null,
  [SPORTS.MODIFIED_RACING]: null,
  [SPORTS.MOTOCROSS]: null,
  [SPORTS.LACROSSE]: null,
  [SPORTS.SHORT_TRACK]: null,
  [SPORTS.HORSEBALL]: null,
  [SPORTS.E_SPORT_HEROES_OF_THE_STORM]: null,
  [SPORTS.SOCCER_MYTHICAL]: null,
  [SPORTS.BEACH_SOCCER]: null,
  [SPORTS.INDOOR_SOCCER]: null,
  [SPORTS.DEPRECATED_SC]: null,
  [SPORTS.SPRINT_CAR_RACING]: null,
  [SPORTS.E_SPORT_FIGHTING_GAMES]: null,
  [SPORTS.CANOEING]: null,
  [SPORTS.SEVEN_BALLRUN]: null,
  [SPORTS.INDY_LIGHTS]: null,
  [SPORTS.FORMULA_2]: null,
  [SPORTS.MODERN_PENTATHLON]: null,
  [SPORTS.E_SPORT_STARCRAFT]: null,
  [SPORTS.E_SPORT_TEAMFIGHT_TACTICS]: null,
  [SPORTS.BMX_RACING]: null,
  [SPORTS.E_TENNIS]: null,
  [SPORTS.E_SPORT_MTG]: null,
  [SPORTS.OLYMPICS_YOUTH]: null,
  [SPORTS.SOFTBALL]: null,
  [SPORTS.POLO]: null,
  [SPORTS.RHYTHMIC_GYMNASTICS]: null,
  [SPORTS.PETANQUE]: null,
  [SPORTS.TAEKWONDO]: null,
  [SPORTS.DRAG_RACING]: null,
  [SPORTS.FIGURE_SKATING]: null,
  [SPORTS.SKELETON]: null,
  [SPORTS.GREYHOUND]: null,
  [SPORTS.RACQUETBALL]: null,
  [SPORTS.MUAY_THAI]: null,
  [SPORTS.CORNHOLE]: null,
  [SPORTS.BREAKING]: null,
  [SPORTS.SOCCER_SPECIALS]: SportIcons.SoccerIcon,
};

export const sportIdToSportNameMap: Record<number, SPORTS> = {
  894: SPORTS.GAELIC_FOOTBALL,
  2278: SPORTS.E_BASKETBALL,
  2279: SPORTS.E_SOCCER,
  // 2699: SPORTS.BEACH_VOLLEYBALL,
  1: SPORTS.E_SPORT_HALO,
  3: SPORTS.RINK_HOCKEY,
  10: SPORTS.E_SPORT_ARTIFACT,
  12: SPORTS.MOTOGP,
  14: SPORTS.BEACH_TENNIS,
  16: SPORTS.AUSSIE_RULES,
  21: SPORTS.E_CRICKET,
  22: SPORTS.ENDURANCE_RACING,
  24: SPORTS.E_SOCCER,
  49: SPORTS.MOTORSPORT,
  61: SPORTS.KABADDI,
  66: SPORTS.SNOOKER,
  72: SPORTS.E_SPORT_ROCKET_LEAGUE,
  93: SPORTS.KARATE,
  97: SPORTS.FREESTYLE,
  98: SPORTS.BADMINTON,
  119: SPORTS.PESAPALLO,
  123: SPORTS.ARCHERY,
  125: SPORTS.NORDIC_COMBINED,
  133: SPORTS.E_SPORT_TEKKEN,
  135: SPORTS.NASCAR_XFINITY_SERIES,
  137: SPORTS.BIATHLON,
  153: SPORTS.BASQUE_PELOTA,
  154: SPORTS.E_SPORT_PLAYERUNKNOWNS_BATTLEGROUNDS,
  171: SPORTS.AMERICAN_FOOTBALL,
  192: SPORTS.E_SPORT_DRAGON_BALL_FIGHTERZ,
  194: SPORTS.MOUNTAIN_BIKE,
  196: SPORTS.SUMO,
  198: SPORTS.CYCLING_BMX_RACING,
  200: SPORTS.FREESTYLE_SKIING,
  216: SPORTS.NHRA,
  218: SPORTS.E_SPORT_ARENA_OF_VALOR,
  227: SPORTS.CANOE_SLALOM,
  228: SPORTS.POOL,
  231: SPORTS.CURLING,
  238: SPORTS.E_SPORT_MOTORSPORT,
  245: SPORTS.ROWING,
  247: SPORTS.E_SPORT_WORLD_OF_WARCRAFT,
  250: SPORTS.E_SPORT_WORLD_OF_TANKS,
  252: SPORTS.SAILING,
  254: SPORTS.SPEED_SKATING,
  271: SPORTS.E_SPORT_KING_OF_GLORY,
  274: SPORTS.INLINE_HOCKEY,
  278: SPORTS.MOTO2,
  280: SPORTS.TRIATHLON,
  282: SPORTS.SQUASH,
  290: SPORTS.BOXING,
  302: SPORTS.E_SPORT_AUTO_CHESS,
  304: SPORTS.TABLE_TENNIS,
  337: SPORTS.E_SPORT_OVERWATCH,
  364: SPORTS.E_SPORT_CROSSFIRE,
  366: SPORTS.EQUESTRIAN,
  370: SPORTS.E_ICE_HOCKEY,
  374: SPORTS.SNOWBOARDCROSS_PARALLEL,
  375: SPORTS.WRESTLING,
  379: SPORTS.E_SPORT_PRO_EVOLUTION_SOCCER,
  382: SPORTS.E_SPORT_GEARS_OF_WAR,
  384: SPORTS.CYCLING,
  401: SPORTS.WATERPOLO,
  434: SPORTS.CYCLING_BMX_FREESTYLE,
  436: SPORTS.E_SPORT_VAINGLORY,
  439: SPORTS.E_SPORT_CLASH_ROYALE,
  441: SPORTS.TROTTING,
  443: SPORTS.E_SPORT_SMITE,
  446: SPORTS.FENCING,
  448: SPORTS.E_SPORT_QUAKE,
  451: SPORTS.CROSS_COUNTRY,
  464: SPORTS.E_SPORT_WILD_RIFT,
  466: SPORTS.E_SPORT_COUNTER_STRIKE,
  684: SPORTS.SEPAK_TAKRAW,
  689: SPORTS.PADEL,
  692: SPORTS.E_SPORT_RAINBOW_SIX,
  706: SPORTS.SWIMMING,
  708: SPORTS.T_BASKET,
  710: SPORTS.GOLF,
  726: SPORTS.CYCLING_CYCLE_BALL,
  728: SPORTS.STREETHOCKEY,
  732: SPORTS.CRICKET,
  814: SPORTS.AIR_RACING,
  816: SPORTS.FISHING,
  818: SPORTS.BOBSLEIGH,
  824: SPORTS.E_VOLLEYBALL,
  826: SPORTS.HANDBALL,
  983: SPORTS.E_BASKETBALL,
  994: SPORTS.SKI_JUMPING,
  1011: SPORTS.SPEEDWAY,
  1022: SPORTS.LUGE,
  1031: SPORTS.ARTISTIC_SWIMMING,
  1033: SPORTS.FORMULA_E,
  1037: SPORTS.E_SPORT_DOTA,
  1149: SPORTS.TOURING_CAR_RACING,
  1157: SPORTS.SUPERBIKE,
  1159: SPORTS.VOLLEYBALL,
  1309: SPORTS.BEACH_HANDBALL,
  1312: SPORTS.GAELIC_HURLING,
  1314: SPORTS.CHESS,
  1319: SPORTS.E_SPORT_HEARTHSTONE,
  1326: SPORTS.E_SPORT_FORTNITE,
  1329: SPORTS.FLOORBALL,
  1355: SPORTS.MOTORCYCLE_RACING,
  1366: SPORTS.BASKETBALL,
  1598: SPORTS.E_SPORT_CALL_OF_DUTY,
  1606: SPORTS.OFF_ROAD,
  1607: SPORTS.MOTO3,
  1609: SPORTS.INDY_RACING,
  1615: SPORTS.SKATEBOARDING,
  1617: SPORTS.E_SPORT_WARCRAFT_III,
  1621: SPORTS.E_SPORT_PALADINS,
  1624: SPORTS.E_SPORT_VALORANT,
  1638: SPORTS.TRACK_CYCLING,
  1640: SPORTS.TRUCK_AND_TRACTOR_PULLING,
  1641: SPORTS.E_SPORT_TF2,
  1643: SPORTS.SPORT_CLIMBING,
  1645: SPORTS.SNOWBOARD,
  1662: SPORTS.SCHWINGEN,
  1664: SPORTS.NASCAR_CAMPING_WORLD_TRUCK,
  1666: SPORTS.BANDY,
  1673: SPORTS.DTM,
  1675: SPORTS.E_SPORT_DOTA_UNDERLORDS,
  1677: SPORTS.E_SPORT_STREET_FIGHTER_V,
  1695: SPORTS.SHOOTING,
  1697: SPORTS.WINTER_SPORTS,
  1700: SPORTS.GYMNASTICS,
  1702: SPORTS.E_SPORT_LEAGUE_OF_LEGENDS,
  1748: SPORTS.E_SPORT_MADDEN_NFL,
  1749: SPORTS.SPEED_BOAT_RACING,
  1750: SPORTS.SOCCER,
  2481: SPORTS.BIKES,
  2482: SPORTS.ATHLETICS,
  2484: SPORTS.TRAMPOLINE_GYMNASTICS,
  2486: SPORTS.GAELIC_SPORTS,
  2490: SPORTS.STOCK_CAR_RACING,
  2491: SPORTS.RUGBY,
  2545: SPORTS.BEACH_VOLLEY,
  2561: SPORTS.BASKETBALL_3X3,
  2581: SPORTS.STOCK_CAR_RACING,
  2587: SPORTS.SURFING,
  2589: SPORTS.RALLY,
  2594: SPORTS.NASCAR_CUP_SERIES,
  2596: SPORTS.DARTS,
  2605: SPORTS.MMA,
  2645: SPORTS.FIELD_HOCKEY,
  2671: SPORTS.MARATHON_SWIMMING,
  2673: SPORTS.WORLD_CHAMPIONSHIP,
  2674: SPORTS.E_SPORT_SSBM,
  2679: SPORTS.JUDO,
  2681: SPORTS.AQUATICS,
  2682: SPORTS.DIVING,
  2684: SPORTS.OLYMPICS,
  2746: SPORTS.WORLD_LOTTERY,
  3000: SPORTS.FORMULA_1,
  3007: SPORTS.E_SPORT_BRAWL_STARS,
  3009: SPORTS.RIDING,
  3010: SPORTS.WEIGHTLIFTING,
  3012: SPORTS.FINNISH_BASEBALL,
  3013: SPORTS.RUGBY_LEAGUE,
  3014: SPORTS.NETBALL,
  3020: SPORTS.HORSE_RACING,
  3137: SPORTS.E_SPORT_APEX_LEGENDS,
  3138: SPORTS.E_SPORT,
  3147: SPORTS.MODIFIED_RACING,
  3148: SPORTS.MOTOCROSS,
  3151: SPORTS.LACROSSE,
  3157: SPORTS.SHORT_TRACK,
  3171: SPORTS.HORSEBALL,
  3173: SPORTS.E_SPORT_HEROES_OF_THE_STORM,
  3176: SPORTS.SOCCER_MYTHICAL,
  3179: SPORTS.BEACH_SOCCER,
  3197: SPORTS.INDOOR_SOCCER,
  3199: SPORTS.BOWLS,
  3204: SPORTS.ICE_HOCKEY,
  3300: SPORTS.DEPRECATED_SC,
  3301: SPORTS.SPRINT_CAR_RACING,
  3302: SPORTS.BASEBALL,
  3351: SPORTS.E_SPORT_FIGHTING_GAMES,
  3354: SPORTS.CANOEING,
  3359: SPORTS.SEVEN_BALLRUN,
  3362: SPORTS.SPECIALS,
  3380: SPORTS.INDY_LIGHTS,
  3382: SPORTS.FORMULA_2,
  3385: SPORTS.MODERN_PENTATHLON,
  3387: SPORTS.E_SPORT_STARCRAFT,
  3429: SPORTS.TENNIS,
  3600: SPORTS.E_SPORT_TEAMFIGHT_TACTICS,
  3602: SPORTS.BMX_RACING,
  3603: SPORTS.E_TENNIS,
  3604: SPORTS.E_SPORT_MTG,
  3606: SPORTS.OLYMPICS_YOUTH,
  3608: SPORTS.SOFTBALL,
  3613: SPORTS.GAELIC_FOOTBALL,
  3615: SPORTS.POLO,
  3617: SPORTS.RHYTHMIC_GYMNASTICS,
  3619: SPORTS.FUTSAL,
  3722: SPORTS.PETANQUE,
  3724: SPORTS.TAEKWONDO,
  3726: SPORTS.DRAG_RACING,
  3729: SPORTS.FIGURE_SKATING,
  3737: SPORTS.SKELETON,
  3740: SPORTS.ALPINE_SKIING,
  3757: SPORTS.GREYHOUND,
  3831: SPORTS.RACQUETBALL,
  4043: SPORTS.MUAY_THAI,
  4068: SPORTS.CORNHOLE,
  4112: SPORTS.BREAKING,
  4160: SPORTS.SOCCER_SPECIALS,
};

export const availableSportBackgroundsMap: Partial<Record<SPORTS, string>> = {
  [SPORTS.ALPINE_SKIING]: SportImages.AlpineSkiingImage,
  [SPORTS.BASEBALL]: SportImages.BaseballImage,
  [SPORTS.BASKETBALL]: SportImages.BasketballImage,
  [SPORTS.BOWLS]: SportImages.BowlsImage,
  [SPORTS.BOXING]: SportImages.BoxingImage,
  [SPORTS.CRICKET]: SportImages.CricketImage,
  [SPORTS.DARTS]: SportImages.DartsImage,
  [SPORTS.ICE_HOCKEY]: SportImages.IceHockeyImage,
  [SPORTS.SOCCER]: SportImages.SoccerImage,
  [SPORTS.TENNIS]: SportImages.TennisImage,
  [SPORTS.VOLLEYBALL]: SportImages.VolleyballImage,
  [SPORTS.AMERICAN_FOOTBALL]: SportImages.AmericanFootballImage,
  [SPORTS.AUSSIE_RULES]: SportImages.AussieRulesImage,
  [SPORTS.BADMINTON]: SportImages.BadmintonImage,
  [SPORTS.BASKETBALL_3X3]: SportImages.Basketball3x3Image,
  [SPORTS.BIATHLON]: SportImages.BiathlonImage,
  [SPORTS.CYCLING]: SportImages.CyclingImage,
  [SPORTS.FUTSAL]: SportImages.FutsalImage,
  [SPORTS.GAELIC_FOOTBALL]: SportImages.GaelicFootballImage,
  [SPORTS.HANDBALL]: SportImages.HandballImage,
  [SPORTS.MMA]: SportImages.MMAImage,
  [SPORTS.MOTORSPORT]: SportImages.MotorsportImage,
  [SPORTS.PESAPALLO]: SportImages.PesapalloImage,
  [SPORTS.RUGBY]: SportImages.RugbyImage,
  [SPORTS.SKI_JUMPING]: SportImages.SkiJumpingImage,
  [SPORTS.SNOOKER]: SportImages.SnookerImage,
  [SPORTS.TABLE_TENNIS]: SportImages.TableTennisImage,
  [SPORTS.WATERPOLO]: SportImages.WaterpoloImage,
};

export type SportIconKey = keyof typeof sportIconMap;
