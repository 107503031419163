import { useEffect } from 'react';

import { useLiveEventConnection } from '~components/molecules/InplayEvents/hooks/useLiveEventConnection';
import { MARKET_STATUS } from '~constants/common';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsEventLoaded,
  setLiveEventData,
  setLoadingEventId,
  setMarketGroups,
} from '~store/slices/liveMenuSlice';
import { Market } from '~types/events';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { groupByMarketId } from '~utils/markets';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

export const usePrematchEvent = () => {
  const dispatch = useAppDispatch();
  const { eventId, sportId } = useQueryParams();
  const {
    liveEventData: eventData,
    marketGroups,
    isEventLoaded,
    loadingEventId,
  } = useAppSelector((state) => state.liveMenu);
  const { isMobileOrTablet } = useMedia();
  const { favoriteMarkets } = useAppSelector((state) => state.mainMarkets);

  const { language } = useAppSelector((state) => state.websiteSettings);
  const { isPrimaryDatsLoaded, sendWsMessage } = useEventsLoadingSocket();

  useEffect(() => {
    if (eventId && isPrimaryDatsLoaded) {
      dispatch(setLoadingEventId(eventId));
      sendWsMessage(ACTION_TYPE.GET_PREMATCH_EVENT, { Id: eventId });
    }

    return () => {
      dispatch(setIsEventLoaded(false));
      dispatch(setLiveEventData(null));
      dispatch(setMarketGroups({}));
    };
  }, [eventId, language, isPrimaryDatsLoaded]);

  useEffect(() => {
    if (eventData && isEventLoaded) {
      const mapUpdatedMarkets = (eventData?.markets || []).filter(
        (item) =>
          item &&
          item.status === MARKET_STATUS.ACTIVE &&
          item.selections.length,
      );

      let mapUpdatedMarketsFavorites: (Market | undefined)[] = [];

      if (sportId || eventData.sportId) {
        const ongoingSportFavorites = favoriteMarkets?.find(
          (favoriteMarket) =>
            favoriteMarket.sportId === +(sportId || eventData.sportId || 0),
        );

        mapUpdatedMarketsFavorites = mapUpdatedMarkets.map((market) => {
          if (market?.marketId) {
            return {
              ...market,
              favorite: ongoingSportFavorites?.marketIds.includes(
                parseInt(market.marketId),
              ),
            };
          }

          return market;
        });
      }

      const resGroups = groupByMarketId(mapUpdatedMarketsFavorites);

      if (!areTwoObjectsEqual(resGroups, marketGroups)) {
        dispatch(setMarketGroups(resGroups));
      }
    }
  }, [eventData, favoriteMarkets, isEventLoaded, sportId, marketGroups]);

  useLiveEventConnection({ loaded: !!eventData });

  return {
    isMobileOrTablet,
    marketGroups,
    eventId,
    eventData,
    isEventLoaded,
    isFetching: loadingEventId,
  };
};
