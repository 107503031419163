import { SelectWithLabel } from '~components/atoms/SelectWithLabel';
import {
  StyledSettingsPopoverContent,
  StyledSettingsPopoverItem,
} from '~components/atoms/SettingsDropdown/styled.components';
import { Text } from '~components/atoms/Typography';
import { ODDS } from '~constants/odds';
import { TIMEZONES } from '~constants/timezones';
import { useTranslation } from '~hooks/useTranslation';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';

// type Props = {
//   flagsMap: Record<string, ReactNode>;
//   langOptions: SelectOptionType[];
// };

export const SettingsDropdownPopoverMobile = () => {
  const { localized } = useTranslation();

  const { odds, timezone, onTimezoneChange, onOddsChange } =
    useWebsiteSettings();

  return (
    <>
      <Text textTransform="uppercase" level="14-20">
        {localized('settings.label')}
      </Text>
      <StyledSettingsPopoverContent
        css={{ background: '$grayDark', margin: '16px 0 20px' }}
      >
        {/*<StyledSettingsPopoverItem>*/}
        {/*  <Text level="12-20" textTransform="uppercase" color="grayMedium">*/}
        {/*    {localized('settings.language')}*/}
        {/*  </Text>*/}
        {/*  <SelectWithLabel*/}
        {/*    value={language}*/}
        {/*    setValue={onLanguageChange}*/}
        {/*    options={langOptions}*/}
        {/*    additionalSelectedComponents={flagsMap}*/}
        {/*    dropdownWidth="100%"*/}
        {/*  />*/}
        {/*</StyledSettingsPopoverItem>*/}
        <StyledSettingsPopoverItem>
          <Text level="12-20" textTransform="uppercase" color="grayMedium">
            {localized('settings.timeZone')}
          </Text>

          <SelectWithLabel
            value={timezone}
            setValue={onTimezoneChange}
            options={TIMEZONES}
            style={{ width: '100%' }}
          />
        </StyledSettingsPopoverItem>
        <StyledSettingsPopoverItem>
          <Text level="12-20" textTransform="uppercase" color="grayMedium">
            {localized('settings.oddsFormat')}
          </Text>
          <SelectWithLabel
            value={odds}
            setValue={onOddsChange}
            options={ODDS}
            dropdownWidth="100%"
          />
        </StyledSettingsPopoverItem>
      </StyledSettingsPopoverContent>
    </>
  );
};
