import { useAppSelector } from '~store';
import { ACTION_TYPE, getActionMessage } from '~utils/eventsSocketUtils';

export const useEventsLoadingSocket = () => {
  const { eventsSocket, eventSocketConnected, primaryDataLoaded } =
    useAppSelector((state) => state.signalRSockets);

  const sendWsMessage = (
    message: ACTION_TYPE,
    params?: Record<string, unknown>,
  ) => {
    if (eventsSocket?.readyState === WebSocket.OPEN) {
      eventsSocket?.send(getActionMessage(message, params));
    }
  };

  return {
    isWsConnected: eventSocketConnected,
    isPrimaryDatsLoaded: primaryDataLoaded && eventSocketConnected,
    sendWsMessage,
  };
};
