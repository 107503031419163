export enum MEDIA_QUERIES {
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XS_SM = 'xs_sm',
  MD_LG = 'md_lg',
  XL = 'xl',
  MD_LG_XL = 'md_lg_xl',
  LG_XL = 'lg_xl',
}

export interface MediaQueryResults {
  isSmallMobile: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isMobileOrTablet: boolean;
  isLargeDesktop: boolean;
  isLargeDesktopOrDesktop: boolean;
  isLaptopOrDesktop: boolean;
}

export type BaseMediaQueries = Exclude<
  MEDIA_QUERIES,
  | MEDIA_QUERIES.XS_SM
  | MEDIA_QUERIES.MD_LG
  | MEDIA_QUERIES.MD_LG_XL
  | MEDIA_QUERIES.LG_XL
>;

export type SpecificMediaQueries = Record<BaseMediaQueries, string>;

// Provide specific values for media queries.
export const mediaQueries: SpecificMediaQueries = {
  [MEDIA_QUERIES.XXS]: '(max-width: 350px)',
  [MEDIA_QUERIES.XS]: '(max-width: 767px)',
  [MEDIA_QUERIES.SM]: '(min-width: 768px) and (max-width: 1023px)',
  [MEDIA_QUERIES.MD]: '(min-width: 1024px) and (max-width: 1439px)',
  [MEDIA_QUERIES.LG]: '(min-width: 1440px) and (max-width: 1799px)',
  [MEDIA_QUERIES.XL]: '(min-width: 1800px)',
};

export const combinedMediaQueries = {
  [MEDIA_QUERIES.XS_SM]: '(max-width: 1023px)',
  [MEDIA_QUERIES.MD_LG]: '(min-width: 1024px) and (max-width: 1799px)',
  [MEDIA_QUERIES.MD_LG_XL]: '(min-width: 1024px)',
  [MEDIA_QUERIES.LG_XL]: '(min-width: 1440px)',
};

export const mediaQueriesAndCombinations = {
  ...mediaQueries,
  ...combinedMediaQueries,
};
