import { Box } from '~components/atoms/Box';
import { TabsList } from '~components/atoms/Tabs/Tabs';
import { BETSLIP_HISTORY_TABS_LIST } from '~components/molecules/BetslipHistorySection/constants';
import { StyledText } from '~components/molecules/BetslipHistorySection/Sports/BetslipHistoryFiltersMobile/styled.components';
import { UseBetslipHistoryFiltersReturn } from '~components/molecules/BetslipHistorySection/Sports/useBetslipHistoryFilters';
import { FilterIcon, ResetIcon } from '~icons';

import {
  StyledActiveParamsNumber,
  StyledBetslipHistoryTabsWrapper,
  StyledFilterButton,
} from './styled.components';

interface BetslipHistoryTabsMobileProps {
  setShowFilters: (show: boolean) => void;
  disabledFilters?: boolean;
  betslipHistoryFiltersParams: UseBetslipHistoryFiltersReturn;
}

export const BetslipHistoryTabsMobile = ({
  setShowFilters,
  disabledFilters = false,
  betslipHistoryFiltersParams,
}: BetslipHistoryTabsMobileProps) => {
  const { activeParamsNumber, handleReset } = betslipHistoryFiltersParams;

  return (
    <StyledBetslipHistoryTabsWrapper>
      <TabsList
        withSlider
        withSeparator
        capitalize
        tabs={BETSLIP_HISTORY_TABS_LIST}
        css={{
          gap: '$3',
          fontSize: '14px',
          lineHeight: '20px',
          display: 'flex',
          alignItems: 'center',
        }}
      />
      <Box flexRow gap={3}>
        <StyledFilterButton
          disabled={disabledFilters}
          variant="secondary"
          onClick={() => setShowFilters(true)}
        >
          <Box flexRow alignCenter justifyCenter>
            <FilterIcon />
            {activeParamsNumber > 0 && (
              <StyledActiveParamsNumber>
                <StyledText
                  smallFont
                  css={{
                    color: '$buttonTextColor !important',
                  }}
                >
                  {activeParamsNumber}
                </StyledText>
              </StyledActiveParamsNumber>
            )}
          </Box>
        </StyledFilterButton>
        <StyledFilterButton
          disabled={disabledFilters}
          variant="secondary"
          onClick={handleReset}
        >
          <Box flexRow alignCenter justifyCenter>
            <ResetIcon />
          </Box>
        </StyledFilterButton>
      </Box>
    </StyledBetslipHistoryTabsWrapper>
  );
};
