import { MouseEvent, useRef } from 'react';

import {
  useDeleteTournamentFromFavorites,
  useSetTournamentAsFavorite,
} from '~api/sportEvent/sportEventMutations';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoriteTournaments } from '~store/slices/sportGroupsSlice';

export const useFavoriteLeague = (
  name: string,
  id: number,
  sportId: number,
  countryId: number,
  events: number,
  sportName: string,
  countryName: string,
) => {
  const dispatch = useAppDispatch();
  const favoriteWrapperRef = useRef<HTMLDivElement>(null);

  const { favoriteTournaments } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const isFavorite = !!favoriteTournaments.find(
    (tournament) => tournament.id === id,
  );

  const { deleteTournamentFromFavoriteMutation } =
    useDeleteTournamentFromFavorites();

  const { setTournamentAsFavoriteMutation } = useSetTournamentAsFavorite();

  const handleFavoriteClick = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (favoriteWrapperRef.current) {
      favoriteWrapperRef.current.blur();
    }

    if (!isFavorite) {
      await setTournamentAsFavoriteMutation({
        id,
      }).then(() => {
        dispatch(
          setFavoriteTournaments([
            ...favoriteTournaments,
            {
              id,
              sportId: sportId,
              countryId: countryId!,
              name: name!,
              events: events,
              sportName: sportName!,
              countryName: countryName!,
            },
          ]),
        );
      });
    } else {
      await deleteTournamentFromFavoriteMutation({
        id,
      }).then(() => {
        dispatch(
          setFavoriteTournaments(
            favoriteTournaments.filter((tournament) => tournament.id !== id),
          ),
        );
      });
    }
  };

  return {
    isFavorite,
    favoriteWrapperRef,
    handleFavoriteClick,
  };
};
