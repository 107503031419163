import React from 'react';
import { PatternFormat } from 'react-number-format';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';
import {
  PHONE_FIELD_COUNTRY_CODES,
  PHONE_FIELD_MASKS,
  PHONE_FIELD_MASKS_WITH_DASH,
} from '~constants/auth';
import { useAppSelector } from '~store';

interface PhoneNumberInputProps {
  phoneNumber: string;
  onChange: (phoneNumber: string) => void;
  isError?: boolean;
}

const textSize = {
  fontSize: '$16',
  lineHeight: '$28',
  '@lg_xl': {
    fontSize: '$18',
    lineHeight: '$24',
  },
  '@xs': {
    fontSize: '$12',
    lineHeight: '$20',
  },
};

const StyledTitleText = styled(Text, {
  textTransform: 'uppercase',
  ...textSize,
});

const StyledPhoneInput = styled(Input, {
  padding: '0 $1',
  width: '100%',
  ...textSize,
});

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  phoneNumber,
  onChange,
  isError = false,
}) => {
  const { currency } = useAppSelector((state) => state.settings);
  const phoneMask =
    phoneNumber.length > 0
      ? PHONE_FIELD_MASKS_WITH_DASH[currency]
      : PHONE_FIELD_MASKS[currency];

  return (
    <Box flexRow>
      {PHONE_FIELD_COUNTRY_CODES[currency] && (
        <Box>
          <StyledTitleText color={isError ? 'red' : 'grayMedium'}>
            {`(${PHONE_FIELD_COUNTRY_CODES[currency]})`}
          </StyledTitleText>
        </Box>
      )}
      <PatternFormat
        allowEmptyFormatting
        value={phoneNumber}
        displayType="input"
        format={phoneMask}
        customInput={StyledPhoneInput}
        onValueChange={(values) => onChange(values.value)}
        style={{
          color: isError ? '#CC302B' : '#E5E5E5',
        }}
      />
    </Box>
  );
};
