import { useCallback, useEffect, useState } from 'react';

import { useLazyGetLeaguesByCountry } from '~api/category/categoryQueries';
import { Countries, Leagues } from '~api/category/types';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setLeaguesData,
  setLoadingCountryId,
  setLoadingSportId,
  setOpenedCountries,
} from '~store/slices/prematchMenuSlice';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { convertKeysToUpperCase } from '~utils/objectHelpers';

import { MULTI_ACCORDION_ROLES } from '../../MultiAccordion';

export const useCountryMenu = (
  initialCountries: Countries,
  accordionSportId?: string,
) => {
  const { sportId } = useQueryParams();
  const dispatch = useAppDispatch();
  const countryMenuRole = MULTI_ACCORDION_ROLES.CHILD;
  const {
    openedCountries,
    leaguesData,
    loadingCountryId,
    sports,
    dateToFilter,
  } = useAppSelector((state) => state.prematchMenu);
  const { isPrimaryDatsLoaded, sendWsMessage } = useEventsLoadingSocket();

  const [curSportOpenedCurrentCountry, setCurSportOpenedCurrentCountry] =
    useState(openedCountries);

  const { lazyGetLeaguesByCountryQuery } = useLazyGetLeaguesByCountry();

  const getLeaguesData = useCallback(
    (countryId: number) => {
      return countryId in leaguesData
        ? (leaguesData[countryId] as Leagues)
        : [];
    },
    [leaguesData],
  );

  const handleOnValueChange = useCallback(
    async (e: string[]) => {
      const countryId = e.find((item) => !openedCountries.includes(item));

      if (countryId) {
        dispatch(setLoadingSportId(parseInt(accordionSportId as string)));
        dispatch(setLoadingCountryId(parseInt(countryId)));
        const params: { [key: string]: unknown } = {};

        if (countryId) {
          params.countryId = parseInt(countryId);
        }

        if (dateToFilter) {
          params.dateTo = dateToFilter;
        }

        sendWsMessage(
          ACTION_TYPE.GET_PREMATCH_SUB_MENU,
          convertKeysToUpperCase(params),
        );
      }

      setTimeout(() => {
        if (accordionSportId) {
          setCurSportOpenedCurrentCountry(e);
        }

        dispatch(setOpenedCountries(e));
      }, 300);
    },
    [
      openedCountries,
      leaguesData,
      sports,
      sportId,
      accordionSportId,
      isPrimaryDatsLoaded,
    ],
  );

  const handleLoadLeagues = async ({ countryId }: { countryId: number }) => {
    const leagues: Leagues = await lazyGetLeaguesByCountryQuery({
      countryId,
    }).unwrap();

    dispatch(setOpenedCountries([countryId.toString()]));
    dispatch(setLeaguesData({ id: countryId.toString(), data: leagues }));
  };

  useEffect(() => {
    if (initialCountries.length === 0 || openedCountries.length) {
      return;
    }

    const { id } =
      initialCountries.find((country) => country?.leagues?.length > 0) || {};

    if (id) {
      dispatch(setOpenedCountries([id.toString()]));
    } else if (initialCountries[0]) {
      const countryId = initialCountries[0].id;

      handleLoadLeagues({ countryId });
    }
  }, [initialCountries]);

  return {
    loadingCountryId,
    countryMenuRole,
    openCountries: accordionSportId
      ? curSportOpenedCurrentCountry
      : openedCountries,
    handleOnValueChange,
    getLeaguesData,
  };
};
