import { useMemo } from 'react';

import { SIGNAL_R_SOCKET_NAMES } from '~constants/signalR';
import { useAppSelector } from '~store';

import SocketService from '../socketService';

type EventCallback = (data: any) => void;
type EventCallbacks = Record<string, EventCallback>;
type EventArgs = any[];

const useInplaySocket = () => {
  const { sockets } = useAppSelector((state: any) => state.signalRSockets);
  const socket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

  const socketService = useMemo(() => new SocketService(socket), [socket]);

  const listening = (eventCallbacks: EventCallbacks) => {
    socketService.addListener(eventCallbacks);

    return () => {
      socketService.removeListener(eventCallbacks);
    };
  };

  const sendMessage = (event: string, ...args: EventArgs) => {
    return socketService.invoke(event, ...args);
  };

  return { listening, sendMessage };
};

export default useInplaySocket;
