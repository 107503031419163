import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledBetslipValueContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  p: '$4',
  width: '100%',
  gap: '$4',
  '@xs_sm': {
    fontSize: '$xxs',
    lineHeight: '$20',
    gap: '$1',
    backgroundColor: '$grayDark',
    m: '$2 $4',
    p: '$2 $3 $1',
    borderRadius: '$8',
    width: 'unset',
  },
});
