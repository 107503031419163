import { useRef } from 'react';

import { SPORT_GROUP_COMPONENTS } from '~components/molecules/PrematchMenu/constants';
import { SPORT_TAB_CONTENTS } from '~components/organisms/MobileTabContents';
import { QUERY_PARAMS } from '~constants/common';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import {
  removeLocationScrollPosition,
  setMobileActiveTab,
  setSportTabActiveContent,
} from '~store/slices/mobileSlice';
import { setReloadPrematch } from '~store/slices/prematchMenuSlice';
import { BreadcrumbItem } from '~types/breadcrumb';
import { StoreSportEvent } from '~types/events';
import { SPORT_MENUS } from '~types/sportMenus';
import { getPreparedParamsString } from '~utils/scrollPositionLocationUtils';
import { QueryParams } from '~utils/url';

import { useQueryParams } from './useQueryParams';

export const useInitializeBreadcrumbForSport = (
  sportId?: number,
  countryName?: string,
  eventData?: StoreSportEvent,
) => {
  const { navigateBack } = useRouterQuery();
  const { updateQueryParams } = useRouterQuery();
  const dispatch = useAppDispatch();
  const { breadcrumbLabel } = useAppSelector((state) => state.menus);
  const { localized } = useTranslation();
  const mainMarkets = useAppSelector((state) => state.mainMarkets.mainMarkets);
  const search = useQueryParams();
  const { previousLocation } = useAppSelector((state) => state.history);
  const locationBeforePrevios = useRef(previousLocation);

  const prematchLabelsMap: Record<SPORT_GROUP_COMPONENTS, string> = {
    [SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT]: localized(
      'prematch.favoriteTournaments',
    ),
    [SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT]: localized(
      'prematch.topTournaments',
    ),
    [SPORT_GROUP_COMPONENTS.FAVORITE_EVENT]: localized(
      'prematch.favoriteEvents',
    ),
    [SPORT_GROUP_COMPONENTS.TOP_EVENT]: localized('prematch.topEvents'),
  };

  const initializeBreadcrumb = (
    sportIdProp?: string | undefined,
    countryNameProp?: string | undefined,
  ) => {
    const sportIdValue = sportId || sportIdProp;
    const countryNameValue = countryName || countryNameProp;

    if (!sportIdValue || !countryNameValue) return;
    const searchCopy = { ...search };

    delete searchCopy.eventId;

    const sport = mainMarkets.find((s) => s.id === sportIdValue);
    const { name: sportName } = sport || {};

    if (sportName) {
      const trail: BreadcrumbItem[] = [
        {
          label: localized(
            breadcrumbLabel
              ? prematchLabelsMap[breadcrumbLabel as SPORT_GROUP_COMPONENTS]
              : 'mobile.breadcrumbs.sports',
          ),
          onClickCallback: () => {
            dispatch(
              removeLocationScrollPosition(getPreparedParamsString(searchCopy)),
            );
            const { sportId, countryId } = searchCopy;

            if (!locationBeforePrevios.current) {
              updateQueryParams(
                {
                  sportId,
                  countryId,
                  menu: SPORT_MENUS.PREMATCH,
                } as QueryParams<typeof QUERY_PARAMS>,
                true,
              );
            } else {
              dispatch(setReloadPrematch(false));
              if (
                locationBeforePrevios.current &&
                !locationBeforePrevios.current.pathname.includes('leagueId')
              ) {
                updateQueryParams(
                  {
                    sportId,
                    countryId,
                    menu: SPORT_MENUS.PREMATCH,
                  } as QueryParams<typeof QUERY_PARAMS>,
                  true,
                );
              } else {
                navigateBack();
              }
            }
          },
        },
        {
          label: `${sportName} - ${countryNameValue}`,
          onClickCallback: () => {
            dispatch(
              removeLocationScrollPosition(getPreparedParamsString(searchCopy)),
            );
            if (
              previousLocation &&
              !previousLocation.search.includes('sportId')
            ) {
              navigateBack();
              dispatch(setMobileActiveTab(null));

              return;
            }

            if (locationBeforePrevios.current) {
              navigateBack();
            } else {
              const { sportId, countryId, leagueId } = searchCopy;

              updateQueryParams(
                {
                  sportId,
                  countryId,
                  leagueId,
                  menu: SPORT_MENUS.PREMATCH,
                } as QueryParams<typeof QUERY_PARAMS>,
                true,
              );
            }
          },
        },
      ];

      if (eventData && !breadcrumbLabel) {
        const { name } = eventData || {};

        trail.push({
          label: name || '',
        });
      }

      if (!eventData) {
        dispatch(setSportTabActiveContent(SPORT_TAB_CONTENTS.PREMATCH_EVENTS));
      }

      dispatch(setBreadcrumbTrail(trail));
    }
  };

  return {
    initializeBreadcrumb,
  };
};
