import { useState } from 'react';

export enum PAYMENT_ACCOUNTS_TYPES {
  CARD,
  BANK,
}

export interface PaymentAccountButton {
  value: PAYMENT_ACCOUNTS_TYPES;
  label: string;
}

const PAYMENT_ACCOUNTS_TYPES_BUTTONS: PaymentAccountButton[] = [
  { value: PAYMENT_ACCOUNTS_TYPES.CARD, label: 'paymentAccounts.cardsLabel' },
  { value: PAYMENT_ACCOUNTS_TYPES.BANK, label: 'paymentAccounts.banksLabel' },
];

export const useMobilePaymentAccounts = () => {
  const [activePaymentsTab, setActivePaymentsTab] = useState(
    PAYMENT_ACCOUNTS_TYPES.CARD,
  );

  const handleChangeTab = (tab: string) => {
    const numberTab = Number(tab) as PAYMENT_ACCOUNTS_TYPES;

    setActivePaymentsTab(numberTab);
  };

  return {
    activePaymentsTab,
    tabs: PAYMENT_ACCOUNTS_TYPES_BUTTONS,
    onTabChange: handleChangeTab,
  };
};
