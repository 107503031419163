import React from 'react';
import { COLOR_KEYS } from 'stitches.config';

import { Transaction } from '~api/transaction/types';
import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { useTranslation } from '~hooks/useTranslation';
import { CopyToClipboardIcon, GreenCheckIcon } from '~icons';
import { useAppSelector } from '~store';
import {
  statusTranslationsMap,
  TRANSACTION_STATUS_CODE_COLORS,
  TRANSACTION_STATUS_ICONS,
  transactionsTranslationsMap,
  transactionsTypeColorsMap,
} from '~types/transactions';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { commaSeparatedNumber } from '~utils/numberUtils';

interface TransactionHistoryListItemProps {
  transaction: Transaction;
  isAllTransactions?: boolean;
}

export const TransactionHistoryListItem = ({
  transaction,
  isAllTransactions = false,
}: TransactionHistoryListItemProps) => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);
  const { id, status, createdAt, amount, balanceAfter, balanceBefore, type } =
    transaction;
  const StatusIcon =
    TRANSACTION_STATUS_ICONS[status as keyof typeof TRANSACTION_STATUS_ICONS];
  const { handleCopyId, isCopied } = useCopyToClipboard();

  return (
    <Box
      fullWidth
      flexCol
      alignCenter
      justifyContentBetween
      css={{
        p: '$2 $3',
        background: '$grayDarkMain',
        borderRadius: '$6',
        mt: '$2',
      }}
    >
      <Box fullWidth flexRow justifyContentBetween alignCenter>
        <Box
          flexRow
          css={{
            maxWidth: '200px',
          }}
        >
          <Box flexRow alignCenter gap={2}>
            <Box flexRow css={{ color: '$grayMedium' }}>
              {StatusIcon && <StatusIcon />}
            </Box>
            <Box flexCol>
              <Text color="grayMedium" level="12-20">
                {formatDateTime(
                  createdAt,
                  TIME_FORMATS.DATE_TIME_24H_WITH_SECONDS,
                )}
              </Text>
              <Text
                color="grayMedium"
                level="12-20"
                ellipsis
                css={{
                  width: '150px',
                }}
              >
                ID: {id.replace(/-/g, '')}
              </Text>
            </Box>
          </Box>
          <Box
            flexRow
            css={{
              position: 'relative',
              top: '$5',
              right: '-$1',
              cursor: 'pointer',
              color: '$grayMedium',
              width: '$3',
            }}
            onClick={handleCopyId(id)}
          >
            {isCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
          </Box>
        </Box>
        <Box flexCol alignEnd>
          <Text color={TRANSACTION_STATUS_CODE_COLORS[status]} level="12-20">
            {localized(statusTranslationsMap[status])}
          </Text>
          {isAllTransactions && (
            <Text
              level="12-20"
              color={transactionsTypeColorsMap[type] as COLOR_KEYS}
            >
              {localized(transactionsTranslationsMap[type])}
            </Text>
          )}
        </Box>
      </Box>
      {!isAllTransactions && (
        <>
          <Separator
            verticalSpace={2}
            shrinkOut={3}
            css={{
              backgroundColor: '$grayDarker',
              ml: 0,
            }}
          />
          <Box flexRow justifyContentBetween fullWidth alignCenter>
            <Text color="grayMedium" level="12-20">
              {localized('transactionHistory.table.type')}
            </Text>
            <Text level="12-20">
              {localized(transactionsTranslationsMap[type])}
            </Text>
          </Box>
        </>
      )}
      <Separator
        verticalSpace={2}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
          ml: 0,
        }}
      />
      <Box flexRow justifyContentBetween fullWidth alignCenter>
        <Text color="grayMedium" level="12-20">
          {localized('transactionHistory.table.amount')}
        </Text>
        <Text level="12-20">
          {commaSeparatedNumber(amount)} {CURRENCY_SYMBOLS[currency]}
        </Text>
      </Box>
      <Separator
        verticalSpace={2}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
          ml: 0,
        }}
      />
      {isAllTransactions && (
        <>
          <Box flexRow justifyContentBetween fullWidth alignCenter>
            <Text color="grayMedium" level="12-20">
              {localized('transactionHistory.table.balanceBefore')}
            </Text>
            <Text level="12-20">
              {commaSeparatedNumber(balanceBefore)} {CURRENCY_SYMBOLS[currency]}
            </Text>
          </Box>
          <Separator
            verticalSpace={2}
            shrinkOut={3}
            css={{
              backgroundColor: '$grayDarker',
              ml: 0,
            }}
          />
        </>
      )}
      <Box flexRow justifyContentBetween fullWidth alignCenter>
        <Text level="12-20">
          {localized('transactionHistory.table.finalBalance')}
        </Text>
        <Text level="12-20" color="yellow">
          {commaSeparatedNumber(balanceAfter)} {CURRENCY_SYMBOLS[currency]}
        </Text>
      </Box>
    </Box>
  );
};
