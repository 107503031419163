import { ReactNode, useState } from 'react';

import {
  StyledShowMoreButton,
  StyledShowMoreButtonTrigger,
  StyledShowMorePanel,
  StyledShowMorePanelWrapper,
} from '~components/atoms/ShowMorePanel/styled.components';
import { useTranslation } from '~hooks/useTranslation';
import { ChevronDownIcon } from '~icons';

interface ShowPanelProps {
  children: ReactNode;
  height?: number;
  customShowText?: string;
  customHideText?: string;
}

export const ShowMorePanel = ({
  children,
  height = 64,
  customShowText,
  customHideText,
}: ShowPanelProps) => {
  const { localized } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const showText = customShowText || localized('buttons.show');
  const hideText = customHideText || localized('buttons.hide');

  return (
    <StyledShowMorePanelWrapper open={isOpen}>
      <StyledShowMorePanel
        open={isOpen}
        css={{
          maxHeight: isOpen ? 'none' : `${height}px`,
        }}
      >
        {children}
      </StyledShowMorePanel>
      <StyledShowMoreButton
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? hideText : showText}
        <StyledShowMoreButtonTrigger open={isOpen}>
          <ChevronDownIcon />
        </StyledShowMoreButtonTrigger>
      </StyledShowMoreButton>
    </StyledShowMorePanelWrapper>
  );
};
