import { useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { SportListItems } from '~components/molecules/LiveMenu/types';
import { preparePrematchUpcomingHighlightsData } from '~components/molecules/MainHighlights/helpers';
import { PrematchTopEventsData } from '~constants/mainPage';
import { useAppDispatch, useAppSelector } from '~store';
import { addEventsWithMarkets } from '~store/slices/eventsSlice';
import {
  setTopPrematchEventsOpenDateGroupsBySport,
  setTopPrematchEventsOpenDateGroupsBySports,
} from '~store/slices/sportGroupsSlice';

export const useTopEventGroupSportMobile = () => {
  const getInitialTopEventsData = (): PrematchTopEventsData => {
    const { data: preparedData } =
      preparePrematchUpcomingHighlightsData(topSportEvents);

    return preparedData;
  };
  const dispatch = useAppDispatch();
  const [showSports, setShowSports] = useState(false);

  const [mainSportMarket, setMainSportMarket] = useState<
    MainMarket | undefined
  >(undefined);
  const { topSportEvents, topPrematchEventsOpenDateGroupsBySport } =
    useAppSelector((state) => state.sportGroupsState);
  const [topEventsData, setTopEventsData] = useState<PrematchTopEventsData>(
    getInitialTopEventsData(),
  );
  const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);

  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const [selectedSportIndex, setSelectedSportIndex] = useState(0);

  const sports: SportListItems = topSportEvents.map(
    ({ sportName, sportId, dateGroups }) => ({
      sportName,
      sportId,
      eventsAmount: dateGroups.reduce(
        (acc, { matches }) => acc + matches.length,
        0,
      ),
    }),
  );

  const { sportId, sportName } = topSportEvents[selectedSportIndex] || {};

  const openDateGroups = topPrematchEventsOpenDateGroupsBySport.find(
    ({ sportId: id }) => id === sportId!.toString(),
  )?.dateGroups;

  const handleSportMenuClick = (index: number) => {
    setShowSports(false);
    setSelectedSportIndex(index);
  };

  const handleDateGroupOpenValuesChange = (value: string[]) => {
    dispatch(
      setTopPrematchEventsOpenDateGroupsBySport({
        sportId: sportId!.toString(),
        dateGroups: value,
      }),
    );
  };

  useEffect(() => {
    const mainSportMarketId = mainMarketsSelected[sportId!];
    const { markets = [] } =
      mainMarkets.find((item) => item.id === sportId) || {};

    if (markets.length) {
      const mainSportMarket = markets.find(
        (item) => item.id === mainSportMarketId,
      );

      setMainSportMarket(mainSportMarket!);
    }
  }, [mainMarketsSelected, mainMarkets, sportId]);

  useEffect(() => {
    if (topSportEvents?.length) {
      const {
        data: preparedData,
        events,
        markets,
      } = preparePrematchUpcomingHighlightsData(topSportEvents);

      const allDateGroupsInSports = topSportEvents.map(
        ({ sportId, dateGroups }) => {
          return {
            sportId: sportId.toString(),
            dateGroups: dateGroups.map(({ date }) => date),
          };
        },
      );

      setTopEventsData(preparedData);
      dispatch(addEventsWithMarkets({ events, markets }));
      dispatch(
        setTopPrematchEventsOpenDateGroupsBySports(allDateGroupsInSports),
      );
    }
  }, [topSportEvents]);

  return {
    sports,
    topEventsData: topEventsData,
    sportId,
    showSports,
    selectedSportIndex,
    sportName,
    mainSportMarket,
    openDateGroups,
    setShowSports,
    handleSportMenuClick,
    handleDateGroupOpenValuesChange,
  };
};
