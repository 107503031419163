import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';

import { UserSession } from './UserSession';
import { useUserSessions } from './useUserSessions';

export const UserSessionsMobile = () => {
  const {
    localized,
    activeSessions,
    previousSessions,
    handleTerminateOtherSessions,
  } = useUserSessions();

  return (
    <Box
      flexCol
      css={{
        p: '$2 $3 $3',
        backgroundColor: '$userProfileWidgetBgColorMobile',
        borderRadius: '$8',
      }}
    >
      <Box flexCol gap={2}>
        <Text
          level="12-20"
          textTransform="uppercase"
          color="grayMedium"
          css={{ pl: '$3' }}
        >
          {localized(
            'userProfile.personalProfileInfo.accountSettings.recentSessions.activeSessions',
          )}
        </Text>
        <Box flexCol gap={1}>
          <Button
            size="small"
            variant="secondary"
            fullWidth
            css={{
              height: '$7',
              borderRadius: '$6',
              mb: '$1',
              backgroundColor: '$gray',
            }}
            onClick={handleTerminateOtherSessions}
          >
            <Text level="13-20" fontWeight="medium" textAlign="center">
              {localized(
                'userProfile.personalProfileInfo.accountSettings.recentSessions.endAllSessions',
              )}
            </Text>
          </Button>
          {activeSessions?.map((session, index) => (
            <UserSession key={index} session={session} />
          ))}
        </Box>
      </Box>
      <Separator
        verticalSpace={3}
        shrinkOut={3}
        css={{ backgroundColor: '$grayDarker' }}
      />
      <Box flexCol gap={2}>
        <Text
          level="12-20"
          textTransform="uppercase"
          color="grayMedium"
          css={{ pl: '$3' }}
        >
          {localized(
            'userProfile.personalProfileInfo.accountSettings.recentSessions.sessionsHistory',
          )}
        </Text>
        <Box flexCol gap={1}>
          {previousSessions?.map((session, index) => (
            <UserSession key={index} session={session} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
