import { ExistingPaymentMethod, SavedBank } from '~api/transaction/types';
import { Box } from '~components/atoms/Box';
import { CardMask } from '~components/atoms/CardMask';
import { Cross } from '~components/atoms/Cross';
import {
  RadioGroupRoot,
  StyledRadioGroupItem,
  StyledRadioGroupItemIndicator,
} from '~components/atoms/RadioGroup';
import { Text } from '~components/atoms/Typography';
import { CARD_TYPES } from '~constants/payments';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { MasterCardIcon, VisaIcon } from '~icons';
import { SVGElement } from '~types/general';
import { isSavedBank } from '~utils/typeguars';

interface PaymentAccountsRadiosProps {
  items: (ExistingPaymentMethod | SavedBank)[] | null;
  emptyTitle: string;
  emptyDescription: string;
  onChange: (value: string) => void;
  onRemove: (id: string) => void;
  isBanks?: boolean;
}

export const PaymentAccountsRadios = ({
  items,
  emptyTitle,
  emptyDescription,
  onChange,
  onRemove,
}: PaymentAccountsRadiosProps) => {
  const { isMobileOrTablet } = useMedia();

  let defaultId = '';

  if (items && items?.length > 0) {
    const defaultItem = items.find(({ isDefault }) => isDefault);

    if (defaultItem) {
      defaultId = defaultItem.id;
    }
  }

  const { localized } = useTranslation();

  const renderCard = (item: ExistingPaymentMethod) => {
    const {
      cardType: initialCardType,
      expiry,
      id,
      lastDigits,
      isDefault: isDefaultProp,
    } = item;
    const cardType = initialCardType?.toLowerCase().trim();

    const cardsIconMap: Record<CARD_TYPES, SVGElement> = {
      [CARD_TYPES.VISA]: VisaIcon,
      [CARD_TYPES.MASTERCARD]: MasterCardIcon,
    };
    const Icon = cardsIconMap[cardType as CARD_TYPES];
    const cardDescription = `${lastDigits}, ${localized(
      'payments.expires',
    )} ${expiry}`;

    return (
      <Box
        key={id}
        flexRow
        alignCenter
        css={{
          p: '$2 $3',
          background: '$blueDark',
          borderRadius: '$6',
          position: 'relative',
        }}
      >
        <Box
          flexRow
          alignCenter
          gap={3}
          fullHeight
          css={{ position: 'relative' }}
        >
          <StyledRadioGroupItem className="RadioGroupItem" value={id}>
            <StyledRadioGroupItemIndicator />
          </StyledRadioGroupItem>
          {isMobileOrTablet ? (
            <Box flexCol justifyContentStart alignStart>
              <Box flexRow gap={1} alignCenter>
                {Icon && <Icon />}
                <CardMask />
                <Text level="14-24" fontWeight="medium">
                  {lastDigits}
                </Text>
              </Box>
              <Text level="11-20">
                {`${localized('payments.Expires')} ${expiry}`}
              </Text>
            </Box>
          ) : (
            <Box flexRow justifyContentStart alignCenter gap={1}>
              {Icon && <Icon />}
              <CardMask />
              <Text level="14-20">{cardDescription}</Text>
            </Box>
          )}
        </Box>
        {!isDefaultProp && (
          <Cross
            onClick={() => onRemove(id)}
            css={{
              top: 'unset',
              bottom: 'unset',
            }}
          />
        )}
      </Box>
    );
  };

  const renderBank = (item: SavedBank) => {
    const {
      id,
      bankId,
      bankName,
      accountNumber,
      isDefault: isDefaultProp,
    } = item;
    const accountLastDigits = accountNumber?.slice(-4);

    return (
      <Box
        key={bankId}
        flexRow
        alignCenter
        css={{
          p: '$2 $3',
          background: '$blueDark',
          borderRadius: '$6',
          position: 'relative',
        }}
      >
        <Box
          flexRow
          alignCenter
          gap={3}
          fullHeight
          css={{ position: 'relative' }}
        >
          <StyledRadioGroupItem className="RadioGroupItem" value={id}>
            <StyledRadioGroupItemIndicator />
          </StyledRadioGroupItem>
          {isMobileOrTablet ? (
            <Box flexCol justifyContentStart alignStart>
              <Text level="11-20">{bankName}</Text>
              <Box flexRow gap={1} alignCenter>
                <CardMask />
                <Text level="14-24" fontWeight="medium">
                  {accountLastDigits}
                </Text>
              </Box>
            </Box>
          ) : (
            <Box flexRow justifyContentStart alignCenter gap={1}>
              <Text level="14-20">{bankName},</Text>
              <CardMask />
              <Text level="14-20">{accountLastDigits}</Text>
            </Box>
          )}
        </Box>
        {!isDefaultProp && (
          <Cross
            onClick={() => onRemove(id)}
            css={{
              top: 'unset',
              bottom: 'unset',
            }}
          />
        )}
      </Box>
    );
  };

  if (!items || !items.length) {
    return (
      <Box fullWidth flexCol justifyCenter alignCenter css={{ p: '$3' }}>
        <Box
          fullWidth
          flexCol
          justifyCenter
          alignCenter
          gap={2}
          css={{
            p: '$7',
            '@xs_sm': {
              p: '$4',
              borderRadius: '$8',
              background: '$blueDark',
            },
          }}
        >
          <Text level="16-20" color="grayMedium" fontWeight="medium">
            {localized(emptyTitle)}
          </Text>
          <Text level="13-20" color="grayMedium">
            {localized(emptyDescription)}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <RadioGroupRoot value={defaultId} onValueChange={onChange}>
      <Box
        flexCol
        css={{
          p: '0 $3 $2',
        }}
        gap={1}
      >
        {items.map((item) =>
          isSavedBank(item) ? renderBank(item) : renderCard(item),
        )}
      </Box>
    </RadioGroupRoot>
  );
};
