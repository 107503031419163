import { BetslipHistoryItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { InfoIcon } from '~icons';

import { BetslipHistoryMobileItemDetails } from './BetslipHistoryMobileItemDetails';
import { BetslipHistoryMobileItemMain } from './BetslipHistoryMobileItemMain';
import {
  AccordionRoot,
  StyledAccordionItem,
  StyledAccordionTrigger,
  StyledCollapsibleContent,
} from './styled.components';

interface BetslipHistoryMobileItemProps {
  betslipHistoryItem: BetslipHistoryItem;
}

export const BetslipHistoryMobileItem = ({
  betslipHistoryItem,
}: BetslipHistoryMobileItemProps) => {
  const { localized } = useTranslation();
  const { id, details } = betslipHistoryItem;

  return (
    <AccordionRoot type="multiple" key={id}>
      <Box
        flexCol
        key={id}
        css={{
          borderRadius: '$8',
          backgroundColor: '$betslipHistoryMobileItemBgColor',
          color: '$grayMedium',
          p: '6px $3 $3',
        }}
      >
        <BetslipHistoryMobileItemMain betslipHistoryItem={betslipHistoryItem} />
        <StyledAccordionItem value={id}>
          <StyledAccordionTrigger>
            <Box flexRow justifyContentBetween>
              <Box flexRow alignCenter gap={2}>
                <Box flexRow css={{ color: 'inherit' }}>
                  <InfoIcon width={16} height={16} />
                </Box>
                <Text level="12-20">
                  {localized('betslipHistory.mobile.betDetails')}
                </Text>
              </Box>
              <Tag
                text={details?.length}
                color="gray"
                css={{ color: 'inherit' }}
              />
            </Box>
          </StyledAccordionTrigger>
          <StyledCollapsibleContent>
            <Box
              flexCol
              gap={1}
              css={{
                p: '$2',
                borderRadius: '$8',
                backgroundColor: '$gray',
                mt: '$2',
              }}
            >
              {details.map((detail, index) => (
                <BetslipHistoryMobileItemDetails
                  key={index}
                  details={detail}
                  index={index}
                />
              ))}
            </Box>
          </StyledCollapsibleContent>
        </StyledAccordionItem>
      </Box>
    </AccordionRoot>
  );
};
