import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

import {
  StyledActionButton,
  StyledInput,
  StyledInputContainer,
  StyledSuccessMessage,
} from './styled.components';
import { InputWithButtonProps } from './types';

export const InputWithButton = ({
  input,
  button,
  isSuccess,
  successText,
}: InputWithButtonProps) => {
  const { customComponent, placeholder, onChange, isError } = input;
  const { text, onClick, isDisabled, isLoading } = button;

  return (
    <StyledInputContainer>
      {!isSuccess ? (
        <>
          <Box flexRow fullWidth>
            {customComponent || (
              <StyledInput
                placeholder={placeholder}
                onChange={onChange}
                css={{
                  color: isError ? '$red' : '$white',
                  backgroundColor: 'transparent',
                }}
              />
            )}
          </Box>

          <StyledActionButton
            onClick={onClick}
            disabled={isDisabled}
            isLoading={isLoading}
          >
            {text}
          </StyledActionButton>
        </>
      ) : (
        <StyledSuccessMessage>
          <Text level="18-24" fontWeight="bold" textTransform="uppercase">
            {successText}
          </Text>
        </StyledSuccessMessage>
      )}
    </StyledInputContainer>
  );
};
