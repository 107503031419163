import React from 'react';

import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import {
  MobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { SVGElement } from '~types/general';

import { USER_PROFILE_DIALOG_MOBILE_STATE } from '../UserProfileDialogMobile';

import { StyledNavigationContainer } from './styled.components';

interface NavigationItemMobileProps {
  value: string;
  title?: string;
  icon?: SVGElement;
  sub?: boolean;
}

export const NavigationItemMobile = ({
  value,
  title,
  icon: Icon,
  sub,
}: NavigationItemMobileProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClick = (value: MobileUserProfileDialogActiveContent) => {
    dispatch(
      setMobileUserProfileDialogState(USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT),
    );
    dispatch(setMobileUserProfileDialogActiveContent(value));
  };

  return (
    <StyledNavigationContainer
      flexRow
      key={value}
      onClick={() => handleClick(value as MobileUserProfileDialogActiveContent)}
      gap={2}
      alignCenter
      sub={sub}
      mobile
    >
      {Icon && <Icon className="icon" />}
      <Text level="14-20">{localized(title!)}</Text>
    </StyledNavigationContainer>
  );
};
