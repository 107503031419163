import { Fragment, memo, useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { Text } from '~components/atoms/Typography';
import {
  iconsMap,
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/HeaderComponent/Header/useHeaderMenu';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { getIsMenuItemActiveCasino } from '~utils/menu';

export type MenuItemProps = {
  name: string;
  url: string;
  icon: string;
  isLaptop: boolean;
  isMenuItemActive: boolean;
};

const MenuItem = memo(
  ({ name, url, icon, isLaptop, isMenuItemActive }: MenuItemProps) => {
    const Icon = iconsMap[icon as MENU_ICON_TYPES];
    const { isMobileOrTablet } = useMedia();

    return (
      <NavigationLink
        style={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: isMobileOrTablet ? 0 : isLaptop ? '10px' : '12px',
          color: isMenuItemActive ? '$yellow' : '$white',
        }}
        to={url || ''}
        key={name}
      >
        <Box
          css={{
            mt: '$1',
            color: isMenuItemActive ? '$yellow' : '$white',
          }}
          as={'span'}
        >
          {Icon && !isMobileOrTablet ? (
            <Icon width={isLaptop ? 20 : 24} height={isLaptop ? 20 : 24} />
          ) : null}
        </Box>
        <Text
          color={isMenuItemActive ? 'yellow' : 'white'}
          css={{
            fontWeight: isMenuItemActive ? '$medium' : '$normal',
            whiteSpace: 'nowrap',
          }}
          level={isLaptop ? '14-20' : '18-24'}
        >
          {name}
        </Text>
      </NavigationLink>
    );
  },
);

export const HeaderMenuCasino = () => {
  const { menu: menuQuery } = useQueryParams();
  const { menu } = useHeaderMenu();
  const { isLaptop, isMobileOrTablet } = useMedia();

  const menuContent = useMemo(() => {
    return menu.map(({ name, url = '', icon = '' }, index) => {
      const isMenuItemActive = getIsMenuItemActiveCasino(
        icon as MENU_ICON_TYPES,
        url || '',
      );

      return (
        <Fragment key={name}>
          <MenuItem
            key={name}
            name={name}
            url={url}
            icon={icon}
            isLaptop={isLaptop}
            isMenuItemActive={isMenuItemActive}
          />
          {isMobileOrTablet && index < menu.length - 1 ? (
            <Box
              css={{
                width: '1px',
                height: '12px',
                backgroundColor: '$gray',
              }}
            />
          ) : null}
        </Fragment>
      );
    });
  }, [menu, menuQuery, isLaptop, isMobileOrTablet, window.location.pathname]);

  return isMobileOrTablet ? (
    <TouchSlider type="menu">{menuContent}</TouchSlider>
  ) : (
    <Box
      flexRow
      css={{
        gap: isLaptop ? '36px' : '24px',
        '& a': {
          cursor: 'pointer',
        },
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {menuContent}
    </Box>
  );
};
