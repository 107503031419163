import { MENU_ICON_TYPES } from '~components/organisms/HeaderComponent/Header/useHeaderMenu';

export const getIsMenuItemActive = (
  item: MENU_ICON_TYPES,
  url: string,
  menuQuery: string = '',
): boolean => {
  switch (item) {
    case MENU_ICON_TYPES.Inplay:
    case MENU_ICON_TYPES.Sports: {
      return !!(menuQuery.length && url.includes(menuQuery));
    }

    default: {
      return window.location.href.includes(url);
    }
  }
};

export const getIsMenuItemActiveCasino = (
  item: MENU_ICON_TYPES,
  url: string,
): boolean => {
  switch (item) {
    case MENU_ICON_TYPES.Aviator: {
      const aviatorGamePath = url.split('/').pop();

      return window.location.pathname.includes(aviatorGamePath || '');
    }

    case MENU_ICON_TYPES.Casino: {
      return ['/', '/casino'].includes(window.location.pathname);
    }

    case MENU_ICON_TYPES.VirtualSports: {
      return window.location.pathname.includes('virtual');
    }

    case MENU_ICON_TYPES.Jackpot: {
      return window.location.pathname.includes('jackpot');
    }

    case MENU_ICON_TYPES.LiveCasino: {
      return window.location.pathname.includes('live');
    }

    default: {
      return window.location.href.includes(url);
    }
  }
};
