import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BannerGroup, ContentMenus, MenuContents } from '~api/content/types';

export interface UserUIState {
  isBalanceVisible: boolean;
  betslipFooterHeight: number;
  bannerData: Record<string, BannerGroup>;
  menuContents: Record<string, ContentMenus>;
  personalProfileUpdateError: string | null;
  isDialogScrolled: boolean;
  isWindowScrolled: boolean;
}

const initialState: UserUIState = {
  isBalanceVisible: true,
  betslipFooterHeight: 0,
  bannerData: {},
  menuContents: {},
  personalProfileUpdateError: null,
  isDialogScrolled: false,
  isWindowScrolled: false,
};

export const userUISlice = createSlice({
  name: 'userUI',
  initialState,
  reducers: {
    setBalanceVisibility: (state, action: PayloadAction<boolean>) => {
      state.isBalanceVisible = action.payload;
    },
    setBetslipFooterHeight: (state, action: PayloadAction<number>) => {
      state.betslipFooterHeight = action.payload;
    },
    setBannerData: (
      state,
      action: PayloadAction<Record<string, BannerGroup>>,
    ) => {
      state.bannerData = {
        ...state.bannerData,
        ...action.payload,
      };
    },
    setMenuContents: (state, action: PayloadAction<MenuContents>) => {
      action.payload.forEach(({ name, columns }) => {
        state.menuContents[name] = columns;
      });
    },
    setPersonalProfileUpdateError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.personalProfileUpdateError = action.payload;
    },
    setDialogScrolled: (state, action: PayloadAction<boolean>) => {
      state.isDialogScrolled = action.payload;
    },
    setWindowScrolled: (state, action: PayloadAction<boolean>) => {
      state.isWindowScrolled = action.payload;
    },
  },
});

export const {
  setBalanceVisibility,
  setBetslipFooterHeight,
  setMenuContents,
  setBannerData,
  setPersonalProfileUpdateError,
  setDialogScrolled,
  setWindowScrolled,
} = userUISlice.actions;

export default userUISlice.reducer;
