import React from 'react';

import { DOCUMENT_STATUS } from '~api/user/types';
import { AttachFileInput } from '~components/atoms/AttachFileInput';
import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { ShowMorePanel } from '~components/atoms/ShowMorePanel';

import { StyledDocumentStatusValue } from '../../../components/styled.components';
import { useUserVerificationData } from '../../../hooks/useUserVerificationData';
import {
  AccountVerificationCriteria,
  DocumentDownloadButton,
  DocumentTypeSelect,
  DocumentUploadButton,
} from '../components';
import {
  StyledUserFileUploadCard,
  StyledUserFileUploadCardRow,
  StyledUserFileUploadCardRowKey,
  StyledUserFileUploadCardRowValue,
} from '../components/styled.components';
import { useAccountVerificationContent } from '../hooks/useAccountVerificationContent';

export const AccountVerificationContentMobile = () => {
  const {
    localized,
    documents,
    documentType,
    documentTypeOptions,
    handleFileAttach,
    handleFileUpload,
    handleDocumentTypeChange,
    file,
  } = useAccountVerificationContent();
  const { preparedData } = useUserVerificationData(documents);

  return (
    <Box flexCol gap={2}>
      <ShowMorePanel>
        <AccountVerificationCriteria />
      </ShowMorePanel>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          p: '$3',
          backgroundColor: '$userProfileWidgetBgColorMobile',
          borderRadius: '$8',
        }}
      >
        <Box flexCol gap={3}>
          <Box flexCol gap={2}>
            <Box>
              <DocumentTypeSelect
                documentType={documentType}
                documentTypeOptions={documentTypeOptions}
                handleDocumentTypeChange={handleDocumentTypeChange}
              />
            </Box>
            <Box>
              <AttachFileInput
                onChange={handleFileAttach}
                value={file}
                placeholder={localized(
                  'userProfile.personalProfileInfo.accountVerification.documentFileUpload.placeholder',
                )}
              />
            </Box>
          </Box>
          <Box>
            <DocumentUploadButton
              documentType={documentType}
              file={file}
              handleFileUpload={handleFileUpload}
            />
          </Box>
        </Box>
        {preparedData.length > 0 && (
          <>
            <Separator
              verticalSpace={3}
              shrinkOut={3}
              css={{ backgroundColor: '$grayDarker' }}
            />
            <Box flexCol gap={2}>
              {preparedData.map(
                ({ id, documentType, date, status, url, documentName }) => (
                  <StyledUserFileUploadCard key={id}>
                    <StyledUserFileUploadCardRow>
                      <StyledUserFileUploadCardRowKey>
                        {localized(
                          'userProfile.personalProfileInfo.documentType',
                        )}
                      </StyledUserFileUploadCardRowKey>
                      <StyledUserFileUploadCardRowValue>
                        {localized(
                          `userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.${documentType}`,
                        )}
                      </StyledUserFileUploadCardRowValue>
                    </StyledUserFileUploadCardRow>
                    <StyledUserFileUploadCardRow>
                      <StyledUserFileUploadCardRowKey>
                        {localized('userProfile.personalProfileInfo.date')}
                      </StyledUserFileUploadCardRowKey>
                      <StyledUserFileUploadCardRowValue>
                        {date}
                      </StyledUserFileUploadCardRowValue>
                    </StyledUserFileUploadCardRow>
                    <StyledUserFileUploadCardRow>
                      <StyledUserFileUploadCardRowKey>
                        {localized('userProfile.personalProfileInfo.status')}
                      </StyledUserFileUploadCardRowKey>
                      <StyledUserFileUploadCardRowValue>
                        <StyledDocumentStatusValue
                          as="span"
                          fontWeight="medium"
                          status={status as DOCUMENT_STATUS}
                        >
                          {localized(
                            `userProfile.personalProfileInfo.accountVerification.documentStatus.${status}`,
                          )}
                        </StyledDocumentStatusValue>
                      </StyledUserFileUploadCardRowValue>
                    </StyledUserFileUploadCardRow>
                    <StyledUserFileUploadCardRow>
                      <DocumentDownloadButton
                        downloadFileName={documentName}
                        url={url}
                      />
                    </StyledUserFileUploadCardRow>
                  </StyledUserFileUploadCard>
                ),
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
