import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { setMainMarketsSelected } from '~store/slices/liveMenuSlice';

interface ToggleSportMarketProps {
  sportId: number;
  noMargin?: boolean;
}

const ToggleGroupItemComponent = ({
  market,
  mainMarketsSelected,
  sportId,
}: {
  market: MainMarket;
  mainMarketsSelected: Record<number, number>;
  sportId: number;
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { isMobileOrTablet } = useMedia();
  const spanRef = useRef<HTMLSpanElement | null>(null);

  const { name, shortName, id } = market;
  const showShortName =
    (isMobileOrTablet && shortName) || (isOverflowing && shortName);

  useLayoutEffect(() => {
    // Check if the span element is overflowing
    const spanElement = spanRef.current;

    if (spanElement) {
      setIsOverflowing(spanElement.scrollWidth > spanElement.clientWidth);
    }
  }, []);

  return (
    <ToggleGroupItem
      key={name}
      type="market"
      value={id}
      disabled={id === mainMarketsSelected[sportId]}
    >
      <Text ref={spanRef} as="span">
        {showShortName ? shortName : name}
      </Text>
    </ToggleGroupItem>
  );
};

export const ToggleSportMarket = memo(
  ({ sportId, noMargin }: ToggleSportMarketProps) => {
    const dispatch = useAppDispatch();
    const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);

    const { mainMarkets } = useAppSelector((state) => state.mainMarkets);

    const sportMainMarketSelected = mainMarketsSelected[sportId] || 0;
    const defaultMainMarketId =
      mainMarkets.find(({ id }) => id === sportId)?.markets[0]?.id || 0;

    const handleValueChange = (marketId: string) => {
      dispatch(
        setMainMarketsSelected({
          sportId: sportId,
          marketId: parseInt(marketId),
        }),
      );
    };

    const marketsItems = useMemo(() => {
      const _markets =
        mainMarkets.find(({ id }) => id === sportId)?.markets ?? [];

      return _markets.map((market) => (
        <ToggleGroupItemComponent
          key={market.id}
          market={market}
          mainMarketsSelected={mainMarketsSelected}
          sportId={sportId}
        />
      ));
    }, [mainMarkets, mainMarketsSelected, sportId]);

    const mainMarketValue =
      sportMainMarketSelected.toString() || defaultMainMarketId.toString();

    return (
      marketsItems.length > 1 && (
        <ToggleGroup
          type="single"
          variant="market"
          onValueChange={handleValueChange}
          value={mainMarketValue}
          rovingFocus={false}
          loop={false}
          css={{
            margin: noMargin ? '0' : '$2 0',
          }}
        >
          {marketsItems}
        </ToggleGroup>
      )
    );
  },
);
