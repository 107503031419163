import React, { MouseEvent } from 'react';

import { BetslipHistoryItem, OpenedBet } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { InfoPopover } from '~components/atoms/InfoPopover';
import { Separator } from '~components/atoms/Separator';
import { ShareLink } from '~components/atoms/ShareLink';
import { Text } from '~components/atoms/Typography';
import { SPORT_BETSLIP_TYPE_CODES } from '~components/molecules/Betslip/constants';
import { WinboostPopover } from '~components/WinboostPopover';
import { BETSLIP_STATUS } from '~constants/common';
import { useFormatAmountWithCurrency } from '~hooks';
import { useStakePayout } from '~hooks/useStakePayout';
import { useTranslation } from '~hooks/useTranslation';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { useAppDispatch } from '~store';
import { setCashOutBet } from '~store/slices/openBetSlice';
import { getShareBetData } from '~utils/betslip';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import {
  getBetType,
  getSectionName,
  getStatusIcon,
  getSystemBetOptionsLabel,
} from '../../historyUtils';
import { StatusCell } from '../BetslipHistoryTable/components/StatusCell';

interface BetslipHistoryMobileItemMainProps {
  betslipHistoryItem: BetslipHistoryItem;
}

export const BetslipHistoryMobileItemMain = ({
  betslipHistoryItem,
}: BetslipHistoryMobileItemMainProps) => {
  const dispatch = useAppDispatch();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();
  const { localized } = useTranslation();
  const { odds, getFormattedOdd } = useWebsiteSettings();

  const {
    status,
    createdAt,
    type,
    details,
    possibleWin,
    stake,
    totalOdd,
    winAmount,
    code,
    availableCashout,
    winBoostMultiplier,
    winBoostAmount,
    originalWinAmount,
    systemBetCombinationSize,
    possibleIncomeTax,
    incomeTaxPercent,
    stakeTaxPercent,
    stakeTax,
    stakeBeforeTax,
    stakeTaxPayer,
  } = betslipHistoryItem;

  const { showPayoutInfo, payoutInfoItems } = useStakePayout({
    stake,
    stakeAfterTax: stakeBeforeTax,
    stakeTax,
    stakeTaxPercent,
    stakeTaxPayer,
    possibleWin,
    possibleIncomeTax,
    incomeTaxPercent,
  });

  const StatusIcon = getStatusIcon(status);
  const isSystemBet = type === SPORT_BETSLIP_TYPE_CODES.SYSTEM;
  const isStatusOpen = status === 1;

  const handleCashOutClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setCashOutBet(betslipHistoryItem as unknown as OpenedBet));
  };

  let betTypeText = getBetType(type);

  if (isSystemBet) {
    betTypeText += ` - ${getSystemBetOptionsLabel(
      details.length,
      systemBetCombinationSize,
    )};`;
  }

  return (
    <>
      <Box flexRow alignCenter justifyContentBetween>
        <Box flexRow gap={2} alignCenter>
          {StatusIcon && <StatusIcon />}
          <Box flexRow css={{ maxWidth: '85px' }}>
            <Box flexCol>
              <Text level="12-20" color="grayMedium" ellipsis>
                {getSectionName(details)}
                {', '}
                {betTypeText}
              </Text>
              <Box flexRow alignCenter gap={1}>
                <Text
                  level="12-20"
                  color="grayMedium"
                  ellipsis
                  css={{ width: '85px' }}
                >
                  ID: {code}
                </Text>
                {status === 1 ? (
                  <ShareLink data={getShareBetData(code)} />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flexCol alignEnd>
          <StatusCell status={status} />
          <Text level="12-20" color="grayMedium">
            {formatDateTime(createdAt, TIME_FORMATS.DATE_TIME_12H)}
          </Text>
        </Box>
      </Box>
      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
          my: '6px',
        }}
      />
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {localized('betslipHistory.mobile.stake')}
        </Text>
        <Text level="12-20" fontWeight="medium">
          {getCommaSeparatedAmount(stake)}
        </Text>
      </Box>
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {localized('betslipHistory.mobile.totalOdd')}
        </Text>
        <Text level="12-20" color="yellow" fontWeight="medium">
          {isSystemBet ? '-' : getFormattedOdd(totalOdd, odds)}
        </Text>
      </Box>
      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
          my: '6px',
        }}
      />

      {/* Payout */}
      {showPayoutInfo && (
        <Box flexRow justifyContentBetween>
          <Box flexRow gap={2} alignCenter>
            <Text underline level="12-20">
              {localized('betslip.payout')}
            </Text>
            {!!winBoostMultiplier && status !== BETSLIP_STATUS.CASH_OUT && (
              <WinboostPopover
                status={status}
                winBoostAmount={winBoostAmount}
                winBoostMultiplier={winBoostMultiplier}
                originalWinAmount={originalWinAmount}
              />
            )}
            <InfoPopover
              title={`${localized('betslip.payoutPopover.info')}:`}
              itemsList={payoutInfoItems}
            />
          </Box>
          <Text level="12-20" color="yellow" fontWeight="medium">
            {getCommaSeparatedAmount(possibleWin - possibleIncomeTax)}
          </Text>
        </Box>
      )}

      {/* Win */}
      {!isStatusOpen && (
        <Box flexRow justifyContentBetween>
          <Box flexRow gap={2} alignCenter>
            <Text level="12-20">{localized('betslipHistory.mobile.win')}</Text>
            {!!winBoostMultiplier && status !== BETSLIP_STATUS.CASH_OUT && (
              <WinboostPopover
                status={status}
                winBoostAmount={winBoostAmount}
                winBoostMultiplier={winBoostMultiplier}
                originalWinAmount={originalWinAmount}
              />
            )}
          </Box>
          <Text level="12-20" color="yellow" fontWeight="medium">
            {getCommaSeparatedAmount(winAmount)}
          </Text>
        </Box>
      )}

      {/* Possible win */}
      {!showPayoutInfo && isStatusOpen && (
        <Box flexRow justifyContentBetween>
          <Box flexRow gap={2} alignCenter>
            <Text level="12-20">
              {localized('betslipHistory.mobile.possibleWin')}
            </Text>
            {!!winBoostMultiplier && (
              <WinboostPopover
                status={status}
                winBoostAmount={winBoostAmount}
                winBoostMultiplier={winBoostMultiplier}
                originalWinAmount={originalWinAmount}
              />
            )}
          </Box>
          <Text level="12-20" color="yellow" fontWeight="medium">
            {getCommaSeparatedAmount(possibleWin)}
          </Text>
        </Box>
      )}

      {isStatusOpen && availableCashout ? (
        <Button
          fullWidth
          onClick={handleCashOutClick}
          css={{
            p: '1$',
            gap: '$1',
            my: '$1',
            backgroundColor: '$greenColorIndicator',
          }}
        >
          <Text color="white" level="14-20">
            {localized('betslip.cashOut')}
          </Text>
          <Text level="14-20" color="yellow">
            {getCommaSeparatedAmount(availableCashout)}
          </Text>
        </Button>
      ) : null}
      <Separator
        verticalSpace={2}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
        }}
      />
    </>
  );
};
