import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';

import { ActiveBonusCard } from '../ActiveBonusCard';

import { useActiveBonusesContent } from './useActiveBonusesContent';

export const ActiveBonusesContentMobile = () => {
  const { bonuses, isBonusesLoading, localized } = useActiveBonusesContent();

  return (
    <Box flexCol>
      {isBonusesLoading ? (
        <Box flexCol alignCenter gap={3} css={{ py: '32px' }}>
          <Loader
            css={{ color: '$grayMedium', width: '23px', height: '23px' }}
          />
          <Text color="grayMedium" level="12-20">
            {localized('bonuses.loading')}
          </Text>
        </Box>
      ) : !bonuses.length ? (
        <Box
          flexRow
          alignCenter
          justifyCenter
          css={{ py: '32px', backgroundColor: '$blueDark', borderRadius: '$8' }}
        >
          <Text color="grayMedium" level="14-20" fontWeight="medium">
            {localized('bonuses.noActiveBonuses')}
          </Text>
        </Box>
      ) : (
        <Box fullHeight css={{ p: '$2 0 $1' }}>
          <ScrollArea>
            <Box flexCol gap={2}>
              {bonuses.map((bonus) => (
                <ActiveBonusCard key={bonus.id} bonus={bonus} />
              ))}
            </Box>
          </ScrollArea>
        </Box>
      )}
    </Box>
  );
};
