import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Market, SportEvent } from '~types/events';

import { SelectionItem } from './SelectionItem';

interface MarketContentProps {
  marketsData: Market[];
  eventId: string;
  eventData: SportEvent;
}

export const MarketContent = memo(
  ({ marketsData, eventId, eventData }: MarketContentProps) => {
    return (
      <Box
        flexCol
        css={{
          gap: '1px',
          overflow: 'hidden',
          background: '$inplayMarketsAccordionContentBgColor',
          border: '1px solid $inplayMarketsContentBorderColor',
          borderRadius: '$10',
        }}
      >
        {marketsData.map((market, index) => (
          <Box
            key={market.id + '-' + index}
            flexRow={market.selections.length <= 3}
            flexCol={market.selections.length > 3}
            css={{
              gap: '1px',
            }}
          >
            {market?.selections.map((selection) => (
              <SelectionItem
                eventData={eventData}
                status={selection.status}
                marketId={market.id}
                eventId={eventId}
                key={selection.id}
                selection={selection}
              />
            ))}
          </Box>
        ))}
      </Box>
    );
  },
);
