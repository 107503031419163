import { useEffect, useState } from 'react';

import { useOpenBet } from '~api/betslip/betslipMutations';
import { BETSLIP_TYPE } from '~api/betslip/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { useAppDispatch, useAppSelector } from '~store';
import {
  removeAll,
  resetBetslipChanged,
  setAggregatedBetAmount,
  setBetErrorCode,
  setBetErrorEvent,
  setBetErrorMessage,
  setRebet,
  setReloadBetslip,
  setShowBetslipNotification,
  setSingleBetAmountMap,
  setStakes,
  setStakeType,
  setSystemBetOption,
} from '~store/slices/betslipSlice';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
import {
  getBetslipStake,
  getBetTotalAmount,
  getOddFromEvent,
} from '~utils/betslip';

import {
  BETSLIP_ERRORS,
  EVENT_ACTIVE_STATUSES,
  MARKET_ACTIVE_STATUS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../constants';

export const useBetslipFooter = (quickBet: boolean = false) => {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const {
    rebet,
    rebetAggregatedBetAmount,
    rebetSingleBetsAmountMap,
    rebetStakeType,
    events,
    eventsData,
    singleBetsAmountMap,
    betslipErrors,
    aggregatedBetAmount,
    stakeType,
    isBetslipChanged,
    oddAskType,
    systemBetOption,
    balanceChangesOnBetData,
  } = useAppSelector((state) => state.betslip);
  const { partnerLimits, defaultOddPolicyAcceptance, separateBonusBalance } =
    useAppSelector((state) => state.settings);

  const [localizationPath, setLocalizationPath] =
    useState<string>('buttons.betNow');
  const { openBetMutation } = useOpenBet();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    const isBetslipEmpty = events.length === 0;
    const hasZeroStake =
      stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE
        ? Object.values(singleBetsAmountMap).some(
            (amount) => getBetTotalAmount(amount) === 0,
          )
        : getBetTotalAmount(aggregatedBetAmount, systemBetOption) === 0;

    if (hasZeroStake) {
      setIsDisabled(true);

      return;
    }

    if (isBetslipEmpty) {
      setIsDisabled(true);

      return;
    }

    const isLinesNotMet =
      betslipErrors.includes(BETSLIP_ERRORS.MIN_SELECTIONS) ||
      betslipErrors.includes(BETSLIP_ERRORS.MAX_SELECTIONS);

    if (isLinesNotMet) {
      setIsDisabled(true);

      return;
    }

    const isStakeNotMet =
      betslipErrors.includes(BETSLIP_ERRORS.LOW_BALANCE) ||
      betslipErrors.includes(BETSLIP_ERRORS.LOW_BONUS_BALANCE) ||
      betslipErrors.includes(BETSLIP_ERRORS.MIN_STAKE) ||
      betslipErrors.includes(BETSLIP_ERRORS.MAX_STAKE) ||
      betslipErrors.includes(BETSLIP_ERRORS.NOT_MEET_REQUIREMENTS) ||
      betslipErrors.includes(BETSLIP_ERRORS.NOT_MET_WINBOOST);

    if (isStakeNotMet) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [
    partnerLimits,
    events,
    eventsData,
    stakeType,
    aggregatedBetAmount,
    singleBetsAmountMap,
    betslipErrors,
  ]);

  const handleClick = async () => {
    if (isUserLoggedIn && rebet.length) {
      dispatch(setSystemBetOption(null));
      dispatch(setReloadBetslip(true));
      dispatch(setStakes(rebet));
      dispatch(setRebet([]));
      dispatch(setSingleBetAmountMap(rebetSingleBetsAmountMap));
      dispatch(setAggregatedBetAmount(rebetAggregatedBetAmount));
      dispatch(setStakeType(rebetStakeType));
      dispatch(setShowBetslipNotification(false));

      return;
    }

    const correlationId = balanceChangesOnBetData?.correlationId as string;

    if (isUserLoggedIn) {
      const filteredEvents = events.filter(({ eventId, marketId }) => {
        const event = eventsData.find((item) => item.id === eventId);
        const market = event?.markets.find((item) => item.id === marketId);
        const isEventFinished = !EVENT_ACTIVE_STATUSES.includes(
          event?.status as number,
        );
        const isMarketFinished = market?.status !== MARKET_ACTIVE_STATUS;

        return !isEventFinished && !isMarketFinished;
      });

      if (filteredEvents.length === 0) {
        dispatch(setStakes([]));
        dispatch(resetBetslipChanged());

        return;
      }

      if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE) {
        const betSlips = [
          {
            selections: filteredEvents
              .map(({ selectionId, marketId, eventId }) => {
                const odd = getOddFromEvent(eventsData, {
                  selectionId,
                  marketId,
                  eventId,
                });

                return {
                  id: selectionId,
                  eventId,
                  odd,
                };
              })
              .filter((item) => !!item.odd),
            // If separateBonusBalance is true, we need to send the bonus stake separately
            ...getBetslipStake({
              amount: aggregatedBetAmount,
              separateBonusBalance,
              isBet: true,
            }),
          },
        ];

        await openBetMutation({
          betSlips,
          betSlipType: BETSLIP_TYPE.MULTIPLE,
          oddChangePolicy: parseInt(
            (oddAskType || defaultOddPolicyAcceptance) as string,
          ),
          correlationId,
        });
      } else if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM) {
        const betSlips = {
          selections: filteredEvents
            .map(({ selectionId, marketId, eventId }) => {
              const odd = getOddFromEvent(eventsData, {
                selectionId,
                marketId,
                eventId,
              });

              return {
                id: selectionId,
                eventId,
                odd,
              };
            })
            .filter((item) => !!item.odd),
          // If separateBonusBalance is true, we need to send the bonus stake separately
          ...getBetslipStake({
            amount: aggregatedBetAmount,
            separateBonusBalance,
            isBet: true,
          }),
        };

        const correlationId = balanceChangesOnBetData?.correlationId as string;

        await openBetMutation({
          correlationId,
          betSlips,
          systemBetCombinationSize: Number(systemBetOption?.value) || 0,
          oddChangePolicy: parseInt(
            (oddAskType || defaultOddPolicyAcceptance) as string,
          ),
        });
      } else {
        const betSlips = filteredEvents.map(
          ({ selectionId, marketId, eventId }) => {
            const amount = singleBetsAmountMap[selectionId] || {};

            const odd = getOddFromEvent(eventsData, {
              selectionId,
              marketId,
              eventId,
            });

            return {
              selections: [
                {
                  eventId,
                  id: selectionId,
                  odd,
                },
              ],
              ...getBetslipStake({ amount, separateBonusBalance, isBet: true }),
            };
          },
        );

        dispatch(setBetErrorCode(0));
        dispatch(setBetErrorEvent(null));
        dispatch(setBetErrorMessage(null));
        const correlationId = balanceChangesOnBetData?.correlationId as string;

        await openBetMutation({
          correlationId,
          betSlips,
          oddChangePolicy: parseInt(
            (oddAskType || defaultOddPolicyAcceptance) as string,
          ),
          betSlipType: BETSLIP_TYPE.SINGLE,
        });
      }
    } else {
      dispatch(openDialog(DIALOGS.SIGN_IN));
    }
  };

  useEffect(() => {
    if (isBetslipChanged.length && isUserLoggedIn) {
      if (quickBet) {
        setLocalizationPath('buttons.acceptChangesAndPlaceQuickBet');

        return;
      }

      setLocalizationPath('buttons.acceptChangesAndPlaceBet');

      return;
    }

    if (isUserLoggedIn) {
      setLocalizationPath('buttons.betNow');
    }
  }, [isUserLoggedIn, isBetslipChanged, events, quickBet]);

  const onDeleteClick = () => {
    dispatch(removeAll());
    dispatch(setIsSwipeOpen(null));
  };

  return {
    isDisabled,
    isUserLoggedIn,
    isBetslipChanged,
    localizationPath,
    rebet,
    onClick: handleClick,
    onDeleteClick,
  };
};
