import {
  BasketballExtraData,
  IceHockeyExtraData,
  SnookerExtraData,
  SoccerExtraData,
  TennisExtraData,
  VolleyballExtraData,
} from '~types/sportExtraData';
import { shouldShowMatchDetail } from '~utils/matchStatusHelpers';

export enum SERVER {
  HOME = 1,
  AWAY = 2,
}

const getNthMinute = (time: string): number => {
  const [minutesStr, secondsStr] = time.split(':');
  const minutes = parseInt(minutesStr!);
  const seconds = parseInt(secondsStr!);

  if (seconds > 0) {
    return minutes + 1;
  }

  return minutes;
};

export const getSportDetails = (
  extraData:
    | BasketballExtraData
    | IceHockeyExtraData
    | SnookerExtraData
    | VolleyballExtraData,
  showTime: boolean = true,
) => {
  const {
    PeriodScores,
    HomeScore = 0,
    AwayScore = 0,
    EventClock,
  } = extraData || {};
  const { RemainingTimeInPeriod } = EventClock || {};

  const remainingTime = RemainingTimeInPeriod || '00:00';

  if (!PeriodScores || PeriodScores.length === 0) {
    return null;
  }

  const periodScores = PeriodScores.sort((a, b) => a.Number - b.Number).reduce(
    (acc, period) => {
      acc.push(`(${period.HomeScore}:${period.AwayScore})`);

      return acc;
    },
    [] as string[],
  );

  const scoreString = `${HomeScore} : ${AwayScore}`;
  const periodScoresString = periodScores.join(', ');

  return {
    details: `${scoreString}, ${periodScoresString} ${
      showTime ? remainingTime : ''
    }`.trim(),
    periodScores: PeriodScores.sort((a, b) => a.Number - b.Number).map(
      (score) => ({
        homeScore: score.HomeScore,
        awayScore: score.AwayScore,
        setNumber: score.Number,
      }),
    ),
    time: remainingTime,
  };
};

export const getBasketballDetails = (
  extraData: BasketballExtraData,
  showTime: boolean,
) => {
  return getSportDetails(extraData, showTime);
};

export const getIceHockeyDetails = (
  extraData: IceHockeyExtraData,
  showTime: boolean,
) => {
  return getSportDetails(extraData, showTime);
};

export const getSnookerDetails = (
  extraData: SnookerExtraData,
  showTime: boolean,
) => {
  return getSportDetails(extraData, showTime);
};

export const getVolleyballDetails = (
  extraData: VolleyballExtraData,
  showTime: boolean,
) => {
  const { Properties } = extraData;
  const { CurrentServer } = Properties || {};

  return {
    ...getSportDetails(extraData, showTime),
    server: CurrentServer,
  };
};

export const getSoccerDetails = (
  extraData: SoccerExtraData,
  showTime: boolean,
) => {
  const { EventTime = '00:00', StoppageTime } = extraData.EventClock || {};
  const minute = getNthMinute(EventTime);
  const extraMinute = StoppageTime ? getNthMinute(StoppageTime) : null;

  const showMatchDetails = shouldShowMatchDetail(extraData);

  return {
    details:
      showMatchDetails && showTime
        ? `${minute}${extraMinute ? `+${extraMinute}` : ''}\``
        : '',
    time: EventTime,
  };
};

export const getTennisDetails = (extraData: TennisExtraData) => {
  const { PeriodScores, HomeScore = 0, AwayScore = 0, Properties } = extraData;
  const {
    CurrentServer,
    HomeGameScore = 0,
    AwayGameScore = 0,
  } = Properties || {};

  if (!PeriodScores || PeriodScores.length === 0) {
    return null;
  }

  const homeScore = HomeGameScore === 50 ? 'A' : HomeGameScore;
  const awayScore = AwayGameScore === 50 ? 'A' : AwayGameScore;
  const homeServer = CurrentServer === SERVER.HOME ? '*' : '';
  const awayServer = CurrentServer === SERVER.AWAY ? '*' : '';

  const periodScores = PeriodScores.sort((a, b) => a.Number - b.Number).reduce(
    (acc, period) => {
      acc.push(`(${period.HomeScore}:${period.AwayScore})`);

      return acc;
    },
    [] as string[],
  );

  const scoreString = `${HomeScore} : ${AwayScore}`;
  const gameScoreString = [
    homeScore,
    homeServer,
    ':',
    awayScore,
    awayServer,
  ].join('');

  const periodScoresString = periodScores.join(', ');

  return {
    details: `${scoreString}, ${periodScoresString} ${gameScoreString}`,
    homeGameScore: homeScore,
    awayGameScore: awayScore,
    server: CurrentServer,
    periodScores: PeriodScores.sort((a, b) => a.Number - b.Number).map(
      (score) => ({
        homeScore: score.HomeScore,
        awayScore: score.AwayScore,
        setNumber: score.Number,
      }),
    ),
  };
};

export const getParsedExtraData = (extraData?: string) => {
  return extraData ? JSON.parse(extraData) : null;
};
