import { Fragment } from 'react';

import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
  StyledAccordionRoot,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import {
  navigationItems,
  userProfileMobileMenuMap,
} from '../../constants/navigationItems';
import { NavigationItemMobile } from '../NavigationItem';

export const UserProfileNavigationMobile = () => {
  const { localized } = useTranslation();

  return (
    <Box>
      {Object.entries(userProfileMobileMenuMap).map(
        ([menuItem, subMenuItems]) => {
          const {
            icon: Icon,
            title,
            value,
          } = navigationItems.find((item) => item.value === menuItem) || {};

          return (
            <Fragment key={menuItem}>
              {subMenuItems ? (
                <StyledAccordionRoot
                  type="multiple"
                  mobileMenu
                  css={{
                    backgroundColor: '$userProfileWidgetBgColorMobile',
                  }}
                >
                  <StyledAccordionItem value={menuItem} mobileMenu>
                    <AccordionTrigger mobileMenu openDataState>
                      {Icon && <Icon className="icon" />}
                      <Text level="14-20">{localized(title!)}</Text>
                    </AccordionTrigger>
                    <AccordionContent mobileMenu>
                      {subMenuItems.map((subItem) => (
                        <Box
                          key={subItem}
                          css={{
                            '&:not(:last-child)': {
                              borderBottom: '1px solid $grayDarker',
                            },
                          }}
                        >
                          <NavigationItemMobile
                            value={subItem}
                            title={subItem}
                            sub
                          />
                        </Box>
                      ))}
                    </AccordionContent>
                  </StyledAccordionItem>
                </StyledAccordionRoot>
              ) : (
                <NavigationItemMobile
                  value={value!}
                  title={title!}
                  icon={Icon!}
                />
              )}
            </Fragment>
          );
        },
      )}
    </Box>
  );
};
