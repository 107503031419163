import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { ChevronDownIcon } from '~icons';

export const StyledUserProfileBarContainer = styled(Box, {
  borderRadius: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  maxHeight: '44px',
  minHeight: '44px',
  p: '2px',
  svg: {
    width: '44px',
    height: '44px',
  },
  '@xs_sm': {
    height: '28px',
    minHeight: 'unset',
  },
  variants: {
    isOpened: {
      true: {
        background: '$greenDark',
      },
    },
    isSquare: {
      true: {
        aspectRatio: 1,
      },
    },
  },
});

export const StyledUserProfileInfoContainer = styled(Box, {
  height: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  gap: '$2',
  p: '$2',

  '&:active *': {
    '@xs_sm': {
      color: '$green',
    },
  },
  '@xs_sm': {
    p: '$1',
    gap: '$1',
  },
});

export const StyledChevron = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '&[data-state="open"]': {
    transform: 'rotate(180deg)',
    fill: '$white',
    stroke: '$white',
  },
  '&:hover path, &[data-state="open"] path': {
    fill: '$white',
    stroke: '$white',
  },
});

export const styledDepositButtonCSS = {
  width: '160px',
  lineHeight: '$24',
  py: '$2',
  borderRadius: '$4',
  color: '$buttonTextColor',
  '& >  svg': {
    color: '$buttonTextColor !important',
    width: '2rem',
    height: '2rem',
  },
  '@xs_sm': {
    lineHeight: '2rem',
    fontSize: '$xxs',
    p: '$1 !important',
    height: '2rem',
  },
  '@xs': {
    width: 'unset',
  },
  '@sm': {
    width: 'unset',
  },
};

export const StyledDepositButton = styled(Button, {
  width: '160px',
  lineHeight: '$24',
  py: '$1',
  borderRadius: '$4 !important',
  color: '$buttonTextColor',
  maxHeight: '36px',
  svg: {
    width: '25px !important',
    height: '15.58px !important',
  },
  '& >  svg': {
    color: '$buttonTextColor !important',
    width: '25px !important',
    height: '15.58px !important',
  },
  '@md': {
    width: '136px',
  },
  '@xs_sm': {
    width: 'unset',
    lineHeight: '2rem !important',
    fontSize: '$xxs !important',
    p: '$1 !important',
    maxHeight: '24px',
  },
  '@xxs': {
    minWidth: '2rem !important',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '$1',
  },
  variants: {
    separateBonusBalance: {
      true: {
        height: '46px',
        maxHeight: '46px !important',
        '@xs_sm': {
          height: '2rem',
          minWidth: '2rem !important',
          maxHeight: '2rem !important',
          svg: {
            width: '25px !important',
            height: '15.58px !important',
          },
        },
      },
    },
  },
});
