import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

export const StyledInfoText = styled(Text, {
  fontSize: '13px',
  lineHeight: '16px',
  '@xs': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

export const StyledInfoContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '$2',
  borderBottom: '1px solid $grayDarker',
});

export type StyledInfoTextProps = React.ComponentProps<typeof StyledInfoText>;

export type StyledInfoContainerProps = React.ComponentProps<
  typeof StyledInfoContainer
>;

export const InfoText: React.FC<StyledInfoTextProps> = ({
  children,
  ...props
}) => <StyledInfoText {...props}>{children}</StyledInfoText>;

export const InfoContainer: React.FC<StyledInfoContainerProps> = ({
  children,
  ...props
}) => <StyledInfoContainer {...props}>{children}</StyledInfoContainer>;

export const StyledJackpotAccordionRoot = styled(Accordion.Root, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
});

export const StyledEventDetailsAccordionTrigger = styled(Accordion.Trigger, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '$gray',
  borderRadius: '0 0 $8 $8',
  cursor: 'pointer',
});
