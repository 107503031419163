import React, { memo, useEffect, useRef } from 'react';

import { Panel } from '~components/atoms/Panel';
import { SPORTS } from '~constants/sport';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';
import { StoreSportEvent } from '~types/events';
import { MATCH_STATUS_NOT_STARTED } from '~types/sportExtraData/generalExtraDataTypes';
import { getMatchStatus } from '~utils/matchStatusHelpers';
import { getParsedExtraData } from '~utils/sportHelpers';

import {
  BasketballEventOverview,
  FallbackEventOverview,
  IceHockeyEventOverview,
  SoccerEventOverview,
  TennisEventOverview,
  VolleyballEventOverview,
} from './sports/overviews';

interface InplayEventOverviewProps {
  eventData: StoreSportEvent;
}

export const InplayEventOverview = memo(
  ({ eventData }: InplayEventOverviewProps) => {
    const { sportId } = useQueryParams();
    const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
    const { extraData, ...restEventData } = eventData;

    const parsedExtraData = getParsedExtraData(extraData);
    const matchStatus = getMatchStatus(parsedExtraData);

    const mainMarketsIdSportNameMap: Record<string, string> = {};

    mainMarkets.forEach(({ id, name }) => {
      mainMarketsIdSportNameMap[id] = name;
    });

    const sportName = mainMarketsIdSportNameMap[sportId || ''];

    const isMatchStarted =
      matchStatus && matchStatus !== MATCH_STATUS_NOT_STARTED;
    const panelWrapperRef = useRef<HTMLDivElement>(null);

    interface EventDataWithParsedExtraData {
      parsedExtraData: typeof parsedExtraData;
      restEventData: typeof restEventData;
    }

    const sportOverviewComponentMap: Partial<
      Record<SPORTS, React.ComponentType<EventDataWithParsedExtraData>>
    > = {
      [SPORTS.SOCCER]: SoccerEventOverview,
      [SPORTS.TENNIS]: TennisEventOverview,
      [SPORTS.VOLLEYBALL]: VolleyballEventOverview,
      [SPORTS.BASKETBALL]: BasketballEventOverview,
      [SPORTS.ICE_HOCKEY]: IceHockeyEventOverview,
    };

    const SportOverviewComponent =
      eventData?.sportName &&
      sportOverviewComponentMap[(sportName || eventData.sportName) as SPORTS]
        ? sportOverviewComponentMap[
            (sportName || eventData.sportName) as SPORTS
          ]
        : FallbackEventOverview;

    useEffect(() => {
      if (panelWrapperRef.current) {
        panelWrapperRef.current.scrollIntoView(false);
      }
    }, [panelWrapperRef.current, eventData.id]);

    if (!SportOverviewComponent) {
      return null;
    }

    return (
      <Panel flexCol compactHeight={!isMatchStarted} ref={panelWrapperRef}>
        <SportOverviewComponent
          parsedExtraData={parsedExtraData}
          restEventData={restEventData}
        />
      </Panel>
    );
  },
);
