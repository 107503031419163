import React, { useEffect } from 'react';

import { Loader } from '~components/atoms/Loader';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { Box } from '../../../atoms/Box';
import { NoBets } from '../NoBets';

import { BetslipHistoryFilters } from './BetslipHistoryFIlters';
import { BetslipHistoryTable } from './BetslipHistoryTable';
import { useBetslipHistory } from './useBetslipHistory';

export const BetslipHistory = () => {
  const { localized } = useTranslation();
  const { loadBetslipHistory, isLoaded, betHistory, total, loadMore } =
    useBetslipHistory();

  useEffect(() => {
    loadBetslipHistory({});
  }, []);

  return (
    <Box
      flexCol
      gap={3}
      css={{
        backgroundColor: '$grayDark',
        borderRadius: '$8',
      }}
    >
      <Separator
        shrinkOut={3}
        verticalSpace={0}
        css={{
          backgroundColor: '$gray',
        }}
      />
      <BetslipHistoryFilters />
      <Separator
        shrinkOut={3}
        verticalSpace={0}
        css={{
          backgroundColor: '$gray',
        }}
      />
      {!isLoaded ? (
        <Box flexCol alignCenter gap={3} css={{ paddingTop: '32px' }}>
          <Loader css={{ color: '$grayMedium' }} />
          <Text color="grayMedium" level="sm-3" fontWeight="medium">
            {localized('betslipHistory.loading')}
          </Text>
        </Box>
      ) : !betHistory.length ? (
        <NoBets />
      ) : (
        <BetslipHistoryTable
          betHistory={betHistory}
          loadMore={loadMore}
          total={total}
        />
      )}
    </Box>
  );
};
