import { BetslipHistoryItems } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { ScrollArea } from '~components/atoms/ScrollArea';

import { BetHistoryMobileCashout } from './BetHistoryMobileCashout';
import { BetslipHistoryMobileItem } from './BetslipHistoryMobileItem';
import { useBetHistoryList } from './useBetHistoryList';

interface BetslipHistoryMobileContentProps {
  betHistory: BetslipHistoryItems;
  loadMore: () => void;
  total: number;
}

export const BetslipHistoryMobileContent = ({
  betHistory,
  loadMore,
  total,
}: BetslipHistoryMobileContentProps) => {
  useBetHistoryList({
    loadMore,
    betHistory,
    dataLength: total,
  });

  return (
    <Box
      flexCol
      gap={1}
      css={{
        backgroundColor: '$black',
        '@xs_sm': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <ScrollArea>
        <Box flexCol gap={1}>
          {betHistory.map((item) => (
            <BetslipHistoryMobileItem key={item.id} betslipHistoryItem={item} />
          ))}
        </Box>
      </ScrollArea>
      <BetHistoryMobileCashout isBetHistory />
    </Box>
  );
};
