import React from 'react';

import { MainMarket } from '~api/market/types';
import { InPlaySportEvent } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { SportEvents } from '~types/events';

import { LiveEventCard } from './LiveEventCard';

interface LiveTopEventListMobileProps {
  events: SportEvents;
  mainSportMarket: MainMarket | undefined;
}

export const LiveTopEventListMobile = ({
  events,
  mainSportMarket,
}: LiveTopEventListMobileProps) => {
  const { isMobile } = useMedia();
  const { selections = [] } = mainSportMarket || {};

  return (
    <Box flexCol css={{ borderRadius: '$6', backgroundColor: '$gray' }}>
      {isMobile && (
        <Box fullWidth flexCol justifyCenter alignEnd>
          <Box flexRow fullWidth justifyContentEnd css={{ mr: '$5', pt: '$3' }}>
            {selections.map(({ name }) => {
              return (
                <Box
                  key={name}
                  fullWidth
                  css={{ maxWidth: '53px' }}
                  flexRow
                  alignCenter
                  justifyCenter
                >
                  <Text ellipsis level="12-20">
                    {name}
                  </Text>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box
        flexCol
        gap={1}
        css={{
          '@xs_sm': {
            p: '$2',
          },
        }}
      >
        {events?.map((event) => {
          const { id, leagueId, countryId, sportId, leagueName, countryName } =
            event;

          return (
            <LiveEventCard
              key={id}
              eventData={event as InPlaySportEvent}
              sportId={sportId!}
              countryId={countryId!}
              leagueId={leagueId!}
              eventId={id}
              isActive={false}
              showLeagues={true}
              leagueName={leagueName}
              countryName={countryName}
            />
          );
        })}
      </Box>
    </Box>
  );
};
